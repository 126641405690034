import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { myEternaviewList, updateEternaviewPermission, getPracticeEternaviews, viewersList, listInviteUsers, sendInviteToView, getMyEternaviews, revokePermissionView, requestReactivation, verifyUserSubscription, editTitle, inviteSharedWithMe } from '../../redux/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import user_add from "../../Images/user_add.png";
import video_outer from "../../Images/video_outer.png";
import arrow_right from "../../Images/arrow_right.png";
import material_edit from "../../Images/Icon-material-edit.png";
import play_button from "../../Images/play-button-arrowhead.png";
import icon_eyes from "../../Images/icon-eyes.png";
import video_img from "../../Images/video_img.png";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Moment from 'moment';
import vector_audio from "../../Images/vector_audio.jpg";
import img_side from "../../Images/img_side.png";
import user_default from "../../Images/user-default.jpeg";
import copy from "copy-to-clipboard";
import $ from "jquery";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import DownloadEterna from './components/downloadEterna';

class MyEternaviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            views: [],
            practiceViews: '',
            permission: '',
            nodding_video: '',
            inviteeLists: [],
            copyText: '',
            access_permission: '',
            invite_id: '',
            verifyPlanDetail: {},
            sixtyInteract: false,
            ninetyInteract: false,
            zeroInteract: false,
            graceZeroInteract: false,
            eterna_title: false,
            input_title: '',
            sharedWithMe: [],
            invite_name: '',
            invite_email: '',
            shared_link: ''
        };
        this.changePermission = this.changePermission.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.onInputEternachange = this.onInputEternachange.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.loginUserToken != '') {
            this.getMyEternaviews();

            this.props.getPracticeEternaviews(this.props.auth.loginUserToken, (res) => {
                if (!res.error || !res.msg) {
                    if (res.data) {
                        // let eterna = [];
                        // eterna.push(res.data);
                        this.setState({ practiceViews: res.data });

                        this.videoJsOptions1 = { // lookup the options in the docs for more options
                            autoplay: true,
                            controls: false,
                            responsive: true,
                            bigPlayButton: false,
                            fluid: true,
                            muted: true,
                            sources: [{
                                src: res.data.nodding_video,
                                type: 'application/x-mpegURL'
                            }]
                        }

                        this.practice_player = videojs(this.practNode, this.videoJsOptions1, function onPlayerReady() {
                            // print version information at startup
                            console.log('onPlayerReady', this)
                        });

                        // device is ready
                        this.practice_player.on('deviceReady', () => {
                            console.log('device is ready!');
                        });
                    }
                }
            });

            this.getEternaviewsShared();
        }
    }

    getMyEternaviews() {
        this.props.myEternaviewList(this.props.auth.loginUserToken, (res) => {
            if (!res.error) {
                if (res.data && res.data.length > 0) {
                    this.setState({ views: res.data });

                    res.data.map((view, index) => {
                        if (view.nodding_video && view.nodding_video != "") {
                            view.videoJsOptions = { // lookup the options in the docs for more options
                                autoplay: true,
                                controls: false,
                                responsive: true,
                                bigPlayButton: false,
                                fluid: true,
                                muted: true,
                                sources: [{
                                    src: view.nodding_video,
                                    type: 'application/x-mpegURL'
                                }]
                            }

                            view.player = videojs(view.viewEternaNode, view.videoJsOptions, function onPlayerReady() {
                                // print version information at startup
                                console.log('onPlayerReady', this)
                            });

                            // device is ready
                            view.player.on('deviceReady', () => {
                                console.log('device is ready!');
                            });
                        }

                        view.permission = view.permission + '+' + view.id;
                        view.name = view.id;
                        view.value = view.title;
                    });


                    this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
                        if (res.data) {
                            this.setState({ verifyPlanDetail: res.data });
                            if (res.data.subscribe_plan__no_of_query > 0) {
                                let sixtyVal = res.data.subscribe_plan__no_of_query - (Math.floor(0.60 * res.data.subscribe_plan__no_of_query));
                                if (sixtyVal == res.data.updated_details.no_of_query) {
                                    this.setState({ sixtyInteract: true });
                                }

                                let ninetyVal = res.data.subscribe_plan__no_of_query - (Math.floor(0.90 * res.data.subscribe_plan__no_of_query));
                                if (ninetyVal == res.data.updated_details.no_of_query) {
                                    this.setState({ ninetyInteract: true });
                                }

                                if (res.data.updated_details.no_of_query == 0 && res.data.updated_details.grace_no_of_query > 0) {
                                    this.setState({ zeroInteract: true });
                                } else if (res.data.updated_details.no_of_query == 0 && res.data.updated_details.grace_no_of_query == 0) {
                                    console.log('tanisha');
                                    this.setState({ graceZeroInteract: true });
                                }
                            }

                        }
                    });
                }
            }
        });

    }

    getAllViewers(id) {
        this.props.viewersList(id, this.props.auth.loginUserToken, (res) => {
            if (res.data.allowed_user && res.data.allowed_user.length > 0) {
                let data = res.data.allowed_user;
                this.setState({ viewersList: data, viewerLikes: res.data.anonymous_user });
            }
        });
    }

    getEternaviewsShared() {
        this.props.inviteSharedWithMe(this.props.auth.loginUserToken, (res) => {
            if (res.data && res.data.length > 0) {
                this.setState({ sharedWithMe: res.data });

                res.data.map((view, index) => {
                    if (view.trans_coded_url && view.trans_coded_url != "") {
                        view.videoJsOptions = { // lookup the options in the docs for more options
                            autoplay: false,
                            controls: true,
                            responsive: true,
                            bigPlayButton: true,
                            fluid: true,
                            muted: false,
                            sources: [{
                                src: view.trans_coded_url,
                                type: 'application/x-mpegURL'
                            }]
                        }

                        view.player = videojs(view.viewShared, view.videoJsOptions, function onPlayerReady() {
                            // print version information at startup
                            console.log('onPlayerReady', this)
                        });

                        // device is ready
                        view.player.on('deviceReady', () => {
                            console.log('device is ready!');
                        });
                    }

                });
            }
        });
    }

    copyToClipboard = (link) => {
        copy(link);

        toast("Link is copied", {
            position: "top-right",
            autoClose: 2000,
            type: 'success',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }

    editEternaTitle = (con) => {
        this.setState({ eterna_title: true, [`${con.id}`]: con.title });
    };

    submitTitle = (con) => {
        this.props.editTitle({ "id": con.id, "title": con.value }, this.props.auth.loginUserToken, (res) => {
            if (res.msg) {
                this.setState({ eterna_title: false });
                this.getMyEternaviews();
            }
        });
    }

    changePermission(event) {
        let val = event.target.value;
        this.setState({ permission: event.target.value });
        let split = val.split('+');
        this.props.updateEternaviewPermission({ "id": split[1], "permission": split[0] }, this.props.auth.loginUserToken, (res) => {

            if (res.msg) {
                toast("Eternaview permissions have been updated successfully", {
                    position: "top-right",
                    autoClose: 5000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                this.getMyEternaviews();
            }
        });
    }

    checkModalId(con) {
        this.setState({ view_id: con.id, shared_link: con.shared_links });
        this.props.listInviteUsers(con.id, this.props.auth.loginUserToken, (res) => {
            if (res.data.length > 0) {
                this.setState({ inviteeLists: res.data });
            }
        });
    }

    sendInvite = (e) => {
        e.preventDefault();

        if (this.state.invite_email == '') {
            toast("Please enter invitee name and email.", {
                position: "top-right",
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
            return;
        } else {
            this.props.sendInviteToView({ "user_list": [{ name: this.state.invite_name, email: this.state.invite_email }], "view_id": this.state.view_id }, this.props.auth.loginUserToken, (res) => {
                this.setState({ invite_name: '', invite_email: '' });
                if (res.msg) {
                    this.getMyEternaviews();
                    toast("Invite has been sent successfully.", {
                        position: "top-right",
                        autoClose: 5000,
                        type: 'success',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });
                }

            });
        }

    }

    onInputchange(event) {
        if (event.target.name == 'access_permission') {
            if (this.state.permission) {
                let split = this.state.permission.split("+");

                this.props.updateEternaviewPermission({ "id": split[1], "permission": split[0], "access_permission": event.target.value }, this.props.auth.loginUserToken, (res) => {

                    this.getMyEternaviews();

                    if (res.msg) {
                        toast("Eternaview permissions have been updated successfully", {
                            position: "top-right",
                            autoClose: 5000,
                            type: 'success',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        });
                    }

                });
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });

    }

    revokeModal(id) {
        this.setState({ invite_id: id });
    }

    removePermission() {
        this.props.revokePermissionView(this.state.view_id, { "user_id": this.state.invite_id }, this.props.auth.loginUserToken, (res) => {
            if (res.msg) {
                toast("Permission is revoked from user", {
                    position: "top-right",
                    autoClose: 5000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                this.setState({ invite_id: '' });
                this.getAllViewers(this.state.view_id);
                this.getMyEternaviews();
            }

        })
    }

    requestActivation = (e) => {
        e.preventDefault();

        this.props.requestReactivation(this.state.views[0].id, this.props.auth.loginUserToken, (res) => {
            if (res.msg) {
                toast("Admin is notified about your request via email, please wait for further confirmation.", {
                    position: "top-right",
                    autoClose: 5000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
        })
    }

    onInputEternachange(event) {
        let views = this.state.views.slice();
        for (let i in views) {
            if (views[i].name == event.target.name) {
                views[i].value = event.target.value;
                this.setState({ views: views });
                break;
            }
        }
    }

    closePopUp = (e) => {
        e.preventDefault();

        this.setState({ sixtyInteract: false, ninetyInteract: false, zeroInteract: false, graceZeroInteract: false });
    }

    render() {
        const { views, practiceViews, permission, nodding_video, viewersList, inviteeLists, sharedWithMe, shared_link } = this.state;
        return (
            <div>
                <section className="doiect_outer crete29 cus672 home1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 cust7">
                                <div className="being56 cre45">
                                    <h4>This is the perfect place to check out all of your Eternaviews. If you register and log in,
                                        you will be able to:
                                    </h4>
                                </div>
                                <div className="told_outer">
                                    <img src={arrow_right} />
                                    <p>See any Eternaview you have created.</p>
                                </div>
                                <div className="told_outer">
                                    <img src={arrow_right} />
                                    <p>Edit your Eternaview.</p>
                                </div>
                                <div className="told_outer">
                                    <img src={arrow_right} />
                                    <p>Check out who has viewed your Eternaview</p>
                                </div>
                                <div className="told_outer">
                                    <img src={arrow_right} />
                                    <p>Interact with your Eternaview yourself.</p>
                                </div>
                                <div className="told_outer">
                                    <img src={arrow_right} />
                                    <p>Send additional invites/share a link.</p>
                                </div>
                                <div className="told_outer">
                                    <img src={arrow_right} />
                                    <p>Change your Eternaview between private and public.</p>
                                </div>
                                <div className="told_outer">
                                    <img src={arrow_right} />
                                    <p>Check invites you've received to view Eternaviews created by others.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div id="myModal201" className={this.state.zeroInteract == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <p>Please note that all of the queries in interaction have been consumed up. You are provided with additional {this.state.verifyPlanDetail.subscribe_plan__no_of_query} in your eternaview.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={this.closePopUp}>Okay!</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="myModal201" className={this.state.sixtyInteract == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <p>Please note that 60% of number of queries have been consumed on your eternaview.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={this.closePopUp}>Okay!</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="myModal201" className={this.state.ninetyInteract == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <p>Please note that 90% of number of queries have been consumed on your eternaview.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={this.closePopUp}>Okay!</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="myModal201" className={this.state.graceZeroInteract == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <p>Sorry but you have now reached the maximum number of queries from viewers that your Eternaview is entitled to receive per your subscription plan. This functionality will be restricted until the next time your subscription is renewed. We encourage you to renew early to restore this functionality and reset all of your usage allowances.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={this.closePopUp}>Okay!</button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.auth.loginUserToken != '' && <section className="custom67 ente45">
                    <div className="container">
                        <div className="enta_oter custom_entrna">
                            <h2>Practice Eternaview</h2>
                        </div>
                        {(practiceViews && practiceViews != '') && <div className="row specinf_bottom">
                            {/* {practiceViews.map((con, index) => { 
                                 return ( */}
                            <div className="col-lg-4 col-md-12">
                                <div className="small_box1">
                                    <div className="top_text_outer">
                                        <div className="top_texr5 min_h">
                                            <h5>{practiceViews.title}</h5>
                                        </div>
                                        <div className="top_texr56">
                                            <p>Created: {Moment(practiceViews.created_on).format('MM-DD-YYYY')}</p>
                                            {/* <span className="last_inner">Last Edited: {Moment(con.updated_on).format('MM-DD-YYYY')}</span> */}
                                        </div>
                                    </div>

                                    <div className="img_i76">
                                        {/* <a href="#" className="resumecreate">Resume Creating Eternaview!</a> */}
                                        {practiceViews.nodding_video == '' && <img src={vector_audio} />}
                                        {/* {con.nodding_video != "" && <video src={con.nodding_video} autoPlay loop muted></video>} */}

                                        {practiceViews.nodding_video != "" && <video id="practVideo" className="video-js vjs-default-skin interact-public" ref={node => this.practNode = node} options={this.videoJsOptions1} loop></video>}
                                        <div className="bottom_icon">
                                            <ul>
                                                <li></li>
                                                <li><Link to={`/home/practice-interact/`}><li className="cutom7"><img src={play_button} /></li></Link></li>
                                                <li className="cutom78">
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* );
                            })} */}

                        </div>}

                        {(practiceViews == '') && <div className="specinf_bottom">
                            <p>You have not created any practice Eternaview yet. If you would like create one, you can click here now. <Link to="/create/practice-test/"><button className="btn">Practice Eternaview!</button></Link> </p>
                        </div>}
                    </div>
                </section>}

                {this.props.auth.loginUserToken != '' && <section className="custom67 ente45">
                    <div className="container">
                        <div className="enta_oter custom_entrna">
                            <h2>My Eternaviews
                                {views.length > 0 && views[0].is_show == false && <span className="deactivate_eternatext"> Your eternaview has been deactivated <button className='request-activate' onClick={this.requestActivation}>Request Activation</button></span>}
                            </h2>
                        </div>

                        {views.length > 0 && <div className="row specinf_bottom">
                            {views.map((con, index) => {
                                return (
                                    <div className="col-lg-4 col-md-12">
                                        <div className="small_box1">
                                            <div className={con.is_show == false ? "overlay_disable" : ""}></div>

                                            {con.progress_status == "complete" && <div className="top_texr5 select_top min_h">
                                                {this.state.eterna_title == false && <h5 onClick={() => this.editEternaTitle(con)}>{con.title}</h5>}
                                                {this.state.eterna_title == true && <div className="input_iin67"><input type="text" name={`${con.name}`} value={`${con.value}`} onChange={this.onInputEternachange} /></div>}

                                                {this.state.eterna_title == true && <span className='update-title' onClick={() => this.submitTitle(con)}><i className="fa fa-check"></i></span>}

                                                <select value={con.permission} onChange={this.changePermission}>
                                                    <option value={`private+${con.id}`}>Private</option>
                                                    <option value={`public+${con.id}`}>Public</option>
                                                </select>
                                                {/* value={`${this.state.permission}${con.permission}+${con.id}`} */}
                                            </div>}

                                            {(con.progress_status == "initial" || con.progress_status == "in-progress") && <div className="top_texr5 min_h">
                                                <Link to={`/create/eternaview/${con.category_id}+${con.sub_category_id}+${con.type == "video" ? '0' : '1'}+${con.id}`}><h5>{con.title}</h5></Link>
                                            </div>}

                                            <div className="top_texr56">
                                                <p>Created: {Moment(con.created_on).format('MM-DD-YYYY')}</p>
                                                <span className="last_inner">Last Edited: {Moment(con.updated_on).format('MM-DD-YYYY')}</span>

                                                {(con.progress_status == "complete") && <p className="downloadP">Download :<DownloadEterna id={con.id} token={this.props.auth.loginUserToken} /></p>}
                                                <br></br>
                                            </div>
                                            {con.progress_status == "complete" && <div className="edit_name"><Link to={`/create/congratulations/${con.category_id}+${con.sub_category_id}+${con.type == "video" ? '0' : '1'}+view-${con.id}`}><img src={material_edit} /></Link></div>}

                                            <div className="modal custom_modal4" id="myModal4">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                            <h4 className="modal-title">Please Confirm</h4>
                                                        </div>
                                                        <div className="modal-body custom_body custom_confirm">
                                                            <div className="inner_tex45">
                                                                <h4>Are you sure you want to remove this person’s<br />
                                                                    permission to watch your Eternaview?
                                                                </h4>
                                                                <p>Note: Their access may not be restricted if a link to your<br />
                                                                    Eternaview was separately shared with them
                                                                </p>
                                                                <div className="btn_outer99">
                                                                    <ul>
                                                                        <li className="cancel"><button className="btn" data-dismiss="modal" data-toggle="modal" data-target="#myModal2" onClick={() => this.checkModalId(con)}>No</button></li>
                                                                        <li className="Save_outer4"><button className="btn" onClick={() => this.removePermission()} data-dismiss="modal">Yes</button></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="img_i76">
                                                {(con.progress_status == "initial" || con.progress_status == "in-progress") && <Link className="resumecreate" to={`/create/eternaview/${con.category_id}+${con.sub_category_id}+${con.type == "video" ? '0' : '1'}+${con.id}`}>Resume Creating Eternaview!</Link>}

                                                {(con.type == "video" && con.nodding_video == '') && <img src={vector_audio} />}
                                                {(con.type == "video" && con.nodding_video != "") && <video id="myVideo" className="video-js vjs-default-skin interact-public" ref={node => con.viewEternaNode = node} options={con.videoJsOptions} loop></video>}

                                                {(con.type == "audio" && con.author__avatar == "") && <img src={vector_audio} />}
                                                {(con.type == "audio" && con.author__avatar != "") && <img src={con.author__avatar} />}
                                            </div>

                                            <div className="bottom_icon">
                                                <ul>
                                                    {con.progress_status == "complete" && <li>
                                                        {con.is_show == true && <img src={user_add} data-toggle="modal" data-target="#myModal2" onClick={() => this.checkModalId(con)} />}
                                                        {con.is_show == false && <img src={user_add} />}
                                                        <p>{con.invitees_count}</p>
                                                    </li>}

                                                    <li className="cutom7">
                                                        <div className="modal custom_model12" id="myModal2">
                                                            <div className="modal-dialog">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                        <div className="custom8">
                                                                            <button className="btn"
                                                                                data-toggle="modal" data-target="#myModal3" data-dismiss="modal">Invite People</button>
                                                                        </div>

                                                                        <h4 className="modal-title">Invites Sent</h4>

                                                                    </div>
                                                                    <div className="modal-body custom_body">

                                                                        {inviteeLists.map((inv, index) => {
                                                                            return (
                                                                                <div className="main_outer4">
                                                                                    {(inv.invited_user__avatar == "" || inv.invited_user__avatar == null) && <img src={user_default} />}
                                                                                    {/* {inv.invited_user__avatar != "" && <img src={inv.invited_user__avatar} />} */}
                                                                                    <div className="inner4">
                                                                                        <h5>{inv.name != null ? inv.name : 'Anonymous'}</h5>
                                                                                        <p>{inv.email}</p>
                                                                                    </div>
                                                                                    <div className="hand_outer">
                                                                                        <a href="#" className="uninvite_btn"
                                                                                            onClick={() => this.revokeModal(inv.email)}
                                                                                            aria-hidden="true" data-toggle="modal" data-target="#myModal4" data-dismiss="modal">Uninvite</a>
                                                                                    </div>
                                                                                </div>)
                                                                        })
                                                                        }
                                                                    </div>

                                                                    <div className="custom_footer">
                                                                        <div className="btn_outer99">
                                                                            <ul>
                                                                                <li className="Save_outer4">
                                                                                    <input type="hidden" value={shared_link} />
                                                                                    <button className="btn" type="button" onClick={() => this.copyToClipboard(shared_link)}> Copy Link</button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="lik_outer">
                                                                            <p>{shared_link}</p>

                                                                            <h4>This link will work for:</h4>
                                                                            <select name="access_permission" value={this.state.access_permission} onChange={this.onInputchange}>
                                                                                <option value="any_one_with_link">Anyone who it is shared with</option>
                                                                                <option value="restricted">Only those people who you have also sent an invite</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {con.progress_status == "complete" && <Link to={`/home/interact-one/${con.id}/${con.author}/${con.permission}`}><li className="cutom7"><img src={play_button} /></li></Link>}

                                                        <div className="modal" id="myModal3">
                                                            <div className="modal-dialog">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                        <h4 className="modal-title">Invite Viewers to Your Eternaview</h4>
                                                                    </div>
                                                                    <div className="modal-body custom_body">
                                                                        <div className="form_outer99">
                                                                            <div className="input_outer"><label>Name<span>Optional</span></label><input type="text" name="invite_name" value={this.state.invite_name} onChange={this.onInputchange} /></div>
                                                                            <div className="input_outer"><label>Email Address*</label><input type="text" name="invite_email" value={this.state.invite_email} onChange={this.onInputchange} /></div>
                                                                            <input type="hidden" name="view_id" value={this.state.view_id} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="custom_footer no_border54">
                                                                        <div className="btn_outer99">
                                                                            <ul>
                                                                                {this.state.invite_email == '' && <li className="Save_outer4"><button type="submit" className="btn" onClick={this.sendInvite}>Invite</button></li>}

                                                                                {this.state.invite_email != '' && <li className="Save_outer4"><button type="submit" className="btn" onClick={this.sendInvite} data-dismiss="modal">Invite</button></li>}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    {con.progress_status == "complete" && <li className="cutom78">
                                                        {con.is_show == true && <img src={icon_eyes} data-toggle="modal" data-target="#myModal" onClick={() => this.getAllViewers(con.id)} />}
                                                        {con.is_show == false && <img src={icon_eyes} />}
                                                        <p>{con.viewer_count}</p>
                                                    </li>}

                                                    <div className="modal fade custom_modale" id="myModal" role="dialog">
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                    <h4 className="modal-title">My Viewers</h4>
                                                                </div>
                                                                <div className="modal-body custom_body">
                                                                    <p className="right_text">Total Likes: {this.state.viewerLikes}</p>

                                                                    {
                                                                        viewersList && viewersList.map((view, index) => {
                                                                            return (
                                                                                <div className="main_outer4">
                                                                                    <img src={user_default} />
                                                                                    <div className="inner4">
                                                                                        <h5>{view.user != null ? view.user : "Anonymous User"}</h5>
                                                                                        <p>{view.email}</p>
                                                                                    </div>
                                                                                    <div className="hand_outer">
                                                                                        <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                                                                                    </div>
                                                                                </div>)
                                                                        })
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>);
                            })}
                        </div>}

                        {views.length == 0 && <div className="specinf_bottom">
                            <p>You have not created any Eternaviews yet. If you would like create one, you can click here now. <Link to="/home/create/"><button className="btn">Create Eternaview!</button></Link> </p>
                        </div>}
                    </div>
                </section>}

                {this.props.auth.loginUserToken != '' && <section className="custom67 vid67">
                    <div className="container">
                        <div className="enta_oter share34">
                            <h2>Shared with Me</h2>
                            {/* <p>Check invites you've received to view Eternaviews created by others.</p> */}
                        </div>
                        {sharedWithMe.length == 0 && <div className="full_width custom_sope ctr45 remove_paddind">
                            <p><b>Sorry, you have not yet been invited to view any Eternaviews created by others. You can, however, view some samples or public ones on the "Interact" screen.</b></p>
                        </div>}

                        {sharedWithMe.length > 0 &&
                            sharedWithMe && sharedWithMe.map((shr, index) => {
                                return (<div className="row full_width custom_sope ctr45 align-items-center">
                                    <div className="col-lg-8 col-md-8">
                                        <div className="main_outer4 custom_inner45">
                                            {shr.trans_coded_url == "" && <img className="rec_img" src={user_default} />}

                                            {(shr.views_type == "audio" && shr.trans_coded_url != "") && <audio src={shr.trans_coded_url} controls></audio>}

                                            {(shr.views_type == "video" && shr.trans_coded_url != "") && <video id="myVideo" className="video-js vjs-default-skin rec_img videoshared" ref={node => shr.viewShared = node} options={shr.videoJsOptions}></video>}

                                            <div className="inner4">
                                                <h5>{shr.title}</h5>
                                                <p>{shr.category__name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="custom_P">
                                            <p>Date Created<br />{Moment(shr.created_on).format('MM-DD-YYYY')}</p>
                                        </div>
                                    </div>
                                </div>)
                            })
                        }

                    </div>
                </section>}
            </div>
        )
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
    myEternaviewList: bindActionCreators(myEternaviewList, dispatch),
    updateEternaviewPermission: bindActionCreators(updateEternaviewPermission, dispatch),
    getPracticeEternaviews: bindActionCreators(getPracticeEternaviews, dispatch),
    viewersList: bindActionCreators(viewersList, dispatch),
    listInviteUsers: bindActionCreators(listInviteUsers, dispatch),
    sendInviteToView: bindActionCreators(sendInviteToView, dispatch),
    revokePermissionView: bindActionCreators(revokePermissionView, dispatch),
    requestReactivation: bindActionCreators(requestReactivation, dispatch),
    verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch),
    editTitle: bindActionCreators(editTitle, dispatch),
    inviteSharedWithMe: bindActionCreators(inviteSharedWithMe, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyEternaviews);
