import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LexRuntimeV2Client, RecognizeTextCommand, RecognizeUtteranceCommand } from "@aws-sdk/client-lex-runtime-v2";
import bg29 from "../../Images/bg29.png";
import real_think from "../../Images/real_think.png";
import small_integote from "../../Images/small_integote.png";
import purpal_check from "../../Images/purpal_check.png";
import micck from "../../Images/micck.png";
import mic from "../../Images/mic.gif";
import green from "../../Images/green.png";
import red_mick from "../../Images/red_mick.png";
import hand_key from "../../Images/hand_key.png";
import { toast } from 'react-toastify';
import { postInteractQuestion, getEternaviewDetail, likeEternaview, getAnsweredQuestions, verifyUserSubscription, getParticularQuestionDetail, getInteractParticularQuestionDetail, getQueryDetail } from '../../redux/actions';
import vector_audio from "../../Images/vector_audio.jpg";
import hand_outer4 from "../../Images/hand_outer4.png";
import Loader from "../../Images/buffer.gif";
import Moment from 'moment';
import { Base64 } from 'js-base64';
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Recorder from "../../components/Recorder";
import InteractVideo from "./components/interactVideo";
import InteractAudio from "./components/interactAudio";


const client = new LexRuntimeV2Client({ region: "us-east-1", credentials: { accessKeyId: "AKIAQUMBY6QYP5X7UIXX", secretAccessKey: "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" } });

class InteractOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recording: false,
            recordedAudio: {},
            show_input: false,
            show_modal_input: false,
            searchinput: '',
            videoUrl: '',
            videoType: '',
            orgUrl: '',
            detail: {},
            micRecording: false,
            micModalRecording: false,
            moduleQuestions: [],
            showLike: false,
            askQuestionBit: false,
            permission: '',
            base64regex: /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/,
            accesspermission: false,
            showHints: false,
            maxInteract: false,
            hideInteractOptions: false,
            no_transcoding: false,
            noInteract: false,
            blobURL: '',
            botResponse: '',
            category: ''
        };
        this.onInputchange = this.onInputchange.bind(this);
    }

    componentDidMount() {

        console.log(this.state.base64regex.test(this.props.match.params.id));

        if (this.props.match.params.id || this.props.match.params.author) {
        // if (this.state.base64regex.test(this.props.match.params.id) || this.state.base64regex.test(this.props.match.params.author)) {
         //   this.props.match.params.id = Base64.decode(this.props.match.params.id);
         //   this.props.match.params.author = Base64.decode(this.props.match.params.author);

            this.setState({ permission: this.props.match.params.permission })
 
            this.getMyDetail(this.props.match.params.permission);

        } else {
            this.getMyDetail('');
        }

        this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
            if (res.is_active == false) {
                localStorage.clear();
            } else {
                if (res.data) {
                    if (res.data.updated_details.no_of_query == 0 && res.data.updated_details.grace_no_of_query == 0) {
                        this.setState({ maxInteract: true, hideInteractOptions: true });
                    }
                }
            }
        });

        this.props.getQueryDetail(this.props.match.params.id, '', (result) => {
            if (result.no_of_query == 0) {
                this.setState({ maxInteract: true, hideInteractOptions: true });
            }
        });
    }

    getMyDetail(permission) {

        this.props.getEternaviewDetail(this.props.match.params.id, permission, (this.props.auth.loginUserToken != '' ? this.props.auth.loginUserToken : ''), (res) => {
            if (res.is_active == false) {
                this.setState({ noInteract: true });
            } else {
                this.setState({ orgUrl: res.nodding_video, detail: res, category: res.category_name });
                this.setState({ no_transcoding: false });

                this.setState({ videoUrl: res.nodding_video, videoType: 'application/x-mpegURL' });

                if (res.access_permission == 'restricted') {
                    this.setState({ accesspermission: true })
                }

                this.props.getAnsweredQuestions(this.props.match.params.author, this.props.match.params.id, '', (response) => {
                    let allquestions = [];
                    if (response.data && response.data.length > 0) {
                        response.data.map((con, index) => {
                            if (con.id == res.category_id) {
                                con.modules.map((subcat, ind) => {
                                    let cat = subcat.subcategory_name;

                                    if (con.category_name == "Dating" && cat != "Interaction Facilitation & Pleasantries (required)") {
                                        allquestions.push(subcat);
                                    }

                                    if ((con.category_name == "Legacy" && (cat != "Interaction Facilitation Part 1 (required)") && cat != "Interaction Facilitation Part 2" && cat != "Pleasantries")) {
                                        allquestions.push(subcat);
                                    }
                                });
                            }
                        });
                        this.setState({ moduleQuestions: allquestions });
                    }
                });
            }

        });
    }

    questionVideo = (id) => {
        this.setState({ no_transcoding: false });
        let token = this.props.auth.loginUserToken;

        let url = `author-question/${id}/?author_id=${this.state.detail.author}&query=yes&view_id=${this.props.match.params.id}`;

        if (token) {
            if (localStorage.getItem('super')) {
                url = `author-question/${id}/?author_id=${this.state.detail.author}&view_id=${this.props.match.params.id}&user_type=admin`;
                token = localStorage.getItem('super');
            }
            this.props.getParticularQuestionDetail(url, token, (res) => {
                this.setState({ showHints: false });
                if (res.data && res.data.answer.id) {
                   
                    this.setState({ recordedAudio: {} });

                    this.setState({ recording: true, searchinput: '', show_input: false });

                    if (res.data.answer.video.trans_coded_url != null) {

                        this.setState({ videoUrl: res.data.answer.video.trans_coded_url, videoType: 'application/x-mpegURL' });

                    } else if(res.data.answer.video.type == "audio") {
                        this.setState({ videoUrl : res.data.answer.video.media_file})
                    } else {
                        this.setState({ no_transcoding: true });
                    }
                }
            });
        } else {
            if (localStorage.getItem('super')) {
                url = `question/detail/${id}/?author_id=${this.state.detail.author}&user_type=admin&view_id=${this.props.match.params.id}`;
            } else {
                url = `question/detail/${id}/?author_id=${this.state.detail.author}&query=yes&view_id=${this.props.match.params.id}`;
            }

            this.props.getInteractParticularQuestionDetail(url, '', (res) => {
                this.setState({ showHints: false });
                if (res.data && res.data.answer.id) {
                    this.setState({ recordedAudio: {} });

                    if (res.data && res.data.answer.id) {
                        this.setState({ recording: true, searchinput: '', show_input: false });

                        if (res.data.answer.video.trans_coded_url != null) {

                            this.setState({ videoUrl: res.data.answer.video.trans_coded_url, videoType: "application/x-mpegURL" });

                        } else if(res.data.answer.video.type == "audio") {
                            this.setState({ videoUrl : res.data.answer.video.media_file})
                        } else {
                            this.setState({ no_transcoding: true });
                        }

                    }
                }
            });
        }

    };

    responseReceive(response) {
        if (response.interpretations && response.interpretations.length > 0) {
            this.sendToGet(response.inputTranscript, response.interpretations, 'audio');
        }
    }

    interactResponseReceive(response) {
        if(response == true) {
            this.setState({ recording: false, askQuestionBit: false });
        } 
    }

    sendToGet(question, interpretations, query_type) {
        this.setState({ askQuestionBit: true, no_transcoding: false });

        let formData = new FormData();

        formData.append("question", question);
        formData.append("language", 1);
        formData.append("apikey", "dsDKL4342jertn6438");
        formData.append("type", "normal");
        formData.append("duration", "0");
        formData.append("interact_user_id", this.props.match.params.author);
        formData.append("interact_view_id", this.props.match.params.id);
        formData.append("interpretations", JSON.stringify(interpretations));
        formData.append("query_type", query_type); // query_type:audio

        this.props.postInteractQuestion(formData, this.props.auth.loginUserToken ? this.props.auth.loginUserToken : '', (res) => {
            this.setState({ recordedAudio: {} });
            this.setState({ showHints: false });
            if(res.data) {
                this.setState({ recording: true, searchinput: '', show_input: false, askQuestionBit: false });

                if (res.data && res.data.data.answer.video.type == "audio") {
                    this.setState({ videoUrl: res.data.data.answer.video.media_file });

                } else {
                    this.setState({ videoUrl: res.data.data.answer.video.trans_coded_url, videoType: "application/x-mpegURL" });
                }
            }

        });
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    showHintBulb = (e) => {
        this.setState({ showHints: !this.state.showHints });
    }

    showInput = (e) => {
        this.setState({ show_input: !this.state.show_input });
    }

    showModalInput = (e) => {
        this.setState({ show_modal_input: !this.state.show_modal_input });
    }

    submitQuestion = async (e) => {
        e.preventDefault();
        this.setState({askQuestionBit : true});
        const command = new RecognizeTextCommand({
            "botAliasId": "TSTALIASID",
            "botId": this.state.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57",
            "localeId": "en_US",
            "text": this.state.searchinput,
            "sessionId": "043758711856848"
        });

        const response = await client.send(command);
        if (response.interpretations && response.interpretations.length > 0) {
            this.sendToGet(this.state.searchinput, response.interpretations, 'text');
        }
    }

    likeView = (e) => {
        e.preventDefault();

        this.props.likeEternaview(this.props.match.params.id, this.props.auth.loginUserToken, (res) => {
            if (!res.detail) {
                this.setState({ showLike: true });
                setTimeout(
                    () => this.setState({ showLike: false }),
                    3000
                );
            }

        });
    }

    closePopUp = (e) => {
        e.preventDefault();

        this.setState({ maxInteract: false });
    }


    render() {
        const { show_input, orgUrl, videoUrl, videoType, recording, micRecording, moduleQuestions, detail, showLike, showHints, no_transcoding, blobURL, category } = this.state;

        return (
            <div>
                <section className="custom_img5 custom_video06">
                    {(recording == true && no_transcoding == true) && <div className="processed_video_outer">
                        <img src={Loader} />
                        <p>Response for this answer is getting processed.</p>
                        <p>Please try again after sometime</p>
                    </div>}

                    {!this.state.noInteract ? (<a>
                        {micRecording == true && <div className="recording-interact"><img src={mic} /></div>}

                        {(detail && detail.type == "audio") && (<InteractAudio setInteractResponse={(val) => this.interactResponseReceive(val)} recording={recording} orgUrl={orgUrl} videoUrl={videoUrl} detail={detail}  />)}

                        {(detail && detail.type == "video") && (<InteractVideo setInteractResponse={(val) => this.interactResponseReceive(val)} recording={recording} orgUrl={orgUrl} videoUrl={videoUrl} videoType={videoType} no_transcoding={no_transcoding} detail={detail} />)}
                       
                    </a>) : (<div className="processed_video_outer">
                        <p>Sorry, this eternaview is deactivated.</p>
                        <p>It is not accessible at the moment.</p>
                    </div>)
                    }

                    <div className="expend_outer">
                        <a href="#myModal" data-toggle="modal"><i className="fa fa-expand" aria-hidden="true"></i></a>
                    </div>

                    <div id="myModal201" className={this.state.maxInteract == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <p>Sorry, this Eternaview has reached its maximum allowance in terms of the queries that can be made to it. Please let the creator know you would like to speak to their Eternaview so that they may replenish their plan.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            {this.state.hideInteractOptions == false && <div className="col-md-6 custom_postiiou78">

                                <div className="brea_outer viedo_overlap" onClick={this.showInput}><a><i className="far fa-keyboard key_custom"></i></a></div>

                                <Recorder setBotResponse={(val) => this.responseReceive(val)} client={client} category={category} setBlobURL={blobURL} />
                            </div>}
                        </div>

                        {show_input == false && <div className="col-md-6">
                        </div>}

                        {show_input == true && <div className="col-md-6"><div className="input67 video_overlap">
                            <input type="text" placeholder="Type in your question:" name="searchinput" value={this.state.searchinput} onChange={this.onInputchange} />
                            <div className="btn_outer45">

                                {!this.state.askQuestionBit ? (<button type="button" className="btn" onClick={this.submitQuestion}>Ask Question</button>) :
                                (<button type="button" className="btn">Asking...</button>)
                                }
                                
                            </div>
                        </div>
                        </div>}
                    </div>

                    {this.state.noInteract == false && <div className="small_inty6 custom_video89">
                        <img src={small_integote} onClick={this.showHintBulb} />
                    </div>}

                    {(showHints == true && this.state.hideInteractOptions == false) && <div className="custtom_according interact-hint">
                        <div className="bs-example">
                            <div className="accordion" id="accordionExample">
                                {moduleQuestions?.map((subcat, ind) => {
                                    return (
                                        <div className="card" key={subcat.id}>
                                            <div className="card-header" id={"headingOne" + ind}>
                                                <h2 className="mb-0">
                                                    <button type="button" className="btn btn-link" data-toggle="collapse" data-target={"#collapseOne" + ind}>{subcat.subcategory_name}<i className="fa fa-angle-down"></i> </button>
                                                </h2>
                                            </div>
                                            <div id={"collapseOne" + ind} className="collapse" aria-labelledby={"headingOne" + ind} data-parent="#accordionExample">
                                                <div className="card-body">
                                                    {
                                                        subcat.questions.map((ques, index) => {
                                                            return (
                                                                <div key={ques.id}>
                                                                    <button onClick={() => { this.questionVideo(ques.id) }}>{ques.question} </button>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    </div>}

                </section>

                <section className="doiect_outer crete29 cus672 create-paid">
                    <div className="container">
                        <div className="thamnail">
                            {showLike && <p className="custom_light">Your like has been submitted successfully</p>}
                            {this.state.noInteract == false && <img src={hand_outer4} onClick={this.likeView} />}
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="cust7">
                                    <div className="being56 cre45 mic">
                                        <h4>To Begin Interacting by Voice:</h4>
                                        <div className="small_ingt52 mick32">
                                        <img src={red_mick} />
                                            {/* <audio
                                                ref={a => {
                                                    this.audio = a;
                                                }}
                                            >
                                            </audio> */}
                                        </div>
                                    </div>
                                    <div className="told_outer">
                                        <img src={purpal_check} />
                                        <p>Click on the microphone icon above to begin recording.</p>
                                    </div>
                                    <div className="told_outer mic_told_outer">
                                        <img src={purpal_check} />
                                        <p>Speak your question into your device's microphone.</p>
                                        
                                    </div>
                                    <div className="told_outer">
                                        <img src={purpal_check} />
                                        <p>Click the microphone icon again to stop recording.</p>
                                    </div>
                                    <div className="told_outer">
                                        <img src={purpal_check} />
                                        <p>Wait to hear an answer!</p>
                                    </div>
                                </div>
                                <div className="cust7 custom_cust7">
                                    <div className="being56 cre45 mic">
                                        <h4>To Begin Interacting by Keyboard:</h4>
                                        <div className="small_ingt52 small_ing45 brea_outer viedo_overlap">
                                            {/* <img src={hand_key} /> */}
                                            <i className="far fa-keyboard key_custom"></i>
                                            {/* <div className="brea_outer viedo_overlap"><i className="far fa-keyboard key_custom"></i></div> */}
                                        </div>
                                    </div>
                                    <div className="told_outer">
                                        <img src={green} />
                                        <p>Click on the keyboard icon above.</p>
                                    </div>
                                    <div className="told_outer">
                                        <img src={green} />
                                        <p>Type in your question then hit “Ask Question.”</p>
                                        
                                    </div>
                                    <div className="told_outer">
                                        <img src={green} />
                                        <p>Wait to hear an answer!</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="right_side_intracy">
                                    <div className="mail_outer sawdow_inner grey5 custom_inte45">
                                        <h5>Title: {detail.title}</h5>
                                        <div className="row border42">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="left450">
                                                    <p>Total Time Recorded:</p>
                                                    <h5>{detail.total_time}</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="left450">
                                                    <p>Date Created:</p>
                                                    <h5>{Moment(detail.created_on).format('MM-DD-YYYY')}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row border42">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="left450">
                                                    <p># Questions Answered:</p>
                                                    <h5>{detail.total_question_recorded}</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="left450">
                                                    <p>Last Updated:</p>
                                                    <h5>{Moment(detail.updated_on).format('MM-DD-YYYY')}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row border42">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="left450">
                                                    <p>Bio:</p>
                                                    <p className="dummy_tex5">{detail.author__bio != "" ? detail.author__bio : "No bio added!"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg_color custom_colore4">
                                    <p><b>Note:</b> You can also just click on the questions (?) in the drop down menu if you prefer to interact that way.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
    postInteractQuestion: bindActionCreators(postInteractQuestion, dispatch),
    getEternaviewDetail: bindActionCreators(getEternaviewDetail, dispatch),
    getAnsweredQuestions: bindActionCreators(getAnsweredQuestions, dispatch),
    likeEternaview: bindActionCreators(likeEternaview, dispatch),
    verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch),
    getParticularQuestionDetail: bindActionCreators(getParticularQuestionDetail, dispatch),
    getInteractParticularQuestionDetail: bindActionCreators(getInteractParticularQuestionDetail, dispatch),
    getQueryDetail: bindActionCreators(getQueryDetail, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InteractOne);
