import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import arrow_right from "../../Images/arrow_right.png";
import righ65 from "../../Images/righ65.png";
import blog1 from "../../Images/blog1.png";
import { blogDetail } from "../../redux/actions/index";

function BlogDetail(props) {
    const dispatch = useDispatch();
    const paramvalue = useParams();
    const [blog, setBlog] = useState([]);

    const auth = useSelector(state => state);

    useEffect(() => {
        getBlogsListing();
    }, []);

    function getBlogsListing() {
        dispatch(blogDetail(paramvalue.id, '', (result) => {
            setBlog(result.data);
        }));
    }

    return (
        <section className="blog_outer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="terms_inner">
                            <div className="plan_outer2 textdeco56"><h4>Blog Detail</h4></div>
                            <div className="terms_content">
                                <div className="blog_detail_img">
                                    {blog.image != '' && <img src={blog.image} alt="b1" />}
                                    {/* {blog.image == '' && <img src={blog1} alt="b1" />} */}
                                </div>
                                <div className="blog_detail_title">{blog.title}</div>

                                <div dangerouslySetInnerHTML={ { __html: blog.content}}></div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default BlogDetail;
