import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getPlans } from "../../redux/actions/index";

function GeneralPlans(props) {
    const dispatch = useDispatch();
    const paramvalue = useParams();
    const [priceLookupKey, setPriceLookupKey] = useState(null);
    const [planId, setPlanId] = useState(null);
    const [planAmount, setPlanAmount] = useState(null);
    const [plans, setPlans] = useState([]);
    const [paramUrl, setParamUrl] = useState('');
    const [catName, setCatName] = useState();
    const auth = useSelector(state => state);

    useEffect(() => {

        dispatch(getPlans(auth.auth.loginUserToken? auth.auth.loginUserToken : '', (result) => {
            setPlans(result.data);
        }));

        setParamUrl(paramvalue.id);

        // const headers = {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + auth.auth.loginUserToken
        // };
        // axios.get('http://eterna.itechnolabs.tech/plans/', { headers })
        //     .then(response => setPlans(response.data.data))
        //     .catch(error => {
        //         console.error('There was an error!', error);
        //     });
    }, []);


    function getPlan(price, plan, amount) {
        setPriceLookupKey(price);
        setPlanId(plan);
        setPlanAmount(amount);
    }

    return (

        <section className="plan_outer new_plan">
            <div className="container">
                <div className="plan_outer2">
                    <h4>Pricing Plans</h4>
                    <p>Here are our Pricing Plans for creating your Eternaview. It is required to purchase one of these plans if you want <br />
                        to begin the process of creating your Eternaview (feel free to try out a free practice<br />
                        Eternaview in the meantime).
                    </p>
                </div>
                <div className="table-responsive prceing_plan">
                <table className="table table-bordered compatibility">
                        <thead>
                            <tr>
                                <th colSpan="4">
                                    <h5 className="custyo">Pricing Plans</h5>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    </div>
                    <div className="table-responsive prceing_plan">
                    <table className="table table-bordered col_table_custom">
                        <tbody>
                            <tr className="first_col">
                                <td className="no_color"></td>
                                <td className="no_color">What types of Eternaviews is<br /> this plan used for?</td>
                                <td className="no_color">Upfront Creation Price<br />
                                    (one-time fee)
                                </td>
                                <td className="no_color">Annual Subscription Price<br />
                                    (charged day 1 and then every year)
                                </td>
                            </tr>
                            {plans && plans.map(plan => (
                                <tr className="custom_left">
                                    <td className="title_td">{plan.name} Plan</td>
                                    {plan.name_slug == "Legacy" && <td className="custom_cnter">{plan.name}</td>}
                                    {plan.name_slug == "Dating" && <td className="custom_cnter">Dating</td>}
                                    {plan.name_slug == "Audio-only" && <td className="custom_cnter">Audio-only<br /> (i.e. no video) <br /> for either Legacy or Dating</td>}
                                    <td className="custom_cnter">${plan.plans[1].amount}</td>
                                    <td className="custom_cnter">${plan.plans[0].amount}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered compatibility">
                        <thead>
                            <tr>
                                <th colSpan="4">
                                    <h5 className="custyo">Compatibility</h5>
                                    <p className="custom67">Initial Recording Can Be Made on and Viewers Can Interact w/the Recording on Any of the Following:<br />
                                        (there may be some limitations in the case of very old hardware or software versions)
                                    </p>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered col_table_custom">
                        <tbody>
                            <tr className="first_col">
                                <td className="no_color"></td>
                                <td className="no_color">PC's - desktop or laptop computers-<br /> running Windows and Apple(iOS)</td>
                                {/* <td className="no_color">Chromebooks</td> */}
                                <td className="no_color">Tablets running Android or<br /> Apple(iOS)</td>
                                <td className="no_color">Mobile phones running Android or <br /> Apple(iOS)</td>
                                <td className="no_color">Chromebooks</td>
                            </tr>
                            {plans && plans.map(plan => (
                                <tr className="custom_left">
                                    <td className="title_td">{plan.name} Plan</td>
                                    {/* {plan.name == "Legacy" && <td className="custom_cnter">{plan.name}</td>}
                                    {plan.name == "Chat Plan" && <td className="custom_cnter">Dating</td>}
                                    {plan.name == "Audio Only Plan" && <td className="custom_cnter">Almost any Audio-<br /> only (i.e. no video) <br /> for either Legacy or Dating</td>} */}
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                <div className="bg_gren">
                    <p>May be compatible with some additional devices as well but we cannot confirm in advance.</p>
                </div>
                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered compatibility">
                        <thead>
                            <tr>
                                <th colSpan="4">
                                    <h5 className="custyo">Includes</h5>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered col_table_custom">
                        <tbody>
                            <tr className="first_col">
                                <td className="no_color"></td>
                                <td className="no_color">Recording through your <br />webcam or mobile camera and/or microphone</td>
                                <td className="no_color">Recording in Full HD (upto 1080p, <br />depending on resolution of recording device)</td>
                                <td className="no_color">Recording is processed for optimal playback on all compatible devices and screens</td>
                                <td className="no_color">Adaptive bit-rate streaming to<br />  optimize performance at<br />  different internet speeds</td>
                                <td className="no_color">Storage on Amazon Web<br /> Services</td>
                                <td className="no_color">Responds to questions spoken by the viewer of your Eternaview</td>
                                <td className="no_color">Responds to questions typed in by the viewer of your Eternaview</td>
                                <td className="no_color">Eternaview indexed by each recorded answer, w/ easy clicking to retrieve any answer</td>
                                <td className="no_color">The ability to set private or<br /> public viewing options and to<br /> send invites</td>
                                <td className="no_color">No charge to the viewers that<br /> you want to view/interact<br /> with your eternaview</td>
                            </tr>
                            {plans && plans.map(plan => (
                                <tr className="custom_left">
                                    <td className="title_td">{plan.name} Plan</td>
                                    {/* {plan.name == "Legacy" && <td className="custom_cnter">{plan.name}</td>}
                                    {plan.name == "Chat Plan" && <td className="custom_cnter">Dating</td>}
                                    {plan.name == "Audio Only Plan" && <td className="custom_cnter">Almost any Audio-<br /> only (i.e. no video) <br /> for either Legacy or Dating</td>} */}
                                    {plan.name_slug == "Legacy" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Dating" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Audio-only" && <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>}

                                    {plan.name_slug == "Legacy" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Dating" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Audio-only" && <td className="custom_cnter">N/A</td>}

                                    {plan.name_slug == "Legacy" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Dating" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Audio-only" && <td className="custom_cnter">N/A</td>}
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered compatibility">
                        <thead>
                            <tr>
                                <th colSpan="4">
                                    <h5 className="custyo">Usage Allowances*</h5>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered col_table_custom">
                        <tbody>
                            <tr className="first_col">
                                <td className="no_color"></td>
                                <td className="no_color"># of Eternaviews (i.e. full interviews) you can create per subscription**</td>
                                <td className="no_color">Total length of Eternaview recording (you likely will not need anywhere near this much time)</td>
                                <td className="no_color"># of answers that can be reviewed and re-recorded before submitting that particular answer</td>
                                <td className="no_color"># of answers that can be edited after initial submissions</td>
                                <td className="no_color"># of questions that viewers can ask your Eternaview***</td>
                            </tr>
                            {plans && plans.map(plan => (
                                <tr className="custom_left">
                                    <td className="title_td">{plan.name} Plan</td>
                                    {/* {plan.name == "Legacy" && <td className="custom_cnter">"{plan.name}"</td>}
                                    {plan.name == "Chat Plan" && <td>"Dating" and Most<br /> "Create Your Own"<br /> Eternaviews</td>}
                                    {plan.name == "Audio Only Plan" && <td>Almost any Audio-<br /> only (i.e. no video) <br /> Eternaview</td>}  */}
                                    <td className="custom_cnter">1</td>
                                    <td className="custom_cnter">{plan.total_time} hours</td>
                                    <td className="custom_cnter">Unlimited</td>
                                    <td className="custom_cnter">Up to {plan.no_of_edit} per year</td>
                                    <td className="custom_cnter">Up to {plan.no_of_query} per year</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div className="box455">
                    <p>
                        *If customers need to increase their usage allowances they will have the ability to renew early in order to reset their usage allowances.</p>
                    <p>Certain exceptions may apply to Usage Allowances, as detailed in our Terms of Use.</p>
                    <p>
                        **Note that a single Eternaview (i.e. interview) consists of your answers to anywhere from 75 to 175+ questions that you will be shown during the recording process.</p>
                    <p>***This includes whether questions are asked in spoken or text form, whether viewer just clicks directly on the answers they want to hear, or any other way individual
                        answers may be played back in the future. It also includes any questions the creator asks of their own Eternaview. </p>
                    <p>Keep in mind that if you make your Eternaview public that there may be more questions asked of it.</p>
                </div>
                <div className="row contact_outer">
                    <div className="col-lg-8 col-md-12 offset-lg-2">
                        <div className="row bg_color67">
                            <div className="col-lg-8">
                                <div className="leg556">
                                    <h3>Custom Plan</h3>
                                    <p>If you are a celebrity, influencer, corporate exec
                                        or otherwise expect very high viewership, please
                                        shoot us an email with more details
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="conty6">
                                    <Link to='/home/contact-us/'><button className="btn cont-btn">Contact Us</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default GeneralPlans;
