import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LexRuntimeV2Client, RecognizeTextCommand, RecognizeUtteranceCommand } from "@aws-sdk/client-lex-runtime-v2";
import bg29 from "../../Images/bg29.png";
import real_think from "../../Images/real_think.png";
import small_integote from "../../Images/small_integote.png";
import purpal_check from "../../Images/purpal_check.png";
import micck from "../../Images/micck.png";
import mic from "../../Images/mic.gif";
import green from "../../Images/green.png";
import red_mick from "../../Images/red_mick.png";
import hand_key from "../../Images/hand_key.png";
import { toast } from 'react-toastify';
import { postPracticeInteractQuestion, getPracticeNodding, getPracticeAnswered, getPracticeEternaviews, practiceQuestionDetail } from '../../redux/actions';
import audio_signal from "../../Images/audio_signal.png";
import vector_audio from "../../Images/vector_audio.jpg";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Loader from "../../Images/buffer.gif";
import Recorder from "../../components/Recorder";

const videoJsOptions = {
    controls: false,
    bigPlayButton: false,
    autoplay: true,
    responsive: false,
    width: 480,
    height: 320,
    fluid: true
};

const videoJsOptions1 = {
    controls: false,
    bigPlayButton: false,
    autoplay: true,
    responsive: false,
    width: 480,
    height: 320,
    fluid: true
}

const client = new LexRuntimeV2Client({ region: "us-east-1", credentials: { accessKeyId: "AKIAQUMBY6QYP5X7UIXX", secretAccessKey: "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" } });

class PracticeInteract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stream: null,
            hasMicrophone: false,
            recording: false,
            recordedAudio: {},
            show_input: false,
            show_modal_input: false,
            searchinput: '',
            videoUrl: '',
            videoType: '',
            orgUrl: '',
            interact_author: '',
            micRecording: false,
            micModalRecording: false,
            moduleQuestions: [],
            showHints: false,
            askQuestionBit: false,
            firstTimePop: true,
            no_transcoding: false,
            practice_detail: {},
            maxQuery: false,
            blobURL: '',
            botResponse: '',
            isBlocked: ''
        };
        this.onInputchange = this.onInputchange.bind(this);
    }

    componentDidMount() {

        this.player = videojs(this.videoNode, videoJsOptions, function onPlayerReady() {
            console.log('onPlayerReady', this)
        });
        // device is ready
        this.player.on('deviceReady', () => {
            console.log('device is ready!');
        });

        this.newPlayer = videojs(this.newVideo, videoJsOptions1, function onPlayerReady() {
            console.log('onPlayerReady', this)
        });

        this.newPlayer.on('deviceReady', () => {
            console.log('device is ready!');
        });

        this.props.getPracticeNodding(this.props.auth.loginUserToken, (res) => {
            this.setState({ orgUrl: res.nodding_video, interact_author: res.author_id });
            this.player.src({
                src: res.nodding_video,
                type: 'application/x-mpegURL'
            });
        });

        this.props.getPracticeAnswered(this.props.auth.loginUserToken, (res) => {
            if (res.data.length > 0) {
                let data = res.data, questions = [];
                data.map((subcat, ind) => {
                    if (subcat.transition == false) {
                        questions.push(subcat);
                    }
                });

                this.setState({ moduleQuestions: questions });
            }
        })

        this.props.getPracticeEternaviews(this.props.auth.loginUserToken, (res) => {
            if (!res.error || !res.msg) {
                if (res.data) {
                    this.setState({ practice_detail: res.data });
                    if (res.data.no_of_query == 0) {
                        this.setState({ maxQuery: true });
                    }
                }
            }

        });
    }


    responseReceive(response) {
        if (response.interpretations && response.interpretations.length > 0) {
            this.sendToGet(response.inputTranscript, response.interpretations, 'audio');
        }
    }

    submitQuestion = async (e) => {
        e.preventDefault();
        const command = new RecognizeTextCommand({
            "botAliasId": "TSTALIASID",
            "botId": "RMEHNYXF57",
            "localeId": "en_US",
            "text": this.state.searchinput,
            "sessionId": "043758711856848"
        });

        const response = await client.send(command);
        if (response.interpretations && response.interpretations.length > 0) {
            this.sendToGet(this.state.searchinput, response.interpretations, 'text');
        }
        //  this.sendToGet(this.state.searchinput, 'question');
    }

    showHintBulb = (e) => {
        this.setState({ showHints: !this.state.showHints });
    }

    sendToGet(question, interpretations, query_type) {
        this.setState({ askQuestionBit: true, no_transcoding: false });
        let formData = new FormData();

        formData.append("question", question);
        formData.append("language", 1);
        formData.append("apikey", "dsDKL4342jertn6438");
        formData.append("type", "practice");
        formData.append("duration", "0");
        formData.append("interact_view_id", this.state.practice_detail.id);
        formData.append("interact_user_id", this.state.interact_author);
        formData.append("interpretations", JSON.stringify(interpretations));
        formData.append("query_type", query_type); // query_type:audio

        this.props.postPracticeInteractQuestion(formData, this.props.auth.loginUserToken, (res) => {
            console.log(res);
            this.setState({ showHints: false });
            if (res.data && res.data.data.answer.video) {
                this.setState({ recording: true, searchinput: '', show_input: false, askQuestionBit: false });

                if (res.data.data.answer.video.trans_coded_url != null) {
                    this.newPlayer.src({
                        src: res.data.data.answer.video.trans_coded_url,
                        type: 'application/x-mpegURL'
                    });
                    this.setState({ videoUrl: res.data.data.answer.video.trans_coded_url, videoType: 'application/x-mpegURL' });

                    this.newPlayer.play();
                } else {
                    this.setState({ no_transcoding: true });
                }

            }

        });
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    myCallback = () => {
        console.log('Video has ended');
        this.setState({ recording: false });
    };

    showInput = (e) => {
        e.preventDefault();

        this.setState({ show_input: !this.state.show_input });
    }

    showModalInput = (e) => {
        this.setState({ show_modal_input: !this.state.show_modal_input });
    }

    noRefCheck(selectedList, selectedItem) {
        console.log(selectedList);
        console.log(selectedItem);
    }

    closePopUp = (e) => {
        e.preventDefault();

        this.setState({ firstTimePop: false });
    }

    closeQueryPopUp = (e) => {
        e.preventDefault();

        this.setState({ maxQuery: false });
    }

    questionVideo = (id) => {
        this.setState({ no_transcoding: false });
        this.props.practiceQuestionDetail(id, this.props.auth.loginUserToken, (res) => {
            this.setState({ showHints: false });
            if (res.data && res.data.answer.id) {
                this.setState({ recordedAudio: {} });

                this.setState({ recording: true, searchinput: '', show_input: false });

                let src = '', videoType = '';

                if (res.data.answer.video.trans_coded_url != null) {
                    src = res.data.answer.video.trans_coded_url;
                    videoType = "application/x-mpegURL";

                    this.newPlayer.src({
                        src: res.data.answer.video.trans_coded_url,
                        type: 'application/x-mpegURL'
                    });

                    this.setState({ videoUrl: src, videoType: videoType });

                    this.newPlayer.play();
                } else {
                    this.setState({ no_transcoding: true });
                }
            }
        });
    };

    render() {
        const { show_input, orgUrl, videoUrl, recording, micRecording, micModalRecording, selectedOption, moduleQuestions, detail, show_modal_input, videoType, showHints, askQuestionBit, no_transcoding, blobURL } = this.state;

        return (
            <div>
                <section className="custom_img5 custom_video06">
                    <div className="expend_outer">
                        <a href="#myModal" data-toggle="modal"><i className="fa fa-expand" aria-hidden="true"></i></a>
                    </div>

                    <div id="myModal201" className={this.state.firstTimePop == false ? "modal fade show show_custom_modal" : "modal fade show custom_modal"} role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close custom_close3" data-dismiss="modal" onClick={this.closePopUp}>&times;</button>

                                </div>
                                <div className="modal-body">
                                    <p>Now that you've finished recording a few practice answers, you can try talking to yourself about the topics you just answered!</p>
                                    <p>(For the real thing, you would of course invite other viewers to interact with your recording)</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.closePopUp}>Proceed</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="myModal201" className={this.state.maxQuery == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {/* <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button> */}

                                </div>
                                <div className="modal-body">
                                    <p>Sorry, you have reached the maximum number of queries to your practice Eternaview. We invite you to create a full-length Eternaview if you would like to tell more about your story and invite others to interact with it.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.closeQueryPopUp}>Okay</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(recording == true && no_transcoding == true) && <div className="processed_video_outer">
                        <img src={Loader} />
                        <p>Response for this answer is getting processed.</p>
                        <p>Please try again after sometime</p>
                    </div>}

                    {/* <img src={bg29} /> */}
                    <a>
                        {micRecording == true && <div className="recording-interact"><img src={mic} /></div>}

                        {(recording == false && orgUrl == '') && <img className="interact-img" src={vector_audio} />}

                        <div className={(recording == false && orgUrl != '') ? "" : "congratulation-video-hide"}>
                            <div data-vjs-player>
                                <video id="myVideo" ref={node => this.videoNode = node} className="video-js vjs-default-skin custom_video_js" options={videoJsOptions} playsInline loop></video>
                            </div>
                        </div>

                        <div className={(recording == true && no_transcoding == false) ? "" : "congratulation-video-hide"}>
                            <div data-vjs-player>
                                <video id="secondVideo" ref={node => this.newVideo = node} width="480" height="320" className="video-js vjs-default-skin custom_video_js" onEnded={() => this.myCallback()}>
                                    <source src={videoUrl} type={videoType}></source>
                                </video>
                            </div>
                        </div>

                        {/* </div> */}
                    </a>

                    <div className="container">
                        <div className="row">
                            {this.state.maxQuery == false && <div className="col-md-6 custom_postiiou78">
                                <div className="brea_outer viedo_overlap" onClick={this.showInput}><a><i className="far fa-keyboard key_custom"></i></a></div>
                                {/* {micRecording == false && <div className="right_mike4" onClick={this.recordQuestion}>
                                    <img src={red_mick} />
                                    <span>Click To Talk</span>
                                </div>}
                                {micRecording == true && <div className="right_mike4 bg_hover" onClick={this.recordQuestion}>
                                    <img src={red_mick} />
                                    <span>Click To Stop</span>
                                </div>} */}
                                <Recorder setBotResponse={(val) => this.responseReceive(val)} client={client} setBlobURL={blobURL} />
                            </div>}
                        </div>

                        {show_input == false && <div className="col-md-6">
                        </div>}

                        {show_input == true && <div className="col-md-6"><div className="input67 video_overlap">
                            <input type="text" placeholder="Type in your question:" name="searchinput" value={this.state.searchinput} onChange={this.onInputchange} />
                            <div className="btn_outer45">
                                {this.state.askQuestionBit == false && <button type="button" className="btn" onClick={this.submitQuestion}>Ask Question</button>}
                                {this.state.askQuestionBit == true && <button type="button" className="btn">Asking...</button>}
                            </div>
                        </div>
                        </div>}
                    </div>

                    <div className="small_inty6 custom_video89">
                        {/* <div className="chat56"><img src={real_think} /></div> */}
                        <img src={small_integote} onClick={this.showHintBulb} />
                        
                    </div>

                    {showHints == true && <div className="custtom_according interact-hint">
                        <div className="bs-example multi_bs_example">
                            <div className="accordion" id="accordionExample">
                                {moduleQuestions?.map((subcat, ind) => {
                                    return (
                                        <div className="card">
                                            <div className="card-body">
                                                <button onClick={() => { this.questionVideo(subcat.id) }}>{subcat.question} </button>
                                            </div>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    </div>}
                </section>

                <section className="doiect_outer crete29 cus672">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="cust7">
                                    <div className="being56 cre45 mic">
                                        <h4>To Begin Interacting by Voice:</h4>
                                        <div className="small_ingt52">
                                        <img src={red_mick} />
                                        {/* onClick={e => this.recordQuestion(e)} */}
                                        {/* <audio
                                            ref={a => {
                                                this.audio = a;
                                            }}
                                        >
                                        </audio> */}
                                    </div>
                                    </div>

                                    <div className="told_outer"><img src={purpal_check} /><p>Click on the microphone icon above to begin recording.</p></div>
                                    <div className="told_outer"><img src={purpal_check} /><p>Speak your question into your device's microphone.</p>
                                    </div>
                                    <div className="told_outer"><img src={purpal_check} /><p>Click the microphone icon again to stop recording.</p></div>
                                    <div className="told_outer"><img src={purpal_check} /><p>Wait to hear an answer!</p></div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="cust7">
                                    <div className="being56 cre45 mic"><h4>To Begin Interacting by Keyboard:</h4>
                                    <div className="small_ingt52">
                                        {/* <img src={hand_key} /> */}
                                        <div className="small_ingt52 small_ing45 brea_outer viedo_overlap"><i className="far fa-keyboard key_custom"></i></div>
                                    </div>
                                    </div>

                                    <div className="told_outer"><img src={green} /><p>Click on the keyboard icon above.</p></div>
                                    <div className="told_outer"><img src={green} /><p>Type in your question then hit “Ask Question.”</p>  </div>
                                    <div className="told_outer"><img src={green} /><p>Wait to hear an answer!</p></div>

                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="bg_color">
                                    <p><b>Note:</b> You can also just click on the questions (?) in the drop down menu if you prefer to interact that way.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
    postPracticeInteractQuestion: bindActionCreators(postPracticeInteractQuestion, dispatch),
    getPracticeNodding: bindActionCreators(getPracticeNodding, dispatch),
    getPracticeAnswered: bindActionCreators(getPracticeAnswered, dispatch),
    getPracticeEternaviews: bindActionCreators(getPracticeEternaviews, dispatch),
    practiceQuestionDetail: bindActionCreators(practiceQuestionDetail, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeInteract);
