import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionDetail } from "../../redux/actions/index";

const AccountSubscription = ({ subscription }) => {
  const auth = useSelector(state => state);

  return (
    <section className="intract_outer_subscription">
      <hr />
      <h4>
        <a href={`https://dashboard.stripe.com/test/subscriptions/${subscription.id}`}>
          {subscription.id}
        </a>
      </h4>

      <p>
        Status: <span>{subscription.status}</span>
      </p>

      <p>
        Card last4: <span>{subscription.default_payment_method.card.last4}</span>
      </p>

      <p>
        Current period end: <span>{(new Date(subscription.current_period_end * 1000).toString())}</span>
      </p>

      {/* <Link to={{pathname: '/change-plan', state: {subscription: subscription.id }}}>Change plan</Link><br /> */}
      <Link className="cancel_sub" to={{ pathname: '/home/cancel', state: { subscription: subscription.id } }}>Cancel</Link>
    </section>
  )
}

const Account = (props) => {
  const dispatch = useDispatch();
  const [subscriptions, setSubscriptions] = useState([]);
  const auth = useSelector(state => state);

  useEffect(() => {
    if (auth.auth.loginUserToken == "") {
      props.history.push('/');
    } else {

      const fetchData = async () => {
        // const { subscriptions } = await fetch('http://eterna.itechnolabs.tech/subscription/detail/', {
        //   method: 'GET',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + auth.auth.loginUserToken
        //   }
        // }).then(r => r.json());


        dispatch(getSubscriptionDetail(auth.auth.loginUserToken, (result) => {
          console.log(result);
          setSubscriptions(result.subscriptions.data);
        }));

      }

      fetchData();

    }

  }, []);

  if (!subscriptions) {
    return '';
  }

  return (
    <div className="add_subscription_outer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="account_sub">
              <h1>Account</h1>
              <a href="/home/plans">Add a subscription</a>
            </div>

            <h2>Subscriptions</h2>

            <div id="subscriptions">
              {subscriptions.map(s => {
                return <AccountSubscription key={s.id} subscription={s} />
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Account);