import React, {
  useState, useEffect
} from "react";
import { Link } from "react-router-dom";
import edit from "../../Images/edit.png";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { register } from "../../redux/actions";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function SignUp(props) {
  const dispatch = useDispatch();
  const auth = useSelector(state => state);

  //const [showLoader, setShowLoader] = useState(false);
  let [email, setEmail] = useState('')
  let [emailError, setEmailError] = useState('')
  let [password, setPassword] = useState('')
  let [passwordError, setPasswordError] = useState('')
  let [cpassword, setConfirmPassword] = useState('')
  let [cpasswordError, setConfirmPasswordError] = useState('')
  let [inputtype, setInputType] = useState(false);
  let [inputtype1, setInput1Type] = useState(false);
  let [termsConditions, setTermsType] = useState(false);
  let [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (auth.auth.loginUserToken != "") {
      props.history.push('/');
    }
    setInputType(false);
  }, []);

  function showPassword() {
    setInputType(!inputtype);
  };

  function showConfirmPassword() {
    setInput1Type(!inputtype1);
  };

  function handleEmailInput(event) {
    setEmail(event.target.value)
    setEmailError(validate('email', event.target.value))
  };

  function handlePasswordInput(event) {
    setPassword(event.target.value)
    setPasswordError(validate('password', event.target.value))
  };

  function handleConfirmPasswordInput(event) {
    setConfirmPassword(event.target.value)
    setConfirmPasswordError(validate('cpassword', event.target.value))
  };

  function handleTermsConditions(event) {
    setTermsType(event.target.value);
  }

  function validate(name, value) {
    switch (name) {
      case "email":
        if (!value || value.trim() === "") {
          return "Email is required.";
        } else {
          return "";
        }
      case "password":
        if (!value || value.trim() === "") {
          return "Password is required.";
        } else {
          var reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
          var test = reg.test(value);
          if (test) {
            return "";
          } else {
            return "Please enter password within range of 8-15 characters including atleast a number and a special character.";
          }
        }
      case "cpassword":
        if (!value || value.trim() === "") {
          return "Confirm Password is required.";
        } else {
          return "";
        }
      default: {
        return "";
      }
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (password != cpassword) {
      toast("Please check your passwords.", {
        position: "top-right",
        autoClose: 3000,
        type: 'error',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return;
    }
    if (termsConditions == false) {
      toast("Please agree to privacy and terms of use to register successfully.", {
        position: "top-right",
        autoClose: 3000,
        type: 'error',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return;
    }
    
    setLoaded(true);
    let payload = {
      "email": email,
      "password": password,
      "password_confirm": cpassword
    }

    dispatch(register(payload, (result) => {
      setLoaded(false);
      if (result.error) {
        toast(result.error, {
          position: "top-right",
          autoClose: 10000,
          type: 'error',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

      // return;
      } else if(!result.error) {
        toast("Thank you. Please click the verification link sent to your email address.", {
          position: "top-right",
          autoClose: 3000,
          type: 'success',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        props.history.push('/oauth/sign-in/');
      }

    }));

  };

  return (
    <section className="login_outer regis_bg signup_outer">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 offset-lg-3 offset-md-0">
            <Form onSubmit={(event) => handleFormSubmit(event)} autoComplete="off">
              <div className="login_inner4">
                <h4>Register with<span className="etnat"> Eterna</span><span className="view_outre">view</span></h4>
				<div className="input_outer3">
                <div className="input_outer99">
                  <input type="email" name="email" required placeholder="Email" value={email} onChange={handleEmailInput} />
                  <p className="errMsg">{emailError}</p>
                </div>

                <div className="input_outer99">
                  {inputtype == false &&
                    <input type="password" name="password" placeholder="Password" required value={password} onChange={handlePasswordInput} />
                  }
                  {inputtype == true &&
                    <input type="text" name="password" placeholder="Password" required value={password} onChange={handlePasswordInput} />
                  }

                  {inputtype == false && <img src={edit} onClick={showPassword} />}
                  {inputtype == true && <i className="fa fa-eye-slash" aria-hidden="true" onClick={showPassword}></i>}
                  <p className="errMsg">{passwordError}</p>
                </div>

                <div className="input_outer99">
                  {inputtype1 == false &&
                    <input type="password" name="cpassword" placeholder="Confirm Password" required value={cpassword} onChange={handleConfirmPasswordInput} />
                  }
                  {inputtype1 == true &&
                    <input type="text" name="cpassword" placeholder="Confirm Password" required value={cpassword} onChange={handleConfirmPasswordInput} />
                  }

                  {inputtype1 == false && <img src={edit} onClick={showConfirmPassword} />}
                  {inputtype1 == true && <i className="fa fa-eye-slash" aria-hidden="true" onClick={showConfirmPassword}></i>}
                  <p className="errMsg">{cpasswordError}</p>
                </div>

                <div className="user45">
                  <p>
                    <label className="container98 new_user5">I agree to Eternaview’s <Link to="/home/privacy-policy/">Privacy Policy</Link> and  <Link to="/home/terms-of-use/">Terms of Use</Link>
                      <input type="checkbox" value={termsConditions} onChange={handleTermsConditions} />
                      <span className="checkmark"></span>
                    </label>

                    <label className="container98 new_user5">Yes, please keep me updated on Eternaview News, Events and Offers
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </p>
                </div>

                <div className="log_outer">
                  {loaded == false && <button type="submit">Register</button>}
                  {loaded == true && <button type="button">Registering...</button>}
                </div>

                <div className="checkbox custom_check">
                  {/* <label className="container98">Remember me
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label> 
                <Link to="/auth/reset-password">Forgot password?</Link>*/}
                </div>

                <p className="new_user5">Already have an account? <Link to="/oauth/sign-in/"> Log in</Link></p>
              </div>
			  </div>
            </Form>
          </div>
        </div>
      </div>
    </section>)
};

export default SignUp;
