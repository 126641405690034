import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { getPrivacyPolicy } from '../../redux/actions';

function PrivacyPolicy(props) {
    const dispatch = useDispatch();
    let [data, setData] = useState('')

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        dispatch(getPrivacyPolicy('', (result) => {
            setData(result.content);
        }));
    }

    return (
        <section class="terms-outer" dangerouslySetInnerHTML={{ __html: data }}>
            {/* <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="terms_inner">
                            <div class="terms_title">
                                Privacy Policy
                            </div>
                            <div class="terms_content">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vulputate id tortor vitae imperdiet. Nulla a erat odio. Curabitur eget tellus semper, mollis est in, accumsan erat. Nulla consequat et sapien vitae elementum. Maecenas rutrum ac quam finibus bibendum. Vivamus erat tortor, ultrices in aliquet non, placerat quis libero. Phasellus consequat, quam et convallis tincidunt, turpis elit dictum augue, sed bibendum ante diam non elit. Cras nec est arcu. Integer sit amet justo tristique, pharetra ex at, vulputate purus.</p>

                                <p><b>Quisque enim felis</b></p>
                                <p>porta in sodales et, blandit non leo. Vestibulum erat orci, dictum quis cursus ac, consectetur sit amet purus. Sed mauris odio, egestas sit amet orci et, ullamcorper.</p>
                                <ul class="terms_ul">
                                    <li><span></span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li><span></span>Phasellus vulputate id tortor vitae imperdiet. Nulla a erat odio.</li>
                                    <li><span></span>Curabitur eget tellus semper, mollis est in, accumsan erat.</li>
                                    <li><span></span>Nulla consequat et sapien vitae elementum. Maecenas rutrum ac quam </li>
                                    <li><span></span>finibus bibendum. Vivamus erat tortor, ultrices in aliquet non</li>
                                </ul>
                                <p><b>Quisque enim felis</b></p>
                                <p>porta in sodales et, blandit non leo. Vestibulum erat orci, dictum quis cursus ac, consectetur sit amet purus. Sed mauris odio, egestas sit amet orci et, ullamcorper.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vulputate id tortor vitae imperdiet. Nulla a erat odio. Curabitur eget tellus semper, mollis est in, accumsan erat. Nulla consequat et sapien vitae elementum. Maecenas rutrum ac quam finibus bibendum. Vivamus erat tortor, ultrices in aliquet non, placerat quis libero. Phasellus consequat, quam et convallis tincidunt, turpis elit dictum augue, sed bibendum ante diam non elit. Cras nec est arcu. Integer sit amet justo tristique, pharetra ex at, vulputate purus.</p>
                                <p><b>Quisque enim felis</b></p>
                                <p>porta in sodales et, blandit non leo. Vestibulum erat orci, dictum quis cursus ac, consectetur sit amet purus. Sed mauris odio, egestas sit amet orci et, ullamcorper.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="terms_inner">
                            <div class="terms_title">Privacy Policy</div>

                            <div class="terms_content">
                                <p>Protecting your private information is our priority. This Statement of Privacy applies to eternaview.com and Eternaview LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Eternaview LLC include eternaview.com and Eternaview. The Eternaview application is a video and/or audio interview application. By using the Eternaview application, you consent to the data practices described in this statement.</p>

                                <p><b>Collection of your Personal Information</b></p>

                                <p>In order to better provide you with products and services offered, Eternaview may collect personally identifiable information including, but not limited to, your:</p>

                                <ul class="terms_ul">
                                    <li>First and Last Name</li>
                                    <li>E-mail Address</li>
                                    <li>Phone Number</li>
                                    <li>Address</li>
                                    <li>Birthdate</li>
                                    <li>Biographical information, including information that you enter in describing any video interview you create on the platform</li>
                                </ul>

                                <p>If you purchase Eternaview&#39;s products and services, we collect billing, credit card, and other payment information. This information is used to complete the purchase transaction.</p>

                                <p>Eternaview is a personal interviewing product designed to allow creators of such interviews to describe themselves in detail to potential viewers. As such, depending on which questions the user chooses to answer, there may be additional questions about age, gender, beliefs, religion, political affiliation, occupation, and a range of other personal data.</p>

                                <p>Eternaview will be collecting and storing video, audio, and/or textual data from both creators and viewers of content on our website as they create and/or interact with content on our website. Eternaview will also be collecting usage data of users of the application in terms of, including but not limited to, use of buttons, controls, ads, queries and other behavior both within and related to (e.g. posting on social media) the application.</p>

                                <p>Eternaview reserves all rights to monitor, restrict or edit all recordings and other video, audio, and textural data put on or through our site for conformity to our standards for hate speech, violence, nudity, etc. We have not at this time fully devised or outlined those standards (beyond some basic limitations outlined in our Terms of Use) and reserve the right to determine on an individual basis what we believe is acceptable to us for inclusion on the website or for continued access to the site for any user.</p>

                                <p>In order to enhance the quality of offerings, we also reserve the right to view, listen, read and otherwise observe the video, audio and/or textual content created by viewers on our website, except in those cases where we may give the creator the ability to opt-out and they have elected that option.</p>

                                <p>Viewers&rsquo; of content created on our site should assume that the creator of such content knows that the viewer has in fact viewed/listened to it. We provide in the Privacy Settings in the Profile section of our website the option for users to opt-out of allowing other creators to know when you have viewed/listened to their Eternaview. And we will endeavor to make such viewership anonymous in cases where the user has made that opt-out election. However, because of the technological complexities of the invite and link sharing process on our website, along with the fact that Eternaview is a new application in beta, there may be times when that anonymity is not actually maintained.</p>

                                <p><b>Use of your Personal Information</b></p>

                                <p>From time to time, Eternaview may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Eternaview or click on a link therein. Eternaview may also use your personally identifiable information to inform you of other products or services available from Eternaview and its affiliates.</p>

                                <p>If you would like to stop receiving marketing or promotional communications via email from Eternaview, you may opt-out of receiving promotional or marketing emails by logging into the site and opting out on the Privacy Settings page on the Profile tab of our website at: https://eternaview.com/home/profile/.</p>

                                <p><b>Sharing Information with Third Parties</b></p>

                                <p>We do not currently sell, rent or lease user information to third parties. We reserve the right to sell or share anonymized-only data in the future for those users who have either opted-in or not opted-out to sharing that data.</p>

                                <p>In all cases, regardless of what you have opted-in to or opted-out of, Eternaview may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide technical or other customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Eternaview, and they are required to maintain the confidentiality of your information.</p>

                                <p>Eternaview may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Eternaview or the site; (b) protect and defend the rights or property of Eternaview; and/or (c) act under exigent circumstances to protect the personal safety of users of Eternaview, or the public.</p>

                                <p><b>Opt-Out of Sale or Disclosure of Personal Information to Third Parties</b></p>

                                <p>You have the right under the California Consumer Privacy Act of 2018 (CCPA) and certain other privacy and data protection laws, as applicable, to opt-out of the sale or disclosure of your personal information. If you exercise your right to opt-out of the sale or disclosure of your personal information, we will refrain from selling or disclosing your personal information, unless you subsequently provide express authorization for the sale or disclosure of your personal information or in those cases where we need to share your personal information in order to properly supply our services to you.. To opt-out of the sale or disclosure of your personal information, visit the Privacy Settings page on the Profile section of our website https://eternaview.com/home/profile/.</p>

                                <p><b>Right to Deletion</b></p>

                                <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you to support@eternaview.com, we will:</p>

                                <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:</p>

                                <ul class="terms_ul">
                                    <li>Delete your personal information from our records; and</li>
                                    <li>Direct any service providers to delete your personal information from their records.</li>
                                    <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;</li>
                                    <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;</li>
                                    <li>Debug to identify and repair errors that impair existing intended functionality;</li>
                                    <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</li>
                                    <li>Comply with the California Electronic Communications Privacy Act;</li>
                                    <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;</li>
                                    <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</li>
                                    <li>Comply with an existing legal obligation; or</li>
                                    <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
                                </ul>

                                <p>We may also not, for technological or resource-related reasons, be able to fulfill such requests while we are in beta release.</p>

                                <p>We reserve the right, solely if we choose to, to store any deleted or deactivated or canceled or expired Eternaview in case the deletion/deactivation/canceling/expiration occurred due to some error, regardless of whether the error was made by eternaview.com or you, or to maintain the possibility that you may renew/turn back on that same Eternaview recording in the future. We may also retain the data for reasons including, but not limited to, research, financial, legal, security, and technical complexity.</p>

                                <p>Any user that would like to make sure their data is permanently deleted should contact us at <a href="">support@eternaview.com</a> and will accommodate, to the degree we are able, such requests that we consider practical, feasible, and reasonable.</p>

                                <p><b>Children Under Thirteen</b></p>

                                <p>Eternaview does not purposefully seek out or collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this application.</p>

                                <p><b>External Data Storage Sites</b></p>

                                <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>

                                <p><b>Changes to this Statement</b></p>

                                <p>Eternaview reserves the right to change this Privacy Policy from time to time. While we may notify you of any changes by sending a notice to the primary email address specified in your account or by placing a prominent notice on our application, we make no commitment to do so and you are encouraged to check our website for the most recent Privacy Policy. Your continued use of the application and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>

                                <p><b>Risks of Beta Version</b></p>

                                <p>User recognizes that the website being launched in early 2022 is a beta version and, by its nature, may have additional bugs or lack of features than might be expected from a non-beta version of this or other websites. User accepts the extra risk related to using the beta version.</p>

                                <p>In both the beta version and later version, all Eternaview services are provided &ldquo;as is&rdquo;, and with &ldquo;all faults&rdquo;, and Eternaview disclaims any warranties.</p>

                                <p><b>Contact Information</b></p>

                                <p>Eternaview welcomes your questions or comments regarding this Statement of Privacy. If you believe that Eternaview has not adhered to this Statement, please contact Eternaview at: support@eternaview.com</p>

                                <p>Effective as of February 10, 2022</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </section>
    )
};

export default PrivacyPolicy;
