import React, { Component } from 'react';
import vector_audio from "../../../Images/vector_audio.jpg";


class InteractAudio extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({ recording: this.props.recording, orgUrl: this.props.orgUrl, no_transcoding: this.props.no_transcoding, videoUrl: this.props.videoUrl, videoType: this.props.videoType });
    }

    myCallback = () => {
        console.log('Audio has ended');
        this.setState({ recording: false });
   
        this.props.setInteractResponse(true);
    };

    render() {

        return (
            <>
                {(this.props.recording == false && this.props.orgUrl == '') && <img className="audio-img-vector" src={vector_audio} />}
                {(this.props.recording == false && this.props.orgUrl != '') && <div><img className="audio-img-vector" src={vector_audio} /><audio src={this.props.orgUrl} autoPlay playsInline loop /></div>}

                {(this.props.recording == true) && <div><img className="audio-img-vector" src={vector_audio} /><audio src={this.props.videoUrl} autoPlay onEnded={() => this.myCallback()} /></div>}
            </>
        );
    }
}

export default InteractAudio;