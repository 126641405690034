import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import plan from "../../Images/plan.png";
import recording from "../../Images/recording.png";
import guid from "../../Images/guid.png";
import { getFaq } from '../../redux/actions';

function Faq(props) {
    const dispatch = useDispatch();
    let [data, setData] = useState('')

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        dispatch(getFaq('', (result) => {
            setData(result.questions);
        }));
    }

    return (
        <section className="blog_outer faq_outer custom_faq6">
            <div className="container">
                <div className="plan_outer2">
                    <h4>Our FAQ</h4>
                    <p>If you do not see your question answered below, please email us at support@eternaview.com</p>
                    <div className="row spec45">
                        <div className="col-lg-4 col-md-12">
                            <div className="white459">
                                <img src={plan} />
                                <p>Pricing & Plans</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="white459">
                            {/* className="white459 active4" */}
                                <img src={recording} />
                                <p>Eternaview Recording</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="white459">
                                <img src={guid} />
                                <p>Usage Guide</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordingh6">
                        <div className="custtom_according">
                            <div className="bs-example">
                                <div className="accordion" id="accordionExample">
                                    {data && data.map((content, index) => (
                                        <div className="card">
                                            <div className="card-header" id={"heading" + index}>
                                                <h2 className="mb-0">
                                                    <button type="button" className="btn btn-link" data-toggle="collapse" data-target={"#collapse" + index}><i className="fa fa-circle"></i>{content.question}<i className="fas fa-plus" aria-hidden="true"></i> </button>
                                                </h2>
                                            </div>
                                            <div id={"collapse" + index} className="collapse" aria-labelledby={"heading" + index} data-parent="#accordionExample">
                                                <div className="card-body">
                                                    <p>{content.answer}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {/* <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button type="button" className="btn btn-link" data-toggle="collapse" data-target="#collapseTwo"><i className="fa fa-circle"></i>Dummy lorem ipsum dolor text?<i className="fas fa-plus" aria-hidden="true"></i> </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>Maecenas quis laoreet felis. Nam a sapien ac enim venenatis tempor. Sed nisi magna, malesuada a ultrices ut, tristique quis
                                                    diam. Suspendisse consectetur quis eros a mattis. Ut eleifend nisi risus, sed bibendum ipsum rutrum iaculis. Curabitur fringilla
                                                    venenatis lacinia. Vivamus rutrum dictum quam sed venenatis. Nulla tincidunt purus ligula, lacinia tristique purus sollicitudin
                                                    id. Nunc finibus pulvinar fermentum.
                                                </p>
                                                <p>Nunc luctus sem velit, et tincidunt ante placerat a. Morbi in mauris nulla. Praesent consectetur augue odio, quis euismod odio
                                                    egestas ut. Pellentesque maximus felis vel lorem aliquam pharetra. Nam vel mattis nulla. Ut vitae odio quis ex tincidunt
                                                    feugiat. Quisque iaculis sit amet lacus non egestas. Curabitur suscipit cursus nunc, non dignissim sem efficitur a. Donec eu
                                                    mollis turpis, nec tempus nulla. Nunc cursus nisl eu tortor interdum placerat. Aliquam convallis diam et ipsum varius porttitor.
                                                    Sed interdum blandit dolor, sed venenatis eros. Etiam nunc sapien, placerat in elit at, egestas posuere magna. Aenean
                                                    imperdiet fermentum augue.
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button type="button" className="btn btn-link" data-toggle="collapse" data-target="#collapseThree"><i className="fa fa-circle"></i>Dummy lorem ipsum dolor text?<i className="fas fa-plus" aria-hidden="true"></i> </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>Maecenas quis laoreet felis. Nam a sapien ac enim venenatis tempor. Sed nisi magna, malesuada a ultrices ut, tristique quis
                                                    diam. Suspendisse consectetur quis eros a mattis. Ut eleifend nisi risus, sed bibendum ipsum rutrum iaculis. Curabitur fringilla
                                                    venenatis lacinia. Vivamus rutrum dictum quam sed venenatis. Nulla tincidunt purus ligula, lacinia tristique purus sollicitudin
                                                    id. Nunc finibus pulvinar fermentum.
                                                </p>
                                                <p>Nunc luctus sem velit, et tincidunt ante placerat a. Morbi in mauris nulla. Praesent consectetur augue odio, quis euismod odio
                                                    egestas ut. Pellentesque maximus felis vel lorem aliquam pharetra. Nam vel mattis nulla. Ut vitae odio quis ex tincidunt
                                                    feugiat. Quisque iaculis sit amet lacus non egestas. Curabitur suscipit cursus nunc, non dignissim sem efficitur a. Donec eu
                                                    mollis turpis, nec tempus nulla. Nunc cursus nisl eu tortor interdum placerat. Aliquam convallis diam et ipsum varius porttitor.
                                                    Sed interdum blandit dolor, sed venenatis eros. Etiam nunc sapien, placerat in elit at, egestas posuere magna. Aenean
                                                    imperdiet fermentum augue.
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="card">
                                        <div className="card-header" id="headingfour">
                                            <h2 className="mb-0">
                                                <button type="button" className="btn btn-link" data-toggle="collapse" data-target="#collapseFour"><i className="fa fa-circle"></i>Dummy lorem ipsum dolor text?<i className="fas fa-plus" aria-hidden="true"></i> </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingfour" data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>Maecenas quis laoreet felis. Nam a sapien ac enim venenatis tempor. Sed nisi magna, malesuada a ultrices ut, tristique quis
                                                    diam. Suspendisse consectetur quis eros a mattis. Ut eleifend nisi risus, sed bibendum ipsum rutrum iaculis. Curabitur fringilla
                                                    venenatis lacinia. Vivamus rutrum dictum quam sed venenatis. Nulla tincidunt purus ligula, lacinia tristique purus sollicitudin
                                                    id. Nunc finibus pulvinar fermentum.
                                                </p>
                                                <p>Nunc luctus sem velit, et tincidunt ante placerat a. Morbi in mauris nulla. Praesent consectetur augue odio, quis euismod odio
                                                    egestas ut. Pellentesque maximus felis vel lorem aliquam pharetra. Nam vel mattis nulla. Ut vitae odio quis ex tincidunt
                                                    feugiat. Quisque iaculis sit amet lacus non egestas. Curabitur suscipit cursus nunc, non dignissim sem efficitur a. Donec eu
                                                    mollis turpis, nec tempus nulla. Nunc cursus nisl eu tortor interdum placerat. Aliquam convallis diam et ipsum varius porttitor.
                                                    Sed interdum blandit dolor, sed venenatis eros. Etiam nunc sapien, placerat in elit at, egestas posuere magna. Aenean
                                                    imperdiet fermentum augue.
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="card">
                                        <div className="card-header" id="headingfive">
                                            <h2 className="mb-0">
                                                <button type="button" className="btn btn-link" data-toggle="collapse" data-target="#collapseFive"><i className="fa fa-circle"></i>Dummy lorem ipsum dolor text?<i className="fas fa-plus" aria-hidden="true"></i> </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>Maecenas quis laoreet felis. Nam a sapien ac enim venenatis tempor. Sed nisi magna, malesuada a ultrices ut, tristique quis
                                                    diam. Suspendisse consectetur quis eros a mattis. Ut eleifend nisi risus, sed bibendum ipsum rutrum iaculis. Curabitur fringilla
                                                    venenatis lacinia. Vivamus rutrum dictum quam sed venenatis. Nulla tincidunt purus ligula, lacinia tristique purus sollicitudin
                                                    id. Nunc finibus pulvinar fermentum.
                                                </p>
                                                <p>Nunc luctus sem velit, et tincidunt ante placerat a. Morbi in mauris nulla. Praesent consectetur augue odio, quis euismod odio
                                                    egestas ut. Pellentesque maximus felis vel lorem aliquam pharetra. Nam vel mattis nulla. Ut vitae odio quis ex tincidunt
                                                    feugiat. Quisque iaculis sit amet lacus non egestas. Curabitur suscipit cursus nunc, non dignissim sem efficitur a. Donec eu
                                                    mollis turpis, nec tempus nulla. Nunc cursus nisl eu tortor interdum placerat. Aliquam convallis diam et ipsum varius porttitor.
                                                    Sed interdum blandit dolor, sed venenatis eros. Etiam nunc sapien, placerat in elit at, egestas posuere magna. Aenean
                                                    imperdiet fermentum augue.
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Faq;
