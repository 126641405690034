import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEternaVideos,downloadFile } from '../../../redux/actions';
import { toast } from 'react-toastify';
import JsFileDownloader from 'js-file-downloader';

class DownloadEterna extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eternaId : props.id,
            token:props.token
        };
    }

   
   downloadEterna()
    {
       this.props.getEternaVideos(this.state.eternaId,this.state.token, (res) => {
        if (res.answers && res.answers.length>0) {
            var zip=new JSZip();

          
            try
            {
                toast("Eternaview download started, You can continue while we download your Eternaview", {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'warning',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false, 
                    progress: undefined,
                });

                const remoteZips =  res.answers.map(async (pack) => {

                // pack is the URL for the auidio or video hosted on the server
               const response = await fetch(pack.media_file,{
                method: 'GET',
                headers: new Headers({'Accept': '*/*'}),
            });
                const data = await response.blob();
                var type=res.type=='audio'?'.mp3':'.mp4';
                var filename=pack.question.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'_')+type;
                console.log("file name is",filename);
                zip.file(filename, data);
                return data;
              })
              Promise.all(remoteZips).then(() => {
                zip.generateAsync({ type: "blob" }).then((content) => {
                  saveAs(content, `EternaView-`+this.state.eternaId+".zip");
                })
                toast("Eternaview downloaded successfully.", {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
              })
            }
            catch(ex)
            {
                toast('Unable to download, Please try after some time.', {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
            
            

        }
        else
        {
            toast('Unable to donwload, Complete the Eternaview before download.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }

    });
    }
 render()
 {
    return(
        <>
          <i class="fa fa-download" style={{cursor:'pointer'}} onClick={()=>this.downloadEterna()}></i>
        </>
    )
 }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
    getEternaVideos: bindActionCreators(getEternaVideos, dispatch),
    downloadFile: bindActionCreators(downloadFile, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(DownloadEterna);