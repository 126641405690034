import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { forgotPassword, resetPassword } from '../../redux/actions';
import edit from "../../Images/edit.png";


function ResetPassword(props) {
  const auth = useSelector(state => state);
  const paramvalue = useParams();
  const dispatch = useDispatch();

  let [email, setEmail] = useState('')
  let [password, setPassword] = useState('')
  let [passwordError, setPasswordError] = useState('')
  let [cpassword, setConfirmPassword] = useState('')
  let [cpasswordError, setConfirmPasswordError] = useState('')
  let [inputtype, setInputType] = useState(false);
  let [inputtype1, setInput1Type] = useState(false);
  let [userid, setUserId] = useState();
  let [loaded, setLoaded] = useState(false);
 

  useEffect(() => {
    setUserId(paramvalue.id);
    setInputType(false);
  }, []);

  function showPassword() {
    setInputType(!inputtype);
  };

  function showConfirmPassword() {
    setInput1Type(!inputtype1);
  };

  function handleEmailInput(event) {
    setEmail(event.target.value)
  };

  function handlePasswordInput(event) {
    setPassword(event.target.value);
    setPasswordError(validate('password', event.target.value));
  };

  function handleConfirmPasswordInput(event) {
    setConfirmPassword(event.target.value);
    setConfirmPasswordError(validate('cpassword', event.target.value));
  };

  function validate(name, value) {
    switch (name) {
      case "email":
        if (!value || value.trim() === "") {
          return "Email is required.";
        } else {
          return "";
        }
      case "password":
        if (!value || value.trim() === "") {
          return "Password is required.";
        } else {
          var reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
          var test = reg.test(value);
          if (test) {
            return "";
          } else {
            return "Please enter password within range of 8-15 characters including atleast a number and a special character.";
          }

          return "";
        }
      case "cpassword":
        if (!value || value.trim() === "") {
          return "Confirm Password is required.";
        } else {
          return "";
        }
      default: {
        return "";
      }
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    setLoaded(!loaded);
    let payload = {
      "password": password,
      "password_confirm": cpassword
    }

    dispatch(resetPassword(payload, userid, (result) => {
      setLoaded(!loaded);
      if (result.error) {
        toast(result.error_description, {
          position: "top-right",
          autoClose: 10000,
          type: 'error',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

      } else if (result.msg) {
        toast('Please enter your new password to login into the application.', {
          position: "top-right",
          autoClose: 3000,
          type: 'success',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

        props.history.push('/oauth/sign-in/');
      }
    }));

  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 offset-lg-3">
            <div className="login_inner4 reset_outer4">
              <div className="text-center mt-4">
                <h1 className="h2">Reset password</h1>
                <p className="lead">Enter your new password to reset your password.</p>
              </div>
              <Form onSubmit={(event) => handleFormSubmit(event)} autoComplete="off">
                <FormGroup>
                  <Label>Password</Label>
                  <div className="input_outer99">
                    {inputtype == false &&
                      <Input
                        bsSize="lg"
                        type="password"
                        name="password"
                        placeholder="Enter your new password" value={password} onChange={handlePasswordInput} required
                      />}

                    {inputtype == true &&
                      <Input
                        bsSize="lg"
                        type="text"
                        name="password"
                        placeholder="Enter your new password" value={password} onChange={handlePasswordInput} required
                      />}

                    {inputtype == false && <img src={edit} onClick={showPassword} />}
                    {inputtype == true && <i className="fa fa-eye-slash" aria-hidden="true" onClick={showPassword}></i>}
                    <p className="errMsg">{passwordError}</p>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <div className="input_outer99">
                    {inputtype1 == false &&
                      <Input
                        bsSize="lg"
                        type="password"
                        name="cpassword"
                        placeholder="Enter your new password" value={cpassword} onChange={handleConfirmPasswordInput} required
                      />}

                    {inputtype1 == true &&
                      <Input
                        bsSize="lg"
                        type="text"
                        name="cpassword"
                        placeholder="Enter your new password" value={cpassword} onChange={handleConfirmPasswordInput} required
                      />}

                    {inputtype1 == false && <img src={edit} onClick={showConfirmPassword} />}
                    {inputtype1 == true && <i className="fa fa-eye-slash" aria-hidden="true" onClick={showConfirmPassword}></i>}
                    <p className="errMsg">{cpasswordError}</p>
                  </div>
                </FormGroup>
                <div className="log_outer">
                  {loaded == false && <Button type="submit">Reset password</Button>}
                  {loaded == true && <Button type="button">Resetting...</Button>}
                </div>

              </Form>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>);
};

export default ResetPassword;
