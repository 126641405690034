import React, { Component } from 'react';
import videojs from "video.js";
import "video.js/dist/video-js.css";
import vector_audio from "../../../Images/vector_audio.jpg";

const videoJsOptions = {
    controls: false,
    bigPlayButton: false,
    autoplay: true,
    responsive: true,
    fluid: true,
    aspectRatio: '16:9',
    width: "auto",
    height: "auto"
};
const videoJsOptions1 = {
    controls: false,
    bigPlayButton: false,
    autoplay: true,
    responsive: true,
    fluid: true,
    aspectRatio: '16:9',
    width: "auto",
    height: "auto"
}

class InteractVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoUrl : ''
        };
    }

    componentDidMount() {
        this.player = videojs(this.videoNode, videoJsOptions, function onPlayerReady() {
            console.log('onPlayerReady', this)
        });

        this.player.on('deviceReady', () => {
            console.log('device is ready!');
        });

        this.newPlayer = videojs(this.newVideo, videoJsOptions1, function onPlayerReady() {
            console.log('onPlayerReady', this)
        });

        this.newPlayer.on('deviceReady', () => {
            console.log('device is ready!');
        });

        this.player.src({
            src: this.props.orgUrl,
            type: this.props.videoType
        });

        this.player.play();

        
    }

    myCallback = () => {
        console.log('Video has ended');
        this.setState({ recording: false });

        this.props.setInteractResponse(true);
    };

    componentDidUpdate() {
        console.log(this.props);

        if(this.props.recording == true && this.newPlayer.src() != this.props.videoUrl) {
            this.newPlayer.src({
                src: this.props.videoUrl,
                type: this.props.videoType
            });
            
        }
        
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
        if (this.newPlayer) {
            this.newPlayer.dispose();
        }
    }

    render() {

        return (
            <>
                {(this.props.recording == false && this.props.orgUrl == '') && <img className="interact-img" src={vector_audio} />}

                <div className={(this.props.recording == false && this.props.orgUrl != '') ? "" : "congratulation-video-hide"}>
                    <div data-vjs-player>
                        <video id="myVideo" ref={node => this.videoNode = node} className="video-js vjs-default-skin custom_video_js" options={videoJsOptions} playsInline loop></video>
                    </div>
                </div>

                <div className={(this.props.recording == true && this.props.no_transcoding == false) ? "" : "congratulation-video-hide"}>
                    <div data-vjs-player>
                        <video id="secondVideo" ref={node => this.newVideo = node} className="video-js vjs-default-skin custom_video_js" options={videoJsOptions1} playsInline onEnded={() => this.myCallback()}></video>
                    </div>
                </div>
            </>
        );
    }
}

export default InteractVideo;