import React, { Component } from "react";
import { connect } from 'react-redux';
import { completeCongratulationForm } from "../../redux/actions";
import { toast } from 'react-toastify';
import hand_outer from "../../Images/hand_outer.png";
import { bindActionCreators } from "redux";
import HeaderComponent from "../../components/Header";

class CongratulationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permissionType: '1',
            posterity: 'no',
            title: '',
            username: '',
            email: '',
            category: '',
            subcategory: '',
            type: 'video',
            formValues: [{ name: "", email: "" }],
            showPoster: false,
            btnSubmit: false,
            plan_id : '',
            viewId : ''
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
        let split = (this.props.match.params.id).split("+");

        if(split[2] != 'undefined' || split[2] != undefined) {
            this.setState({type : split[2]});
        }
        this.setState({ category: split[0], subcategory: split[1], plan_id : split[3], viewId : split[4]});
    }

    onTypeSelected = (e) => {
        this.setState({ permissionType: e.target.value });
    }

    onPosteritySelected = (e) => {
        console.log(e.target.value);
        if(e.target.value == 'yes') {
            this.setState({showPoster : true});
        } else if(e.target.value == 'no') {
            this.setState({showPoster : false});
        }
        this.setState({ posterity: e.target.value });
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    addFormFields = () => {
        this.setState(prevState => ({ formValues: [...prevState.formValues, { name: "", email: "" }] }));
    }

    handleChange = (i, e) => {
        let newFormValues = [...this.state.formValues];
        newFormValues[i][e.target.name] = e.target.value;
        this.setState({ formValues: newFormValues });
    }

    submitForm = (e) => {
        e.preventDefault();

        let invite_array = [];
        this.state.formValues.map((element, index) => {
            if (element.email != "") {
                invite_array.push(element);
            }
        });

        if(this.state.showPoster == true) {
            if(this.state.username == '' || this.state.email == '') {
                toast("Please enter the username and email under posterity field", {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                return;
            }
        }

        let token = this.props.auth.loginUserToken;
        this.setState({ btnSubmit : true });

        let payload = {
            "category": this.state.category,
            "sub_category": this.state.subcategory,
            "type": this.state.type,
            "name": this.state.username,
            "email": this.state.email,
            "permission": this.state.permissionType == '0' ? "public" : "private",
            "title": this.state.title,
            "show": invite_array,
            "subscribed_plan": this.state.plan_id,
            "view_id": this.state.viewId
        }

        this.props.completeCongratulationForm(payload, token, (res) => {
            this.setState({ btnSubmit : false });
            if (res.msg) {
                toast(res.msg, {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                this.props.history.push('/home/myeternaviews/');

            } else if (res.error) {
                toast(res.error, {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }

        });
    };

    render() {
        const { permissionType, formValues, posterity, btnSubmit } = this.state;

        return (
            <div>
               <HeaderComponent value={100} props={this.props} />
            <section className="congration_outer create24">
                <div className="container">
                    <form>
                        <div className="shaw_outer453 congrtx_shaw_div">
                            <h3>Congratulations on completing your Eternaview!</h3>
                            <div className="top-outr54">
                                <span>- Give a title for your Eternaview  </span><input type="text" name="title" value={this.state.title} onChange={this.onInputchange} required />
                                <p>- Now it's time to share your Eternaview!  Please enter here the names (optional) and email addresses of the people you would like to authorize to interact with your Eternaview and send them an email invite.</p>
                            </div>

                            {formValues.map((element, index) => (
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="custom786 lef59">
                                            <p className="left56">Optional</p>
                                            <div className="top-outr54"><span>Name </span><input type="text" name="name" value={element.name || ""} onChange={e => this.handleChange(index, e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="custom786 lef59 mar56">
                                            <div className="top-outr54"><span> Email Adddress </span><input type="text" name="email" value={element.email || ""} onChange={e => this.handleChange(index, e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className="anoth4">
                                <p><button type="button" onClick={() => this.addFormFields()}>Add Another +</button></p>
                            </div>

                            <p>- From your ‘My Eternaview’ page, you will be able to share a link or invite additional people to interact with your Eternaview.</p>
                            <p>- You also have the option to share your Eternaview publicly with anyone who comes to our website. It is your responsibility to determine whether the information is too personal to share widely or whether viewers might misuse the information in any way.
                            </p>

                            <div className="checkouter56">
                                <label className="container9">Yes, please make my Eternaview public
                                    <input type="radio" name="radio" value="0" checked={permissionType == '0'} onChange={this.onTypeSelected} />
                                    <span className="checkmark"></span>
                                </label>
                                <label className="container9">No Thank You
                                    <input type="radio" name="radio" value="1" checked={permissionType == '1'} onChange={this.onTypeSelected} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>

                            <p>- For Legacy Eternaviews only, we recommend that you provide the Name and email address of a close relative or friend, ideally much younger, who you would like to give permission to take control of your Eternaview in the future when you pass away (hopefully far into the future).
                            </p>
                            <div className="checkouter56">
                                <label className="container9">Yes, Please
                                    <input type="radio" value="yes" checked={posterity == 'yes'} onChange={this.onPosteritySelected} />
                                    <span className="checkmark"></span>
                                </label>
                                <label className="container9">No Thanks
                                    <input type="radio" value="no" checked={posterity == 'no'} onChange={this.onPosteritySelected} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>

                            {this.state.showPoster == true && <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="custom786 lef59">
                                        <div className="top-outr54"><span>Name </span><input type="text" name="username" value={this.state.username} onChange={this.onInputchange} required /></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="custom786 lef59">
                                        <div className="top-outr54"><span>Email Adddress </span><input type="email" name="email" value={this.state.email} onChange={this.onInputchange} required /></div>
                                    </div>
                                </div>
                            </div>}

                            <div className="submit_outer45">
                                {!btnSubmit && <button className="btn" type="submit" onClick={this.submitForm}>Submit</button>}
                                {btnSubmit && <button className="btn" type="button">Submitting...</button>}
                            </div>
                            {/* <div className="bottom_tex4 hand_img_div">
                                <a href="#" data-toggle="modal" data-target="#myModal12"><img src={hand_outer} /></a>
                            </div> */}
                        </div>
                    </form>
                </div>
            </section>
            </div>
        );
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
    completeCongratulationForm: bindActionCreators(completeCongratulationForm, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CongratulationForm);
