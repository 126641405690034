import React, { useState, useEffect } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cancelSubscription } from "../../redux/actions/index";

const Cancel = ({ location, props }) => {
  const dispatch = useDispatch();
  const [cancelled, setCancelled] = useState(false);
  const auth = useSelector(state => state);

  useEffect(() => {
    if (auth.auth.loginUserToken == "") {
      props.history.push('/');
    }

  }, []);

  const handleClick = async (e) => {
    e.preventDefault();

    // await fetch('http://eterna.itechnolabs.tech/plans/cancel/', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Bearer ' + auth.auth.loginUserToken
    //   },
    //   body: JSON.stringify({
    //     subscription_id: location.state.subscription
    //   }),
    // })

    dispatch(cancelSubscription({ subscription_id: location.state.subscription }, auth.auth.loginUserToken, (result) => {
      console.log(result);
      
    }));

    setCancelled(true);
  };

  if (cancelled) {
    return <Redirect to={`/home/account`} />
  }

  return (
    <section className="intract_outer_subscription text-center">
      <hr />
      <h4>Confirm to cancel Subscription?</h4>
      <button className="cancel_sub mr-2 bg-danger" onClick={handleClick}>Yes</button>
      <Link className="cancel_sub" to={{ pathname: '/home/account' }}>
        Go Back
      </Link>
      <hr />
    </section>
  )
}


export default withRouter(Cancel);