import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import thankyou_icon from "../../Images/thnku-icon.png";
import thankyou_text from "../../Images/thnku-text.png";

function Thankyou(props) {
    const dispatch = useDispatch();
    const paramvalue = useParams();
    const [paramUrl, setParamUrl] = useState('');
    const auth = useSelector(state => state);

    useEffect(() => {
        if (auth.auth.loginUserToken == "") {
            props.history.push('/');
        }

        setParamUrl(paramvalue.id);

    }, []);

    return (
        <section class="thankyou-outer">
            <div class="container inner-container">
                <div class="row">
                    <div class="col-md-8 offset-md-2 col-sm-12">
                        <div class="login-inner thankyou-outer">
                            <div class="thankyou-upper">
                                <img src={thankyou_icon} alt="" />
                            </div>
                            <div class="thankyou-bottom">
                                <img src={thankyou_text} alt="" />
                                <p class="p-text">Your payment has been received. You may now move on to recording.</p>
                                <Link to={`/create/eternaview/${paramUrl}`}><button class="login-btn-h signup-h login-button">Continue</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Thankyou;
