import React, { Component } from 'react';
import RecorderJS from 'recorder-js';
import { LexRuntimeV2Client, RecognizeTextCommand, RecognizeUtteranceCommand } from "@aws-sdk/client-lex-runtime-v2"; // ES Modules import
import { getAudioStream, exportBuffer } from '../utilities/audio';
import red_mick from "../Images/red_mick.png";

class Recorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stream: null,
      recording: 0,
      recorder: null
    };
    this.startRecord = this.startRecord.bind(this);
    this.stopRecord = this.stopRecord.bind(this);
  }

  async componentDidMount() {
    let stream;

    try {
      stream = await getAudioStream();
    } catch (error) {
      // Users browser doesn't support audio.
      // Add your handler here.
      console.log(error);
    }

    this.setState({ stream });
  }

  startRecord() {
    this.setState({ recording : 1});
    
    const { stream } = this.state;

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const recorder = new RecorderJS(audioContext);
    recorder.init(stream);

    this.setState(
      {
        recorder
      },
      () => {
        recorder.start();
      }
    );
  }

  async stopRecord() {
    const { recorder } = this.state;
    const { buffer } = await recorder.stop()
    const audio = exportBuffer(buffer[0]);
    const blobURL = URL.createObjectURL(audio)

    // Process the audio here.
    const command = new RecognizeUtteranceCommand({
      "botAliasId": "TSTALIASID",
      "botId": this.props.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57", // legacy - RMEHNYXF57 (Practice ones of legacy one), dating - QPSJB0MH2O
      "localeId": "en_US",
      "inputStream": audio,
      "requestContentType": "audio/x-l16; sample-rate=16000; channel-count=1",
      "sessionId": "043758711856891",
      "responseContentType": "audio/mpeg"
    });

    this.setState({
      recording: 2
    });

    const response = await this.props.client.send(command);

    this.setState({
      recording: 3
    });


    fetch('https://dict.shinedashboard.com/decoder.php', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(response),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        this.setState({
          recording: 0
        });
        this.props.setBotResponse(data)
      })
      .catch((error) => {
        console.error('Error:', error);
        this.props.setBotResponse(error)
      });

  }

  render() {
    const { recording, stream } = this.state;

    // Don't show record button if their browser doesn't support it.
    if (!stream) {
      return null;
    }

    return (
      <div>
        {recording == 0 && <div className="right_mike4" onClick={this.startRecord}>
          <img src={red_mick} />
          <span>Click To Talk</span>
        </div>}
        {recording == 1 && <div className="right_mike4 bg_hover" onClick={this.stopRecord}>
          <img src={red_mick} />
          <span>Click To Stop</span>
        </div>}
        {recording == 2 && <div className="right_mike4">
          <img src={red_mick} />
          <span>Interpreting...</span>
        </div>}
        {recording == 3 && <div className="right_mike4">
          <img src={red_mick} />
          <span>Fetching...</span>
        </div>}
      </div>
    );
  }
}

export default Recorder;