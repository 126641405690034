import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import le1 from "../../Images/le1.png";
import le2 from "../../Images/le2.png";
import le3 from "../../Images/le3.png";

class InteractFind extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <section className="doiect_outer crete29">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="indr_outer">
                                    <div className="plan_outer2">
                                        <h4>Instructions</h4>
                                        <p>Fusce ut mattis leo. Phasellus at arcu lacus. Nullam eu pharetra urna. Phasellus in tincidunt erat. Ut tristique rutrum elit ut consectetur. Duis euismod auctor mauris ac eleifend. Nam sit amet ante est.</p>
                                    </div>
                                    <div className="row spect679">
                                        <div className="col-lg-2 col-md-12">
                                            <div className="small_icon">
                                                <img src="assets/img/mik_cicel.png" />
                                            </div>
                                        </div>
                                        <div className="col-lg-10 col-md-12">
                                            <div className="quest_outer">
                                                <h4>To ask your question verbally</h4>
                                                <div className="told_outer">
                                                    <img src="assets/img/arrow_right.png" />
                                                    <p>Hold down anywhere within the black background. </p>
                                                </div>
                                                <div className="told_outer">
                                                    <img src="assets/img/arrow_right.png" />
                                                    <p>Wait for a flashing red dot and the word "listening" to appear.
                                                    </p>
                                                </div>
                                                <div className="told_outer">
                                                    <img src="assets/img/arrow_right.png" />
                                                    <p>Ask your question, and release when your question appears.
                                                    </p>
                                                </div>
                                                <div className="told_outer">
                                                    <img src="assets/img/arrow_right.png" />
                                                    <p>Interrupt a response to ask a new question by tapping or clicking within the black background.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row spect679  borde4">
                                        <div className="col-lg-2 col-md-12">
                                            <div className="small_icon">
                                                <img src="assets/img/mick2.png" />
                                            </div>
                                        </div>
                                        <div className="col-lg-10 col-md-12">
                                            <div className="quest_outer">
                                                <h4>To type your question</h4>
                                                <div className="told_outer">
                                                    <img src="assets/img/arrow_right.png" />
                                                    <p>Click anywhere within the black background.</p>
                                                </div>
                                                <div className="told_outer">
                                                    <img src="assets/img/arrow_right.png" />
                                                    <p>Press the space bar on your computer's keyboard.
                                                    </p>
                                                </div>
                                                <div className="told_outer">
                                                    <img src="assets/img/arrow_right.png" />
                                                    <p>Type in your question and press "Ask Question."
                                                    </p>
                                                </div>
                                                <div className="told_outer">
                                                    <img src="assets/img/arrow_right.png" />
                                                    <p>Interrupt a response to ask a new question by clicking within the black background.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="inte45">
                                    <div className="pip89">
                                        <img src="assets/img/intergation.png" />
                                    </div>
                                    <div className="full89"> <img src="assets/img/le2.png" /></div>
                                </div>
                                <div className="told_outer mick_outer put5">
                                    <img src="assets/img/red_mick.png" />
                                    <p>Hold the button Hold the button when you are ready or type your question.</p>
                                    <div className="input52">
                                        <input type="text" placeholder="Type Here..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dating_ques">
                    <div className="container">
                        <div className="plan_outer2">
                            <h4>Dating Questions Listing</h4>
                        </div>
                        <div className="row spect6">
                            <div className="col-lg-6 col-md-6">
                                <div className="box45">
                                    <img src="assets/img/box1.png" />
                                    <p>Dummy Lorem Ipsum dolor text?</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="box45">
                                    <img src="assets/img/box2.png" />
                                    <p>Dummy Lorem Ipsum dolor text?</p>
                                </div>
                            </div>
                        </div>
                        <div className="row spect6">
                            <div className="col-lg-6 col-md-6">
                                <div className="box45">
                                    <img src="assets/img/box3.png" />
                                    <p>Dummy Lorem Ipsum dolor text?</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="box45">
                                    <img src="assets/img/box4.png" />
                                    <p>Dummy Lorem Ipsum dolor text?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(InteractFind);
