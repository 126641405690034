import React, { useEffect, useState } from "react";

import {
    Collapse,
    Navbar,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";

import { Link, useLocation, useHistory  } from "react-router-dom";
import { useSelector } from "react-redux";
import { logOut, profile } from "../redux/actions";
import { useDispatch } from 'react-redux';
import { toastr } from "react-redux-toastr";
import icon_share from "../Images/icon_share.png";
import profile_pc from "../Images/user-default.jpeg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function HeaderComponent(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const historyy = useHistory();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    const auth = useSelector(state => state);
    const [user_state, setUserState] = React.useState("");
    const [profile_state, setProfileState] = React.useState(profile_pc);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
    //  console.log(auth);
      if (auth.auth.loginUserToken != '') {
       // console.log('tanisha');
        getProfile(auth.auth.loginUserToken);
      } else {
          if(props.props) {
            props.props.history.replace('/');
          }
        
      }
    }, [auth]);

    const getProfile = (token) => {
      dispatch(profile({ token: token }, (result) => {
        if (result.data) {
          if(result.data && result.data.first_name != "") {
            setUserState(result.data.first_name);
          }

          if(result.data && result.data.avatar != "") {
            setProfileState(result.data.avatar);
          }

        } else if (result.detail && result.detail == "Authentication credentials were not provided.") {
          let payload = {
            "grant_type": "password",
            "client_id": auth.auth.credentials.client_id,
            "client_secret": auth.auth.credentials.client_secret,
            "token": auth.auth.loginUserToken
          }

          dispatch(logOut(payload, (result) => {
          }));
        }

      }));
    };

    const goToHome = () => {
        historyy.replace('/home/create/');
    }

    const goToProfile = () => {
        historyy.replace('/home/profile/');
    }

    const logout = () => {
      let payload = {
        "grant_type": "password",
        "client_id": auth.auth.credentials.client_id,
        "client_secret": auth.auth.credentials.client_secret,
        "token": auth.auth.loginUserToken
      }

      dispatch(logOut(payload, (result) => {
        setLoaded(!loaded);
        if (result.status == true) {
          toast("You are successfully logged out of application.", {
            position: "top-right",
            autoClose: 3000,
            type: 'success',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });

          props.props.history.replace('/');
        }

      }));
    };

    return (
        <header>
            <div className="container">
                <div className="modal" id="myModal">
                    <div className="modal-dialog custom_dioalag">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Before leaving this page, do you wish to submit the recording you just made?</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <div className="btn_inner">
                                    <ul>
                                        <li>
                                            <button className="btn custom_yes" onClick={props.saveToServer} data-dismiss="modal">Yes, submit!</button>
                                        </li>
                                        <li>
                                        <button className="btn custom_no" onClick={props.skipQuestion} data-dismiss="modal">No, just move ahead</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(props.value != 100 || props.value == 2) && <div className="modal" id="profileModal">
                    <div className="modal-dialog custom_dioalag">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Before leaving this page, do you wish to submit the recording you just made?</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <div className="btn_inner">
                                    <ul>
                                        <li>
                                            <button className="btn custom_yes" onClick={props.saveToServer} data-dismiss="modal">Yes, submit!</button>
                                        </li>
                                        <li>
                                        <button className="btn custom_no"  onClick={goToProfile} data-dismiss="modal">No, Go ahead</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {(props.value != 100 || props.value == 2) && <div className="modal" id="LogoutModal">
                    <div className="modal-dialog custom_dioalag">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Before leaving this page, do you wish to submit the recording you just made?</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <div className="btn_inner">
                                    <ul>
                                        <li>
                                            <button className="btn custom_yes" onClick={props.saveToServer} data-dismiss="modal">Yes, submit!</button>
                                        </li>
                                        <li>

                                        <a style={{cursor: "pointer"}} className="btn custom_no" data-dismiss="modal" onClick={logout}>No, Go ahead</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    {(props.value == '' || props.value == 100 || props.value == 2) ? <Link to="/" className="navbar-brand">
                        <h3><img className="small_logo" src={icon_share} />Eterna<span>view</span></h3>
                    </Link> : <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal" className="navbar-brand">
                        <h3><img className="small_logo" src={icon_share} />Eterna<span>view</span></h3>
                    </a>}

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown">
                               <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img className="smap" src={profile_state} />
                                    Hello {user_state}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    { (props.value == '' || props.value == 100 || props.value == 2) ? <Link to="/home/profile/" className="dropdown-item">Profile</Link> : <a href="javascript:void(0)" data-toggle="modal" data-target="#profileModal" className="dropdown-item">Profile
                                        </a>}
                                    { (props.value == '' || props.value == 100 || props.value == 2) ? <a style={{cursor: "pointer"}} className="dropdown-item" onClick={logout}>Logout</a> : <a style={{cursor: "pointer"}} href="javascript:void(0)" data-toggle="modal" data-target="#LogoutModal" className="dropdown-item">Logout
                                        </a> }

                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>

    )
};


export default HeaderComponent;
