import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import video_recording from "../../Images/video_recording.png";
import hand_outer from "../../Images/hand_outer.png";
import keepasis from "../../Images/keepasis.png";
import mic from "../../Images/mic.gif";
import arrow_right from "../../Images/arrow_right.png";
import ctre45 from "../../Images/ctre45.png";
import { getCreateQuestion, updateAnswer, getQuestionById, getEditQuestion, saveCreateQuestion, scanQuestions, verifyUserSubscription } from '../../redux/actions';
import HeaderComponent from "../../components/Header";
import chat_tak from "../../Images/chat_tak.png";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import RecordRTC, { invokeSaveAsDialog } from 'recordrtc';
import getBlobDuration from 'get-blob-duration';
import timer1 from "../../Images/Timer1.png";
import timer2 from "../../Images/Timer2.png";
import timer3 from "../../Images/Timer3.png";
import start from "../../Images/start.png";
import ringtone from "../../Sounds/beep-07a.mp3";
import Loader from "react-loader";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { browserName, browserVersion } from "react-device-detect";
import AudioAnalyser from './AudioAnalyser';

const videoIOSType = 'video/mp4';
const videoWebType = 'video/webm';
const audioType = 'audio/*';

const details = navigator.userAgent;
const regexp = /android|iphone|kindle|ipad/i;
const isMobileDevice = regexp.test(details);

const videoJsOptions = {
    controls: true,
    bigPlayButton: false,
    autoplay: true,
    responsive: true,
    width: 480,
    height: 320,
    fluid: true
};

class EditEternaview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recording: false,
            recordingAfter: false,
            videos: [],
            completedata: {},
            data: {},
            recordedVideo: {},
            recordedAudio: {},
            category: '',
            subcategory: '',
            question: '',
            numberRecordings: '',
            stream: {},
            submitFlag: false,
            isSubmitted: false,
            videoUrl: '',
            audioUrl: '',
            duration: '',
            seconds: 4,
            typecreate: '',
            timer: 0,
            current_time_recording: '',
            isActive: false,
            isPaused: false,
            loaded: true,
            type: '',
            moduleQuestions: [],
            newRecording: false,
            total_time: false,
            grace_total_time: false,
            viewId: '',
            videoOptions: {
                video: {
                    width: { min: 320, ideal: 1920, max: 1920 },
                    height: { min: 180, ideal: 1080, max: 1080 },
                    aspectRatio: 1.7777777778,
                    facingMode: 'user'
                }, audio: true
            },
            videoFacing : 'user'
        };

        this.countRef = React.createRef(null);
    }

    componentDidMount() {
        if (this.props.auth.loginUserToken == "") {
            this.props.history.replace("/");
        }
        let split = (this.props.match.params.id).split("+");

        this.player = videojs(this.videoNode, videoJsOptions, function onPlayerReady() {
            // print version information at startup
            console.log('onPlayerReady', this)
        });

        // device is ready
        this.player.on('deviceReady', () => {
            console.log('device is ready!');
        });

        this.setState({ viewId: split[3], typecreate: (split[4] == "audio" || split[4] == '1' ? 1 : 0) });

        this.props.getEditQuestion(split[0], split[1], split[2], this.props.auth.loginUserToken, (res) => {
            let temp = res.data;
            this.setState({ completedata: res, data: temp });
            this.setState({ category: split[0], subcategory: split[1], question: split[2] });
            if(res.data && res.data.answer.video) {
                if (res.data.answer.video.type == "audio") {
                    this.setState({ audioUrl: res.data.answer.video.media_file, typecreate: 1, current_time_recording: res.current_time_recording });
    
                } else {
                    this.videoNode.src = res.data.answer.video.trans_coded_url;
                    this.player.src({
                        src: res.data.answer.video.trans_coded_url,
                        type: 'application/x-mpegURL'
                    });
                    this.setState({ videoUrl: res.data.answer.video.trans_coded_url, typecreate: res.data.answer.video.type == "video" ? 0 : 1, current_time_recording: res.current_time_recording });
                    
                }

                this.setState({ recording: false, numberRecordings: '2', isSubmitted: false });
            }

        });

        if (split[4] == 0) {
            this.video.src = this.video.srcObject = null;
            this.video.muted = false;
            this.video.volume = 1;

        } else {
            if (this.audio) {
                this.audio.src = this.audio.srcObject = null;
                this.audio.muted = true;
                this.audio.volume = 0;
            }

        }

        this.props.scanQuestions({}, `&question_scan=True`, this.props.auth.loginUserToken, (res) => {
            let con = res.data, questions = [], match = res.transition_name;

            if (res.data.length > 0) {
                con.map((data, index) => {
                    if (data.id == split[0]) {
                        data.modules.map((mod, ind) => {
                            if (mod.subcategory_name != "Interaction Facilitation Part 1 (required)" && mod.subcategory_name != "Interaction Facilitation Part 2" && mod.subcategory_name != "Pleasantries" && mod.subcategory_name != "Interaction Facilitation & Pleasantries (required)") {
                                questions.push(mod);
                            }
                        });
                    }

                });
                this.setState({ moduleQuestions: questions });
            }

        });

        this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
            if (res.data) {
                if (res.data.updated_details.total_time != 0) {
                    let remaining_time = (res.data.subscribe_plan__total_time * 3600) - res.data.updated_details.total_time;
                    // remaining_time = Math.ceil(remaining_time / 3600);
                    if (remaining_time == 1) {
                        this.setState({ total_time: true });
                    }
                } else if (res.data.updated_details.total_time == 0 && res.data.updated_details.grace_time > 0) {
                    let remaining_time = (res.data.subscribe_plan__grace_total_time * 3600) - res.data.updated_details.grace_time;
                    //  remaining_time = Math.ceil(remaining_time / 3600);
                    if (remaining_time == 1) {
                        this.setState({ grace_total_time: true });
                    }
                }
            }
        });

    }

    videoOptionsChange = (e) => {
        // alert(e.target.value);
         let videoOptions = {
             video: {
                 width: { min: 320, ideal: 1920, max: 1920 },
                 height: { min: 180, ideal: 1080, max: 1080 },
                 aspectRatio: 1.7777777778,
                 facingMode: e.target.value
             }, audio: true
         };
 
         this.setState({videoOptions : videoOptions, videoFacing : e.target.value});
     }

    async getCameraStream() {

        this.setState({ numberRecordings: '' });
        var audio = new Audio(ringtone);
        await navigator.mediaDevices.getUserMedia({
            video: {
                width: { min: 320, ideal: 1920, max: 1920 },
                height: { min: 180, ideal: 1080, max: 1080 },
                aspectRatio: 1.7777777778
            }, audio: true
        }).then(stream => {

            this.stream = stream;
            this.chunks = [];
            this.video.srcObject = stream;
            this.video.muted = true;
            this.video.volume = 0;

            this.setState({ seconds: 3 });

            audio.play();

            this.video.play();
            this.setState({ recording: true });

            let myInterval = setInterval(() => {
                if (this.state.seconds > 0) {
                    audio.play();
                    this.setState({ seconds: this.state.seconds - 1 });
                } else if (this.state.seconds <= 0) {
                    clearInterval(myInterval);
                    this.setState({ seconds: this.state.seconds - 1 });

                    this.countRef.current = setInterval(() => {
                        this.setState({ timer: this.state.timer + 1 });
                    }, 1000)

                    // listen for data from media recorder
                    this.setState({ recordingAfter: true });
                    this.setState({ isActive: true, isPaused: true });

                    var iosCheck = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

                    if (iosCheck == false) {
                        this.mediaRecorder = new MediaRecorder(stream, {
                            mimeType: (`${browserName}` == "Safari" || `${browserName}` == "safari") ? videoIOSType : videoWebType
                        });

                    } else {
                        this.mediaRecorder = new MediaRecorder(stream, {
                            mimeType: videoIOSType
                        });
                    }

                    this.mediaRecorder.start(10);

                    this.setState({ stream: this.mediaRecorder.stream });

                    this.mediaRecorder.ondataavailable = e => {
                        if (e.data && e.data.size > 0) {
                            this.chunks.push(e.data);
                        }
                    };

                    return stream;
                }
            }, 1000)
            // return ()=> {
            //     clearInterval(myInterval);
            // };

        }).catch(err => {
            alert("Please enable your webcamera and microphone in order to create eternaview.")
            this.setState({ hasWebcam: false });
            this.setState({ hasMicrophone: false });
            return false;
            //throw new Error("Unable to fetch stream " + err);
        });
        //  return localStream;
    }

    async getAudioStream() {

        this.setState({ numberRecordings: '' });
        var audiosound = new Audio(ringtone);

        await navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {

            this.stream = stream;
            this.chunks = [];
            this.audio.srcObject = stream;

            this.audio.muted = true;

            audiosound.play();
            this.setState({ seconds: 3 });

            this.setState({ recording: true });

            this.myInterval = setInterval(() => {
                if (this.state.seconds > 0) {
                    audiosound.play();
                    this.setState({ seconds: this.state.seconds - 1 });
                } else if (this.state.seconds <= 0) {
                    clearInterval(this.myInterval);
                    this.setState({ seconds: this.state.seconds - 1 });
                    this.countRef.current = setInterval(() => {
                        this.setState({ timer: this.state.timer + 1 });
                    }, 1000)
                    // listen for data from media recorder
                    this.setState({ recordingAfter: true });
                    this.setState({ isActive: true, isPaused: true });
                    this.setState({ audio: stream });

                    this.mediaRecorder = new MediaRecorder(stream);

                    this.mediaRecorder.start(10);

                    this.setState({ stream: this.mediaRecorder.stream });

                    this.mediaRecorder.ondataavailable = e => {
                        if (e.data && e.data.size > 0) {
                            this.chunks.push(e.data);
                        }
                    };

                    return stream;
                }
            }, 1000)

        }).catch(err => {
            console.log(err);
            alert("Please enable your webcamera and microphone in order to create eternaview.")
            this.setState({ hasWebcam: false });
            this.setState({ hasMicrophone: false });
            return false;
            //throw new Error("Unable to fetch stream " + err);
        });


    }

    startRecording(e) {
        e.preventDefault();

        this.handleReset();

        if (this.state.typecreate == 0) {
            this.getCameraStream();
        } else {
            this.getAudioStream();
        }

    }

    stopRecording(e) {
        e.preventDefault();

        this.handlePause();

        var iosCheck = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (this.state.typecreate == 0) {
            this.video.srcObject = this.video.src = null;
            this.video.muted = false;
            this.video.volume = 1;
            if (this.mediaRecorder) {
                this.mediaRecorder.stop();
            }

            if (iosCheck == false) {
                const blob = new Blob(this.chunks, { type: (`${browserName}` == "Safari" || `${browserName}` == "safari") ? videoIOSType : videoWebType });

                this.setState({ recordedVideo: blob });
                //  const videoURL = window.URL.createObjectURL(blob);
                const videoURL = window.webkitURL.createObjectURL(blob);

                this.setState({ videoUrl: videoURL });

                let that = this;

                getBlobDuration(blob).then(function (duration) {
                    that.setState({ duration: duration });
                }).catch((error) => {
                    console.error(error);
                });

            } else {
                const blob = new Blob(this.chunks, { type: 'video/mp4' });

                this.setState({ recordedVideo: blob });
                //  const videoURL = window.URL.createObjectURL(blob);
                const videoURL = window.webkitURL.createObjectURL(blob);

                this.setState({ videoUrl: videoURL });

                let that = this;

                getBlobDuration(blob).then(function (duration) {
                    that.setState({ duration: duration });
                }).catch((error) => {
                    console.error(error);
                });
            }


            if (iosCheck == false) {
                this.state.stream.getTracks().forEach(track => {
                    track.stop();
                });
            }

        } else if(this.state.typecreate == 1 || this.state.typecreate == '1') {
            this.audio.src = this.audio.srcObject = null;
            this.audio.muted = false;
            if (this.mediaRecorder) {
                this.mediaRecorder.stop();
            }

            const blob = new Blob(this.chunks, { type: (`${browserName}` == "Safari" || `${browserName}` == "safari") ? 'audio/mp4' : audioType });

            this.setState({ recordedVideo: blob });
            this.setState({ audio: null });

            const audioUrl = window.webkitURL.createObjectURL(blob);

            let that = this;

            getBlobDuration(blob).then(function (duration) {
                that.setState({ duration: duration });
            }).catch((error) => {
                console.error(error);
            });

            this.setState({ audioUrl: audioUrl });

            if (iosCheck == false) {
                this.state.stream.getTracks().forEach(track => {
                    track.stop();
                });
            }
        }
        this.setState({ recording: false, numberRecordings: '1', newRecording: true, recordingAfter: false });

    }

    retakeVideo(e) {
        e.preventDefault();

        this.setState({ numberRecordings: '', current_time_recording: '', newRecording: false });
        this.setState({ seconds: 4 });
        this.setState({ timer: 0 });

        if (this.state.typecreate == 0) {
            this.getCameraStream();
        } else {
            this.getAudioStream();
        }
    }

    saveToServer = (event) => {

        if (this.state.numberRecordings == '') {
            toast('Please record your answer to submit successfully.', {
                position: "top-right",
                autoClose: 3000,
                type: 'warning',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            return;
        }

        if (this.state.duration != '') {
            this.setState({ loaded: false });
            this.setState({ submitFlag: true });
            let formData = new FormData();
            formData.append("video", this.state.recordedVideo);
            formData.append("question_id", this.state.question);
            formData.append("language", this.state.data.language.id);
            formData.append("duration", this.state.duration);
            formData.append("size", this.state.recordedVideo.size);
            formData.append("view_id", this.state.viewId);
            if (this.state.data.answer && this.state.data.answer.id) {
                formData.append("id", this.state.data.answer.id);
            }

            formData.append("mode", "edit");
            if (this.state.typecreate == 0) {
                formData.append("type", "video");
            } else {
                formData.append("type", "audio");
            }

            //this.props.updateAnswer(this.state.category, this.state.subcategory, this.state.question, formData, this.props.auth.loginUserToken, (res) => {
            this.props.saveCreateQuestion(formData, this.props.auth.loginUserToken, (res) => {
                this.setState({ videoUrl: '' });
                this.setState({ loaded: true });
                this.setState({ submitFlag: false, isSubmitted: true });
                this.setState({ seconds: 4 });
                this.setState({ timer: 0 });
                if (res.data && res.data.msg) {
                    toast('Thank you for recording answer. Your answer has been updated successfully.', {
                        position: "top-right",
                        autoClose: 3000,
                        type: 'success',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });

                    this.props.history.replace(`/create/congratulations/${this.state.category}+${this.state.subcategory}+${this.state.typecreate == 0 ? 'video' : 'audio'}+view-${this.state.viewId}`);
                }
            });
        } else {
            toast('Firstly, Please ensure that the video/audio is playing back properly or try creating a new one.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }

        event.preventDefault();
    }

    takeBreak = (e) => {
        e.preventDefault();
        this.props.history.goBack();
        // this.props.history.replace('/home/create/');
    }

    formatTime = (timer) => {
        const getSeconds = `0${(timer % 60)}`.slice(-2)
        const minutes = `${Math.floor(timer / 60)}`
        const getMinutes = `0${minutes % 60}`.slice(-2)
        const getHours = `${Math.floor(timer / 3600)}`.slice(-2)

        return `${getHours}:${getMinutes}:${getSeconds}`
    }

    handlePause = () => {
        clearInterval(this.countRef.current)
        this.setState({ isPaused: false });
    }

    handleReset = () => {
        clearInterval(this.countRef.current)
        this.setState({ isActive: false, isPaused: false });
        this.setState({ timer: 0 });
    }

    closePopUp = (e) => {
        e.preventDefault();

        this.setState({ total_time: false });
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    render() {
        const { recording, recordingAfter, completedata, data, videoUrl, audioUrl, numberRecordings, seconds, loaded, moduleQuestions, newRecording } = this.state;

        return (
            <div>
                <HeaderComponent value={this.state.numberRecordings} saveToServer={this.saveToServer} skipQuestion={this.skipQuestion} props={this.props} />
                <section className="practing_outer record2 recored3 paid5 recording1 hide_hand crey78">
                    <Loader loaded={loaded}></Loader>
                    <div className="container">
                        <div className="row">
                            <div className="col-ld-10 col-md-10 offset-lg-1 offset-md-1">
                                <div className="middle_outer56 text5627 full_intr45">

                                    <div className="modal" id="myBreakModal">
                                        <div className="modal-dialog custom_dioalag">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Before moving back, do you wish to submit the recording you just made?</h4>
                                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                </div>

                                                <div className="modal-body">
                                                    <div className="btn_inner">
                                                        <ul>
                                                            <li><button className="btn custom_yes" onClick={e => this.saveToServer(e)} data-dismiss="modal">Yes, submit!</button></li>
                                                            <li><button className="btn custom_no" onClick={e => this.takeBreak(e)} data-dismiss="modal">No, just move back!</button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="myModal201" className={this.state.total_time == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Please note that you have consumed maximum recording time limit and left with only 1 hour.</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="myModal201" className={this.state.grace_total_time == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Please note that you have consumed maximum recording time limit along with additional time given as grace allotment and left with only 1 hour.</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h3 className="small_heding69">Module {completedata.current_module}/{completedata.total_sub_module} : {data?.sub_category?.name}</h3>

                                    <div className="tex_fulll2">
                                        <p className="question_name"> Question {completedata.current_page} of {completedata.total_question_count}</p>
                                        <h4>{data?.question}</h4>
                                    </div>

                                    {(numberRecordings == '' && recordingAfter == false) && <div className="same_height">
                                        {(!recording && numberRecordings == '') && <div className="btn7 custom_t6">
                                            <button className="btn" onClick={e => this.startRecording(e)}>START Recording</button>
                                        </div>}
                                    </div>}

                                    {(!recording && numberRecordings != '') && <div className="btn7 custom_t6">
                                        <button className="btn" disabled={numberRecordings != ''}>START Recording</button>
                                    </div>}

                                    {(recording == true && recordingAfter == true) && <div className="btn7 custom_t6 red_coloe5"><button className="btn" onClick={e => this.stopRecording(e)}>STOP Recording</button>
                                    </div>}

                                    <div className="img6 audio_recorder custom_rec45">
                                        {this.state.typecreate == 0 && <div>
                                            {(this.state.typecreate == 0 && recordingAfter) && <div className="recording_gif">
                                                <img src={mic} />
                                            </div>}
                                            <div className="timer_outer">
                                                {seconds == 1 && <img src={timer1} />}
                                                {seconds == 2 && <img src={timer2} />}
                                                {seconds == 3 && <img src={timer3} />}
                                                {seconds == 0 && <img src={start} />}
                                            </div>

                                            {(numberRecordings == '' && recording == false) && <div className="videoCheck">
                                            {isMobileDevice && <select className="changeCamera_select" value={this.state.videoFacing} onChange={(e) => this.videoOptionsChange(e)}>
                                                    <option value="user">Front Camera</option>
                                                    <option value="environment">Rear Camera</option>
                                                </select>}
                                            </div>}

                                            <div className={(this.state.typecreate == 0 && numberRecordings == '2') ? "" : "congratulation-video-hide"}><div data-vjs-player className='edit-video-clas'>
                                                <video id="myVideo" ref={node => this.videoNode = node} className="video-js vjs-default-skin custom_video_js edit-videoframe" options={videoJsOptions} playsInline></video>
                                            </div></div>

                                            {(this.state.typecreate == 0 && numberRecordings == '') && <video
                                                style={{ width: 900, height: 320 }}
                                                ref={v => {
                                                    this.video = v;
                                                }}
                                                playsInline
                                            >
                                                Video stream not available.
                                            </video>}

                                            {(this.state.typecreate == 0 && numberRecordings != '2' && numberRecordings != "") && <video id="video_player" style={{ width: 900, height: 320 }} src={videoUrl} controls playsInline ></video>}

                                            {/* {(this.state.typecreate == 0 && numberRecordings != '') && <video id="video_player" style={{ width: 900, height: 320 }} ref={v => {
                                                this.videocreated = v;
                                            }} src={videoUrl} controls ></video>} */}

                                        </div>}

                                        {this.state.typecreate == 1 && <div className='audio_div_box'>
                                            {(localStorage.getItem('typecreate') == 1 && recordingAfter) && <div className="recording_audio_gif">
                                                <img src={mic} />
                                            </div>}

                                            {this.state.audio ? <AudioAnalyser audio={this.state.audio} /> : ''}
                                            {/* {this.state.flagFrame == false && <img src={video_recording} />} */}

                                            <div className="timer_outer">
                                                {seconds == 1 && <img src={timer1} />}
                                                {seconds == 2 && <img src={timer2} />}
                                                {seconds == 3 && <img src={timer3} />}
                                                {seconds == 0 && <img src={start} />}
                                            </div>

                                            {(this.state.typecreate == 1 && numberRecordings == '') && <audio
                                                ref={a => {
                                                    this.audio = a;
                                                }}

                                            >
                                                <p>Audio stream not available. </p>
                                            </audio>}

                                            {(this.state.typecreate == 1 && numberRecordings != '') && <audio controls src={audioUrl} controlsList="nodownload" />}
                                        </div>}

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="text_nam546">

                                                    <p>Recording Time of This Question: {this.state.current_time_recording != '' ? this.state.current_time_recording : ((new Date(this.state.duration * 1000).toISOString()).substr(11, 8))} <br />
                                                        Cumulative Recording Time: {completedata.total_time_recording ? completedata.total_time_recording : '00:00:00'}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="btn_outer99 recor67">
                                                    <ul>
                                                        {(newRecording && this.state.submitFlag == false) && <li className="Save_outer4"><button className="btn" onClick={e => this.saveToServer(e)}>Submit</button></li>}
                                                        {(this.state.submitFlag == true) && <li className="Save_outer4"><button className="btn">Submitting...</button></li>}

                                                        {(this.state.isSubmitted == false && numberRecordings != '') && <li className="cancel"><button className="btn" onClick={e => this.retakeVideo(e)}>Retake</button></li>}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        {(numberRecordings == '' || numberRecordings == '2') && <div className="take_break" onClick={e => this.takeBreak(e)}>
                                            <img src={keepasis} />
                                        </div>}
                                        {(numberRecordings != '' && numberRecordings != '2') && <div className="take_break" data-toggle="modal" data-target="#myBreakModal">
                                            <img src={keepasis} />
                                        </div>} 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="greand_pop">
                    <div className="conatiner">
                        <div className="img56">
                            {(numberRecordings == '' || numberRecordings == '2') && <a data-toggle="modal" data-target="#myModal12"><img src={hand_outer} /></a>}

                            {(numberRecordings != '' && numberRecordings != '2') && <a data-toggle="modal" data-target="#myBreakModal"><img src={hand_outer} /></a>}

                            <div className="modal" id="myModal12">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body cu678">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="box activer4">
                                                        <h4>Frequently Asked Questions</h4>
                                                        <div className="few3_outer">
                                                            <i className="fas fa-circle"></i>
                                                            <p>Dummy lorem ipsum dolor text?</p>
                                                        </div>
                                                        <div className="few3_outer no_bo675">
                                                            <i className="fas fa-circle"></i>
                                                            <p>Dummy lorem ipsum dolor text?</p>
                                                        </div>
                                                        <div className="btn78"><a href="#">View all</a></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="box">
                                                        <h4>Scan Modules & Questions in Template</h4>
                                                        <div className="custtom_according">
                                                            <div className="bs-example">
                                                                <div className="accordion" id="accordionExample">
                                                                    {moduleQuestions?.map((subcat, ind) => {
                                                                        return (
                                                                            <div className="card">
                                                                                <div className="card-header" id={"headingOne" + ind}>
                                                                                    <h2 className="mb-0">
                                                                                        <button type="button" className="btn btn-link" data-toggle="collapse" data-target={"#collapseOne" + ind}>{subcat.subcategory_name}<i className="fa fa-angle-down"></i> </button>
                                                                                    </h2>
                                                                                </div>
                                                                                <div id={"collapseOne" + ind} className="collapse" aria-labelledby={"headingOne" + ind} data-parent="#accordionExample">
                                                                                    <div className="card-body cus56">
                                                                                        {
                                                                                            subcat.questions.map((ques, index) => {
                                                                                                return (

                                                                                                    <p>{ques.question} </p>

                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="box">
                                                        <h4>Demo Video</h4>
                                                        <div className="img6_outer"><img src={ctre45} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="box vill98">
                                                        <h4>Blog Posts</h4>
                                                        <div className="blog_box1">
                                                            <img src="assets/img/blog1.png" />
                                                            <div className="texcd4">
                                                                <p>Creating A Personal Connection With Eternaview</p>
                                                                <span><img src={arrow_right} /></span>
                                                            </div>
                                                        </div>
                                                        <div className="btn78"><a href="#">View all</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>);
    }
}

const styles = {
    'wrapper': {
        display: 'flex',
        overflow: 'auto'
    },
    'child': {
        flex: 2
    },
    'videos': {
        flex: 1
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
   // getCreateQuestion: bindActionCreators(getCreateQuestion, dispatch),
    updateAnswer: bindActionCreators(updateAnswer, dispatch),
  //  getQuestionById: bindActionCreators(getQuestionById, dispatch),
    getEditQuestion: bindActionCreators(getEditQuestion, dispatch),
    saveCreateQuestion: bindActionCreators(saveCreateQuestion, dispatch),
    scanQuestions: bindActionCreators(scanQuestions, dispatch),
    verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEternaview);


