import React from "react";

import { Col, Container, Row } from "reactstrap";

const Auth = ({ children }) => (
  <React.Fragment>
    {children}
    {/* <Container className="d-flex flex-column">
        <Row className="h-100">
            <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
            <div className="d-table-cell align-middle">{children}</div>
            </Col>
        </Row>
    </Container> */}
  </React.Fragment>
);

export default Auth;
