import async from "../components/Async";
// Landing
import Landing from "../pages/landing/Landing";

// Auth
import SignIn from "../pages/auth/SignIn";
import Page404 from "../pages/auth/Page404";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Profile from "../pages/dashboard/Profile";
import CreateIntro from "../pages/dashboard/Create-Intro";
import SystemTest from "../pages/dashboard/SystemTest";
import Practice from "../pages/dashboard/Practice";
import VideoRecording from "../pages/recording/VideoRecording";
import RecordingAudio from "../pages/recording/RecordingAudio";
import LiveStreaming from "../pages/recording/LiveStreaming";
import Interact from "../pages/dashboard/Interact";
import BlogPosts from "../pages/dashboard/BlogPosts";
import BlogDetail from "../pages/dashboard/BlogDetail";
import InteractOne from "../pages/dashboard/InteractOne";
import PracticeInteract from  "../pages/dashboard/PracticeInteract";
import MyEternaviews from "../pages/dashboard/MyEternaviews";
import CreateEternaview from "../pages/dashboard/CreateEternaview";
import Faq from "../pages/dashboard/Faq";
import Plans from "../pages/dashboard/Plans";
import ForgotPassword from "../pages/auth/forgotPassword";
import CongratulationForm from "../pages/dashboard/CongratulationForm";
import Congratulations from "../pages/dashboard/Congratulations";
import EditEternaview from "../pages/dashboard/EditEternaview";
import InteractFind from "../pages/dashboard/InteractFind";
import Subscribe from "../pages/dashboard/Subscribe";
import SubscribeDone from "../pages/dashboard/SubscribeDone";
import PrebuiltCheckoutStripe from "../pages/dashboard/PrebuiltCheckoutStripe";
import Account from "../pages/dashboard/Account";
import Cancel from "../pages/dashboard/Cancel";
import ContactUs from "../pages/dashboard/ContactUs";
import GeneralPlans from "../pages/dashboard/GeneralPlans";
import Thankyou from "../pages/dashboard/Thankyou";
import Terms from "../pages/dashboard/Terms";
import AboutUs from "../pages/dashboard/AboutUs";
import PrivacyPolicy from "../pages/dashboard/PrivacyPolicy";

const Default = async(() => import("../pages/dashboard/Default"));

// Routes
const landingRoutes = {
  path: "/",
  name: "Dashboard",
  component: Default,
  children: null
};

const dashboardRoutes = {
  path: "/home",
  name: "Dashboard",
  children: [
    {
      path: "/oauth/sign-in/",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/oauth/sign-up/",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/oauth/forgot-password/",
      name: "Forgot Password",
      component: ForgotPassword
    },
    {
      path: "/oauth/reset-password/:id",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/home/index/:id",
      name: "Home",
      component: Default,
    },
    {
      path: "/home/profile/",
      name: "Home",
      component: Profile,
    },
    {
      path: "/home/create/",
      name: "Create",
      component: CreateIntro,
    },
    {
      path: "/home/interact/",
      name: "Interact",
      component: Interact,
    },
    {
      path: "/home/interact-one/:id/:author/:permission?",
      name: "InteractOne",
      component: InteractOne,
    },
    {
      path: "/home/practice-interact/",
      name: "InteractOne",
      component: PracticeInteract,
    },
    {
      path: "/home/interact-find/",
      name: "InteractFind",
      component: InteractFind,
    },
    {
      path: "/home/blogs/",
      name: "Blogs",
      component: BlogPosts,
    },
    {
      path: "/home/blogs/:id",
      name: "Blog Detail",
      component: BlogDetail,
    },
    {
      path: "/home/myeternaviews/",
      name: "MyEternaviews",
      component: MyEternaviews,
    },
    {
      path: "/home/faq/",
      name: "FQA",
      component: Faq,
    },
    {
      path: "/home/about-us/",
      name: "AboutUs",
      component: AboutUs,
    },
    {
      path: "/home/terms-of-use/",
      name: "Terms",
      component: Terms,
    },
    {
      path: "/home/privacy-policy/",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
    },
    {
      path: "/home/contact-us/",
      name: "ContactUs",
      component: ContactUs,
    },
    {
      path: "/home/plans/:id",
      name: "Plans",
      component: Plans,
    },
    {
      path: "/home/thank-you-plan/:id",
      name: "Thankyou",
      component: Thankyou,
    },
    {
      path: "/home/pricing-plans",
      name: "General Plans",
      component: GeneralPlans,
    },
	  // {
    //   path: "/home/subscribe/:id",
    //   name: "Subscribe",
    //   component: Subscribe,
    // },
    // {
    //   path: "/home/subscribe-done/:id",
    //   name: "SubscribeDone",
    //   component: SubscribeDone,
    // },
    {
      path: "/home/make-payment",
      name: "PrebuiltCheckoutStripe",
      component: PrebuiltCheckoutStripe,
    },
	 {
      path: "/home/account/",
      name: "Account",
      component: Account,
    },
	 {
      path: "/home/cancel/",
      name: "Cancel",
      component: Cancel,
    }

  ]
};


const authRoutes = {
  path: "/oauth",
  name: "Auth",
  children: [
    {
      path: "/oauth/404/",
      name: "404 Page",
      component: Page404
    }
  ]
};

const LayoutRoutes = {
  path: "/create",
  name: "Create",
  children: [
    {
      path: "/create/system-test/",
      name: "System Test",
      component: SystemTest,
    },
    {
      path: "/create/practice-test/",
      name: "Practice Test",
      component: Practice,
    },
    {
      path: "/create/eternaview/:id",
      name: "CreateEternaview",
      component: CreateEternaview,
    },
    {
      path: "/edit/eternaview/:id",
      name: "EditEternaview",
      component: EditEternaview,
    },
    {
      path: "/create/congratulations/:id",
      name: "Congratulations",
      component: Congratulations,
    },
    {
      path: "/create/congratulation/form/:id",
      name: "CongratulationForm",
      component: CongratulationForm,
    },
    {
      path: "/video-record",
      name: "video-record",
      component: VideoRecording
    },
    {
      path: "/audio-record",
      name: "audio-record",
      component: RecordingAudio
    },
    {
      path: "/live-streaming",
      name: "live-streaming",
      component: LiveStreaming
    }
  ]
};


// Dashboard specific routes
export const dashboard = [
  dashboardRoutes
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

export const layout = [LayoutRoutes];

// All routes
export default [
  dashboardRoutes,
  landingRoutes
];
