import React, { useEffect, useState } from "react";
import fb from "../Images/fb.png";
import tw from "../Images/tw.png";
import linkdin from "../Images/linkdin.png";
import footer_logo from "../Images/footer_logo.png";
import { Link } from "react-router-dom";
import {
  useDispatch, useSelector
} from 'react-redux';

function Footer(props) {
  const dispatch = useDispatch();
  const auth = useSelector(state => state);

  return(
  <div>
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="footer1">
             <Link to="/"><img src={footer_logo} /></Link>
              <ul className="soical_outer">
                <li><a href="#"><img src={fb} /></a></li>
                <li><a href="#"><img src={tw} /></a></li>
                <li><a href="#"><img src={linkdin} /></a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-12">
            <div className="footer1">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/home/create/">Create</Link>
                </li>
                <li><Link to="/home/interact/">Interact</Link></li>
                {auth.auth.loginUserToken == "" &&
                <li><Link to="/oauth/sign-in/">Login/Register
                </Link>
                </li>}
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-12">
            <div className="footer1">
              <ul>
                {/* {auth.auth.loginUserToken != "" && <li><Link to="/home/pricing-plans">Pricing
                </Link>
                </li>} */}
                <li><Link to="/home/pricing-plans">Pricing
                </Link>
                </li>
                <li><a href="#">Demos
                </a>
                </li>
                <li><Link to="/home/myeternaviews/">My Collections
                </Link>
                </li>
                <li><Link to="/home/blogs/">Blog
                </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-12">
            <div className="footer1 footer4">
              <ul>
                <li><Link to="/home/about-us/">About
                </Link>
                </li>
                <li><Link to="/home/faq/">FAQ
                </Link>
                </li>
                <li><Link to="/home/contact-us/">Support/Contact Us
                </Link>
                </li>
              </ul>
              
            </div>
          </div>
        </div>
        <div className="row border_top">
          <ul className="soical_outer copy_right6">
            <li><Link to="/home/terms-of-use/">Terms of use</Link></li>
            <li><a>|</a></li>
            <li><Link to="/home/privacy-policy/">Privacy  Policy
            </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>);
};

export default Footer;
