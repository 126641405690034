import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import edit from "../../Images/edit.png";
import { Form } from "reactstrap";
import { register, getCredentials, registerSocialLogin, login, checkEternaviewCreated } from "../../redux/actions/index";
import Loader from "react-loader";


function SignIn(props) {
    const dispatch = useDispatch();

    let [email, setEmail] = useState('')
    let [emailError, setEmailError] = useState('')
    let [password, setPassword] = useState('')
    let [passwordError, setPasswordError] = useState('')
    let [inputtype, setInputType] = useState(false);
    let [loaded, setLoaded] = useState(true);
    const auth = useSelector(state => state);

    useEffect(() => {
        if (auth.auth.loginUserToken != "") {
            props.history.push('/');
        }
        setInputType(false);
        setLoaded(true);
    }, []);

    function showPassword() {
        setInputType(!inputtype);
    };

    function handleEmailInput(event) {
        setEmail(event.target.value)
        setEmailError(validate('email', event.target.value))
    };

    function handlePasswordInput(event) {
        setPassword(event.target.value)
        setPasswordError(validate('password', event.target.value))
    }

    function validate(name, value) {
        switch (name) {
            case "email":
                if (!value || value.trim() === "") {
                    return "Email is required.";
                } else {
                    return "";
                }
            case "password":
                if (!value || value.trim() === "") {
                    return "Password is required.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setLoaded(false);
        dispatch(getCredentials((res) => {
            if (res.status == true) {
                let payload = {
                    "username": email,
                    "password": password,
                    "grant_type": "password",
                    "client_id": res.res.client_id,
                    "client_secret": res.res.client_secret
                }
                dispatch(login(payload, (result) => {
                    setLoaded(true);
                    if (result.error == "invalid_grant") {
                        toast('Your email or password is incorrect. Please click the “forgot password” link if you would like reset your password or “Register Now” if you haven’t registered yet.', {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'error',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });

                        return;
                    } else if (result.access_token) {
                        dispatch(checkEternaviewCreated(result.access_token, (result) => {
                            console.log(result);
                        }));

                        toast('You are successfully logged into the application.', {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'success',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });
                        props.history.push('/');
                    } else {
                        toast(result.error, {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'error',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });

                        return;
                    }
                }));
            }
        }));

    };

    const responseGoogle = (response) => {
        if (!response.error) {
            console.log('Logged In successfully with Google');
        } else {
            return;
        }

        setLoaded(false);
        const googleresponse = {
            Name: response.profileObj.name,
            email: response.profileObj.email,
            token: response.googleId,
            Image: response.profileObj.imageUrl,
            ProviderId: 'google'
        };
        socialLogin(googleresponse);
    }

    const responseFacebook = (response) => {
        if(!response.error) {
            console.log('Logged in facebook');
        } else {
            return;
        }

        setLoaded(false);
        const fbresponse = {
            Name: response.name,
            email: response.email,
            token: response.id,
            ProviderId: 'facebook'
        };
        socialLogin(fbresponse);
    }

    const socialLogin = (payload) => {
        dispatch(getCredentials((res) => {
            if (res.status == true) {
                let payload1 = {
                    "client_id": res.res.client_id,
                    "client_secret": res.res.client_secret,
                    "username": payload.email,
                    "unique_id": payload.token,
                    "plateform": payload.ProviderId
                }
                dispatch(registerSocialLogin(payload1, (result) => {
                    setLoaded(true);
                    if (result.error) {
                        toast(result.error_description, {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'error',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });

                    } else if (result.access_token) {
                        toast('You are successfully logged into application.', {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'success',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });
                        props.history.push('/');
                    }
                }));
            }
        }));
    }

    const onLogin = () => {
        let response = responseFacebook();
        if (response.status !== 'unknown') {
          dispatch(FacebookLogin, (response))
            .then(data => {
            //   setFbData(response);
            //   setPicture(response.picture.data.url);
            //   if (response.accessToken) {
            //   //  setLogin(true);
            //   } else {
            // //    setLogin(false);
            //   }

              console.log('data is --------');
              console.log(data);
            }).catch(err => {
              console.log("error-->", err)
            })
        } else {
        //  setLogin(false)
        }
    }

    return (
        <section className="login_outer login67">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 offset-lg-3 offset-md-0">
                        <Form onSubmit={(event) => handleFormSubmit(event)} autoComplete="off">
                            <div className="login_inner4">
                                <h4>Log in to<Link to="/"><span className="etnat"> Eterna</span><span className="view_outre">view</span></Link></h4>
                                <div className="google_add">
                                    {/* <a href="#"><img src="assets/img/google.png" />Sign In with Google</a> */}
                                    <GoogleLogin
                                        clientId="364132936223-1dm6pioltpokfbe9s7co6savjjlkd6n0.apps.googleusercontent.com"
                                        buttonText="Sign In with Google"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}>
                                    </GoogleLogin>
                                </div>
                                <div className="google_add fb_inner">
                                    {/* <a href="#"><img src="assets/img/facebook1.png" />Sign In with Facebook</a> */}
                                    <FacebookLogin
                                        appId="1016816069106032"
                                        textButton="Sign In with Facebook"
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        callback={responseFacebook}
                                        icon="fa-facebook" />
                                </div>
                                <p>Or Use Your Email Account</p>

                                <div className="input_outer99">
                                    <input type="email" name="email" required placeholder="Email" value={email} onChange={handleEmailInput} />
                                </div>

                                <div className="input_outer99">
                                    {inputtype == false &&
                                        <input type="password" name="password" placeholder="Password" required value={password} onChange={handlePasswordInput} />
                                    }
                                    {inputtype == true &&
                                        <input type="text" name="password" placeholder="Password" required value={password} onChange={handlePasswordInput} />
                                    }

                                    {inputtype == false && <img src={edit} onClick={showPassword} />}
                                    {inputtype == true && <i className="fa fa-eye-slash" aria-hidden="true" onClick={showPassword}></i>}
                                </div>

                                <div className="checkbox custom_check">
                                    <label className="container98">Remember me
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                    </label>
                                    <Link to="/oauth/forgot-password/">Forgot password?</Link>
                                </div>

                                <div className="log_outer">
                                    {/* <button type="submit">Log in</button> */}
                                    {loaded == true && <button type="submit">Log in</button>}
                                    {loaded == false && <button type="button">Logging in...</button>} 

                                    <Loader loaded={loaded}></Loader>
                                </div>
                                <p className="new_user5">New User?<Link to="/oauth/sign-up/"> Register Now</Link></p>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </section>

    )
};

export default SignIn;
