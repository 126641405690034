import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { logOut, profile } from "../redux/actions";
import { useDispatch } from 'react-redux';
import { toastr } from "react-redux-toastr";
import profile_pc from "../Images/user-default.jpeg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader";


function NavbarComponent(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const auth = useSelector(state => state);
  const [user_state, setUserState] = React.useState("");
  const [profile_state, setProfileState] = React.useState(profile_pc);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (auth.auth.loginUserToken != '') {
      getProfile(auth.auth.loginUserToken);
    } else {
    //  setUserState(auth.auth.loginUserInfo.first_name);
    //  setProfileState(auth.auth.loginUserInfo.avatar);
    }
  }, [auth]);

  const getProfile = (token) => {
    dispatch(profile({ token: token }, (result) => {
      if (result.data) {
        if(result.data && result.data.first_name != "") {
          setUserState(result.data.first_name);
        }
        
        if(result.data && result.data.avatar != "") {
          setProfileState(result.data.avatar);
        }
        
      } else if (result.detail && result.detail == "Authentication credentials were not provided.") {
        let payload = {
          "grant_type": "password",
          "client_id": auth.auth.credentials.client_id,
          "client_secret": auth.auth.credentials.client_secret,
          "token": auth.auth.loginUserToken
        }

        dispatch(logOut(payload, (result) => {
        }));
      }

    }));
  };

  const logout = () => {
    let payload = {
      "grant_type": "password",
      "client_id": auth.auth.credentials.client_id,
      "client_secret": auth.auth.credentials.client_secret,
      "token": auth.auth.loginUserToken
    }

    dispatch(logOut(payload, (result) => {
      if (result.status == true) {
        toast("You are successfully logged out of application.", {
          position: "top-right",
          autoClose: 3000,
          type: 'success',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setLoaded(!loaded);
        props.props.history.replace('/oauth/sign-in/');
      }

    }));
  };

  return (

    <header>
      {
        (loaded) &&
        <Redirect to="/oauth/sign-in/" />
      }
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link className="navbar-brand" to="/">
            <h3>Eterna<span>view</span></h3>
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className={splitLocation[1] === "" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className={splitLocation[2] === "create" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/home/create/">Create</Link>
              </li>
              <li className={splitLocation[2] === "interact" || splitLocation[2] === "interact-one" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/home/interact/">Interact</Link>
              </li>
              <li className={splitLocation[2] === "myeternaviews" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/home/myeternaviews/">My Collections</Link>
              </li>
              {/* {auth.auth.loginUserToken ? <li className={splitLocation[2] === "myeternaviews" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/home/myeternaviews/">My Collections</Link>
              </li> : <></>} */}
              {auth.auth.loginUserToken ?
                <li className={splitLocation[2] === "profile" ? "nav-item dropdown login_outer4" : "nav-item dropdown"}>
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img className="smap" src={profile_state} />
                    Hello {user_state}
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/home/profile/">Profile</Link>
                    <a style={{cursor: "pointer"}} className="dropdown-item" onClick={logout}>Logout</a>
                  </div>
                </li>
                :
                <li className={splitLocation[2] === "sign-in" || splitLocation[2] === "sign-up" || splitLocation[2] === "reset-password" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/oauth/sign-in/">Login/Register</Link>
                </li>}
            </ul>
          </div>
        </nav>
      </div>
    </header>

  );
};

export default NavbarComponent;
