
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage'; // default: localStorage if web, AsyncStorage if react-native
import { routerReducer as router } from 'react-router-redux';

import auth from "./authReducer";

import { reducer as toastr } from "react-redux-toastr";

const userPersistConfig = {  
  key: 'react-app',
  storage: storage
};

export default persistCombineReducers(userPersistConfig, {
  auth,
  toastr,
  router
});
