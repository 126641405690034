import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import video_mute from "../../Images/video_mute.png";
import video_recording from "../../Images/video_recording.png";
import mic from "../../Images/mic.gif";
import { getPracticeQuestion, postPracticeQuestion, getPracticeRestoreSession, skipPracticeQuestion, submitPracticeEterna } from '../../redux/actions';
import HeaderComponent from "../../components/Header";
import real_think from "../../Images/real_think.png";
import { toast } from 'react-toastify';
import getBlobDuration from 'get-blob-duration';
import timer1 from "../../Images/Timer1.png";
import timer2 from "../../Images/Timer2.png";
import timer3 from "../../Images/Timer3.png";
import start from "../../Images/start.png";
import Loader from "react-loader";
import ringtone from "../../Sounds/new-ringtone.mp3";
import { browserName, browserVersion } from "react-device-detect";

const videoIOSType = 'video/mp4';
const videoWebType = 'video/webm';
const iosCheck = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

class Practice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recording: false,
            recordingAfter: false,
            videos: [],
            data: {},
            nextpage: '',
            recordedVideo: {},
            category: '',
            subcategory: '',
            numberRecordings: '',
            stream: {},
            submitFlag: false,
            completedata: {},
            videoUrl: '',
            duration: '',
            seconds: 4,
            isSubmitted: false,
            loaded: true,
            timer: 0,
            checkTransition: false,
            firstTimePop: false,
            hasMicrophone: false,
            hasWebcam: false
        };

        this.countRef = React.createRef(null);
        this.myInterval = React.createRef(null);

    }

    async componentDidMount() {

        if (this.props.auth.loginUserToken == "") {
            localStorage.clear();
            this.props.history.replace("/");
        }

        //      this.getQuestion();
        this.setState({ loaded: false });
        let url = `practice-question/`;
        this.props.getPracticeRestoreSession(this.props.auth.loginUserToken, (res) => {
            this.setState({ loaded: true });
            if (res.id && res.id != "") {
                url = `practice-question/?restore_session=${res.id}`;
            }

            this.props.getPracticeQuestion(url, this.props.auth.loginUserToken, (response) => {
                if (response.data && response.data.length > 0) {
                    if (response.data[0] && response.data[0].transition == true) {

                        if (this.state.checkTransition == false) {
                            this.setState({ firstTimePop: true, checkTransition: true });
                        } else {
                            this.setState({ firstTimePop: false });
                        }
                    }
                    this.setState({ data: response.data[0], nextpage: response.current_page, completedata: response });
                    this.setState({ recording: false, numberRecordings: '', isSubmitted: false });
                } else {
                    let payload = {
                        "title": "PRACTICE",
                        "name": this.props.auth.loginUserInfo.first_name ? this.props.auth.loginUserInfo.first_name : "",
                        "email": this.props.auth.loginUserInfo.email ? this.props.auth.loginUserInfo.email : "",
                        "type": "video"
                    }
                    this.props.submitPracticeEterna(payload, this.props.auth.loginUserToken, (response1) => {
                        if (response1.msg) {
                            this.props.history.push(`/home/practice-interact/`);
                        } else {
                            toast(response1.error, {
                                position: "top-right",
                                autoClose: 3000,
                                type: 'error',
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                            });
                        }
                    });

                }
            });
        });


        this.video.src = this.video.srcObject = null;
        this.video.muted = false;
        this.video.volume = 1;

    }

    getQuestion() {
        //    alert('Entered');
        this.setState({ loaded: false });
        let url = `practice-question/`;
        this.props.getPracticeRestoreSession(this.props.auth.loginUserToken, (res) => {
            this.setState({ loaded: true });
            //     alert('Step 1');
            if (res.id && res.id != "") {
                url = `practice-question/?restore_session=${res.id}`;
            }

            //    alert('Step 2');
            this.props.getPracticeQuestion(url, this.props.auth.loginUserToken, (response) => {
                //      alert('Step 3');
                if (response.data && response.data.length > 0) {
                    //  alert(response.data[0].transition);
                    if (response.data[0] && response.data[0].transition == true) {
                        //      alert(response.data[0].transition);
                        //     alert('Step 4');
                        if (this.state.checkTransition == false) {
                            this.setState({ firstTimePop: true, checkTransition: true });
                        } else {
                            this.setState({ firstTimePop: false });
                        }
                    }
                    this.setState({ data: response.data[0], nextpage: response.current_page, completedata: response });
                    this.setState({ recording: false, numberRecordings: '', isSubmitted: false });
                } else {
                    let payload = {
                        "title": "PRACTICE",
                        "name": this.props.auth.loginUserInfo.first_name ? this.props.auth.loginUserInfo.first_name : "",
                        "email": this.props.auth.loginUserInfo.email ? this.props.auth.loginUserInfo.email : "",
                        "type": "video"
                    }
                    this.props.submitPracticeEterna(payload, this.props.auth.loginUserToken, (response1) => {
                        if (response1.msg) {
                            this.props.history.push(`/home/practice-interact/`);
                        } else {
                            toast(response1.error, {
                                position: "top-right",
                                autoClose: 3000,
                                type: 'error',
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                            });
                        }
                    });

                }
            });
        });
    }

    async getCameraStream() {

        this.setState({ numberRecordings: '' });
        var audio = new Audio(ringtone);
        // Older browsers might not implement mediaDevices at all, so we set an empty object first
        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia != undefined) {
            await navigator.mediaDevices.getUserMedia({
                video: {
                    width: { min: 320, ideal: 1920, max: 1920 },
                    height: { min: 180, ideal: 1080, max: 1080 },
                    aspectRatio: 1.7777777778
                },
                audio: true
            }).then(stream => {

                //  alert(stream);
                this.stream = stream;
                this.chunks = [];
                this.video.srcObject = stream;
                this.video.muted = true;
                this.video.volume = 0;

                audio.play();
                this.setState({ seconds: 3 });

                this.video.play();

                this.setState({ recording: true });

                this.myInterval = setInterval(() => {
                    if (this.state.seconds > 0) {
                        audio.play();
                        this.setState({ seconds: this.state.seconds - 1 });
                    } else if (this.state.seconds <= 0) {
                        clearInterval(this.myInterval);
                        this.setState({ seconds: this.state.seconds - 1 });
                        //  this.setState({ timer: this.state.timer + 1 });
                        this.countRef.current = setInterval(() => {
                            if (this.state.timer == 120) {
                                this.performStopRecording();
                            } else {
                                this.setState({ timer: this.state.timer + 1 });
                            }

                        }, 1000)
                        // listen for data from media recorder
                        this.setState({ recordingAfter: true });
                        this.setState({ isActive: true, isPaused: true });

                        if (iosCheck == false) {
                            this.mediaRecorder = new MediaRecorder(stream, {
                                mimeType: (`${browserName}` == "Safari" || `${browserName}` == "safari") ? videoIOSType : videoWebType
                            });

                        } else {
                            this.mediaRecorder = new MediaRecorder(stream, {
                                mimeType: videoIOSType
                            });
                        }

                        this.mediaRecorder.start(10);

                        this.setState({ stream: this.mediaRecorder.stream });

                        this.mediaRecorder.ondataavailable = e => {
                            if (e.data && e.data.size > 0) {
                                this.chunks.push(e.data);
                            }
                        };
                        //  alert(this.chunks);
                        return stream;
                    }
                }, 1000)
                // return ()=> {
                //     clearInterval(myInterval);
                // };

            }).catch(err => {
                alert("Please enable your webcamera and microphone in order to create eternaview.")
                this.setState({ hasWebcam: false });
                this.setState({ hasMicrophone: false });
                return false;
                //throw new Error("Unable to fetch stream " + err);
            });
            //  return localStream;
        } else {
            alert('getUserMedia not supported on your browser!');
        }

    }

    startRecording(e) {
        e.preventDefault();

        this.handleReset();

        this.getCameraStream();

    }

    formatTime = (timer) => {
        const getSeconds = `0${(timer % 60)}`.slice(-2)
        const minutes = `${Math.floor(timer / 60)}`
        const getMinutes = `0${minutes % 60}`.slice(-2)
        const getHours = `${Math.floor(timer / 3600)}`.slice(-2)

        return `${getHours}:${getMinutes}:${getSeconds}`
    }

    handlePause = () => {
        clearInterval(this.countRef.current)
        this.setState({ isPaused: false });
    }

    handleReset = () => {
        clearInterval(this.countRef.current)
        this.setState({ isActive: false, isPaused: false });
        this.setState({ timer: 0 });
    }

    stopRecording(e) {
        e.preventDefault();

        this.performStopRecording();
    }

    performStopRecording() {
        clearInterval(this.countRef.current);

        this.setState({ isPaused: false });
        this.setState({ recording: false, numberRecordings: '1', recordingAfter: false });

        this.video.srcObject = this.video.src = null;
        this.video.muted = false;
        this.video.volume = 1;
        if (this.mediaRecorder) {
            this.mediaRecorder.stop();
        }

        if (iosCheck == false) {
            const blob = new Blob(this.chunks, { type: (`${browserName}` == "Safari" || `${browserName}` == "safari") ? videoIOSType : videoWebType });

            this.setState({ recordedVideo: blob });
            //  const videoURL = window.URL.createObjectURL(blob);
            const videoURL = window.webkitURL.createObjectURL(blob);

            this.setState({ videoUrl: videoURL });

            let that = this;

            getBlobDuration(blob).then(function (duration) {
                that.setState({ duration: duration });
            }).catch((error) => {
                console.error(error);
            });

        } else {
            const blob = new Blob(this.chunks, { type: videoIOSType });

            this.setState({ recordedVideo: blob });
            //  const videoURL = window.URL.createObjectURL(blob);
            const videoURL = window.webkitURL.createObjectURL(blob);

            this.setState({ videoUrl: videoURL });

            let that = this;

            getBlobDuration(blob).then(function (duration) {
                that.setState({ duration: duration });
            }).catch((error) => {
                console.error(error);
            });
        }

        if (iosCheck == false) {
            this.state.stream.getTracks().forEach(track => {
                track.stop();
            });
        }

    }

    skipQuestion(e) {
        e.preventDefault();

        this.setState({ loaded: false });
        this.props.skipPracticeQuestion({ "question_id": this.state.data.id }, this.props.auth.loginUserToken, (res) => {
            this.setState({ loaded: true });
            //  if (this.state.completedata.next != null) {
            this.setState({ seconds: 4 });
            this.setState({ timer: 0 });
            this.getQuestion();

        });
    }

    saveToServer = (e) => {
        e.preventDefault();

        if (this.state.numberRecordings == '') {
            toast('Please record your answer to submit successfully.', {
                position: "top-right",
                autoClose: 3000,
                type: 'warning',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            return;
        }

        if (this.state.duration != '') {
            this.setState({ loaded: false });
            this.setState({ submitFlag: true });
            let formData = new FormData();
            formData.append("video", this.state.recordedVideo);
            formData.append("question_id", this.state.data.id);
            formData.append("duration", this.state.duration);
            formData.append("size", this.state.recordedVideo.size);

            this.props.postPracticeQuestion(formData, this.props.auth.loginUserToken, (res) => {
                this.setState({ submitFlag: false, isSubmitted: true });
                this.setState({ loaded: true });
                this.setState({ timer: 0, seconds: 4 });
                if (res.status == 200) {
                    toast('Thank you for recording answer. Your answer has been submitted successfully.', {
                        position: "top-right",
                        autoClose: 3000,
                        type: 'success',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });

                    this.getQuestion();
                  
                }
            });
        } else {
            toast('Please wait until processing is complete before trying to submit.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    takeBreak = (e) => {
        e.preventDefault();

        this.props.history.replace('/home/create/');
    }

    retakeVideo(e) {
        e.preventDefault();

        this.setState({ numberRecordings: '' });
        this.setState({ seconds: 4 });
        this.setState({ timer: 0 });

        this.getCameraStream();
    }

    closePopUp = (e) => {
        e.preventDefault();

        this.setState({ firstTimePop: false });
    }

    componentWillUnmount() {
        clearInterval(this.countRef.current);
        clearInterval(this.myInterval);
        // if(this.mediaRecorder) {
        //     this.mediaRecorder.stop();
        // }
    }

    render() {
        const { recording, data, completedata, numberRecordings, videoUrl, seconds, loaded, recordingAfter } = this.state;
        return (
            <div>
                <HeaderComponent value={this.state.numberRecordings} saveToServer={this.saveToServer} skipQuestion={this.skipQuestion} props={this.props} />
                <section className="practing_outer record2 recored3 partices1 custom_partices4">
                    <Loader loaded={loaded}></Loader>
                    <div className="container">
                        <div className="row">
                            <div className="col-ld-10 col-md-10 offset-lg-1 offset-md-1">
                                <div className="middle_outer56 cust67">
                                    <div className="tex_fulll2">
                                        <h4 className="colre4">Practice </h4>

                                        <div id="myModal201" className={this.state.firstTimePop == false ? "modal fade show show_custom_modal" : "modal fade show custom_modal"} role="dialog">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        {/* <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button> */}

                                                    </div>
                                                    <div className="modal-body">
                                                        <p>The first couple questions, which you are required to record, are intended to enhance the experience of interacting with your practice Eternaview.</p>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" onClick={this.closePopUp}>Continue</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal" id="myModal">
                                            <div className="modal-dialog custom_dioalag">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">Before skipping ahead, do you wish to submit the recording you just made?</h4>
                                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                    </div>

                                                    <div className="modal-body">
                                                        <div className="btn_inner">
                                                            <ul>
                                                                <li><button className="btn custom_yes" onClick={e => this.saveToServer(e)} data-dismiss="modal">Yes, submit!</button></li>
                                                                <li><button className="btn custom_no" onClick={e => this.skipQuestion(e)} data-dismiss="modal">No, just move ahead</button></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="question_name"> Question {completedata.current_page} of {completedata.total_question_count}</p>
                                        <h4>{data?.question}</h4>

                                        {(numberRecordings == '' && data.transition == false) && <h5><a href="" onClick={e => this.skipQuestion(e)}>Skip Question</a></h5>}
                                        {(numberRecordings != '' && data.transition == false) && <h5><a href="" data-toggle="modal" data-target="#myModal">Skip Question</a></h5>}

                                    </div>

                                    {(numberRecordings == '' && recordingAfter == false) && <div className="same_height">
                                        {(!recording && numberRecordings == '') && <div className="btn7 custom_t6">
                                            <button className="btn" onClick={e => this.startRecording(e)}>START Recording</button>
                                        </div>}
                                    </div>}

                                    {(!recording && numberRecordings != '') && <div className="btn7 custom_t6">
                                        <button className="btn" disabled={numberRecordings != ''}>START Recording</button>
                                    </div>}

                                    {(recording == true && recordingAfter == true) && <div className="btn7 custom_t6 red_coloe5"><button className="btn" onClick={e => this.stopRecording(e)}>STOP Recording</button></div>}

                                    <div className="img6 practice">
                                        {recordingAfter && <div className="recording_gif">
                                            <img src={mic} />
                                        </div>}

                                        <div className="timer_outer practice-timer">
                                            {seconds == 1 && <img src={timer1} />}
                                            {seconds == 2 && <img src={timer2} />}
                                            {seconds == 3 && <img src={timer3} />}
                                            {seconds == 0 && <img src={start} />}
                                        </div>

                                        {numberRecordings == '' && <video
                                            style={{ width: 900, height: 320 }}
                                            ref={v => {
                                                this.video = v;
                                            }}
                                            playsInline
                                        >
                                            Video stream not available.
                                        </video>}

                                        {numberRecordings != '' && <video id="video_player" style={{ width: 900, height: 320 }} ref={v => {
                                            this.videocreated = v;
                                        }} src={videoUrl} controls playsInline></video>}

                                        <div className="partices"><p>Recording time is limited to 2 minutes per question.</p></div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="text_nam546">

                                                    <p>Recording Time of This Question: {(new Date(this.state.duration * 1000).toISOString()).substr(11, 8)} </p>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="btn_outer99 recor67">
                                                    <ul>
                                                        {(numberRecordings != '' && this.state.submitFlag == false) && <li className="Save_outer4"><button className="btn" onClick={e => this.saveToServer(e)}>Submit</button></li>}
                                                        {this.state.submitFlag == true && <li className="Save_outer4"><button className="btn">Submitting...</button></li>}
                                                        {(this.state.isSubmitted == false && numberRecordings != '') && <li className="cancel"><button className="btn" onClick={e => this.retakeVideo(e)}>Retake</button></li>}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="break_outer45">
                                    <img src={real_think} onClick={e => this.takeBreak(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
    getPracticeQuestion: bindActionCreators(getPracticeQuestion, dispatch),
    postPracticeQuestion: bindActionCreators(postPracticeQuestion, dispatch),
    getPracticeRestoreSession: bindActionCreators(getPracticeRestoreSession, dispatch),
    skipPracticeQuestion: bindActionCreators(skipPracticeQuestion, dispatch),
    submitPracticeEterna: bindActionCreators(submitPracticeEterna, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Practice);