
if (process.env.NODE_ENV === "production") {
    module.exports = {
        REACT_APP_BASE_ENDPOINT: "https://eternabackend.eternaview.com",
        production_time: "6-10 business days after artwork approval",
        FRONTEND_ENDPOINT: "https://eternaview.com"
    };
} else {
    module.exports = {
        REACT_APP_BASE_ENDPOINT: "https://eternabackend.eternaview.com",
        production_time: "6-10 business days after artwork approval",
        FRONTEND_ENDPOINT: "https://eternaview.com"
    };
}
//* https://eternabackend.eternaview.com
//* https://eternabackend.itechnolabs.tech
