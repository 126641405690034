import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import edit from "../../Images/edit.png";
import { Redirect } from 'react-router-dom';
import CheckoutWithStripe from "./CheckoutWithStripe";
import { getPlans, getPromoCodes, subscribeToPlans, createCheckoutSession, verifyUserSubscription } from "../../redux/actions/index";
import copy from "copy-to-clipboard";
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { FRONTEND_ENDPOINT } from "../../config/connection";

function Plans(props) {
    const dispatch = useDispatch();
    const paramvalue = useParams();
    const [priceLookupKey, setPriceLookupKey] = useState(null);
    const [planId, setPlanId] = useState(null);
    const [planAmount, setPlanAmount] = useState(null);
    const [plans, setPlans] = useState([]);
    const [promocodes, setPromocodes] = useState([]);
    const [paramUrl, setParamUrl] = useState('');
    const [catName, setCatName] = useState(props?.location?.state?.catName);
    const auth = useSelector(state => state);
    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState('');
    let [sessionId, setSessionId] = useState('');
    const [subscription, setSubscription] = useState();
    const [planVerify, setPlanVerify] = useState('');

    useEffect(() => {
        if (auth.auth.loginUserToken == "") {
            props.history.push('/');
        }

        dispatch(verifyUserSubscription(auth.auth.loginUserToken, (res) => {
            if (res.data) {
                setPlanVerify(res.data.active_status);
            }
        }));

        dispatch(getPlans(auth.auth.loginUserToken, (result) => {
            setPlans(result.data);
        }));

        dispatch(getPromoCodes(auth.auth.loginUserToken, (res) => {
            if (res.data && res.data.length) {
                setPromocodes(res.data);
            }
        }));

        setParamUrl(paramvalue.id);

        ////// checkout ////////
        const query = new URLSearchParams(window.location.search);

        console.log(query);
        if (query.get('success')) {
            setSuccess('true');
            setSessionId(query.get('session_id'));

        } else {
            console.log(props);
            // if(props.location.state) {
            //     localStorage.setItem('priceLookupKey',props.state.priceLookupKey)
            //     localStorage.setItem('planId',props.state.planId)
            //     localStorage.setItem('email',props.state.email)
            // }
        }

        if (query.get('canceled')) {
            setSuccess('false');
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );

            toast("Payment is unsuccessful", {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }

    }, [sessionId]);

    let stripePromise = null;

    const [publishableKey, setPublishableKey] = useState(null);

    const Message = ({ message }) => (
        <section>
            <p>{message}</p>
        </section>
    );

    const SuccessDisplay = ({ sessionId }) => {
        var priceLookupKey = localStorage.getItem('priceLookupKey')
        var planId = localStorage.getItem('planId')
        var email = localStorage.getItem('email')

        let split = paramUrl.split('+');
        let body = {
            price_id: priceLookupKey,
            plan_id: planId,
            cat_id: split[0]
            // paymentMethodId: paymentMethod.id,
        }

        dispatch(subscribeToPlans(body, auth.auth.loginUserToken, (result) => {
            console.log(result);
            if (result.msg) {
                window.location.href = `/home/thank-you-plan/${paramUrl}+${result.views}`;
            }
        }));

        // if (subscription) {
        //     switch (subscription.status) {
        //         case 'active':
        //             // Redirect to account page
        //             setMessage("Success! Redirecting to your account.");
        //             break;

        //         case 'incomplete':
        //             setMessage("Please confirm the payment.");

        //             break;

        //         default:
        //             setMessage(`Unknown Subscription status: ${subscription.status}`);
        //     }
        // }

    };

    if (!success && message === '') {

    } else if (success == 'true' && sessionId !== '') {
        return <SuccessDisplay sessionId={sessionId} />;
    } else {
        return <Message message={message} />;
    }


    if (publishableKey) {
        stripePromise = loadStripe('pk_test_51JNf1bBcsK7hC85NTATFObKQVyDrs6dGsG8gKvvZW9tZRK3t26d9unVC1M63YfyUrUdh2u4lT85mVgAQLhkPGVWu00hVntFYDe');
    } else {
        // fetch("http://eterna.itechnolabs.tech/config").then(r => r.json()).then(({publishableKey}) => {
        setPublishableKey('pk_test_51JNf1bBcsK7hC85NTATFObKQVyDrs6dGsG8gKvvZW9tZRK3t26d9unVC1M63YfyUrUdh2u4lT85mVgAQLhkPGVWu00hVntFYDe');
        // });
    }

    // if (priceLookupKey) {
    //     return <Redirect to={{
    //         pathname: `/home/subscribe/${paramUrl}`,
    //         state: { priceLookupKey, planId, planAmount }
    //     }} />
    // };

    function getPlan(price, plan, amount) {
        setPriceLookupKey(price);
        setPlanId(plan);
        setPlanAmount(amount);

        localStorage.setItem('priceLookupKey', price)
        localStorage.setItem('planId', plan)

        let body = {
            "lookup_key": plan,
            "domain": `${FRONTEND_ENDPOINT}/home/plans/${paramUrl}`,
            "planAmount": amount
        }

        dispatch(createCheckoutSession(body, auth.auth.loginUserToken, (result) => {
            window.location.href = result.session_url;
        }));

        // createCheckoutSessionNow(plan, amount);
    }

    function copyPromocode(code) {
        copy(code);

        toast("Promocode is copied successfully", {
            position: "top-right",
            autoClose: 2000,
            type: 'success',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }

    return (
        <section className="plan_outer new_plan">
            <div className="container">
                <div className="plan_outer2">
                    <h4>Pricing Plans</h4>
                    <p>Here are our Pricing Plans for creating your Eternaview. It is required to purchase one of these plans if you want <br />
                    to begin the process of creating your Eternaview (feel free to try out a free practice<br />
                        Eternaview in the meantime).
                    </p>
                </div>
                {promocodes.length > 0 && <div className="promo_div_1">
                    <p>Please click to see offers</p>
                    <button className="cancel_sub" type="submit" data-toggle="modal" data-target="#promoModal">View Offers</button>
                </div>}

                <div className="modal fade" id="promoModal" tabIndex="-1" role="dialog" aria-labelledby="promoModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="promoModalLabel">Promo Code</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {promocodes && promocodes.map(promo => (
                                    <div className="promo_div">
                                        {promo.coupon.percent_off && <span>Promo Code: <b>{promo.code}</b> -  {promo.coupon.percent_off}%</span>}

                                        {promo.coupon.amount_off && <span>Promo Code: <b>{promo.code}</b> -  ${promo.coupon.amount_off}</span>}
                                        <button onClick={() => copyPromocode(promo.code)} data-dismiss="modal"><i className="fal fa-copy"></i></button>
                                    </div>
                                ))}
                                {/* <button type="button" className="payable_amount"><b>Please Pay:</b> ${planAmount}</button> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive prceing_plan">
                <table className="table table-bordered compatibility">
                        <thead>
                            <tr>
                                <th colSpan="4">
                                    <h5 className="custyo">Pricing Plans</h5>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    </div>
                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered col_table_custom">
                        <tbody>
                            <tr className="first_col">
                                <td className="no_color"></td>
                                <td className="no_color">What types of Eternaviews is<br /> this plan used for?</td>
                                <td className="no_color">Upfront Creation Price<br />
                                    (one-time fee)
                                </td>
                                <td className="no_color">Annual Subscription Price<br />
                                    (charged day 1 and then every year)
                                </td>
                                <td className="custom_cnter text-white"></td>
                            </tr>
                            {/* <Elements stripe={stripePromise}></Elements> */}

                            {localStorage.getItem('typecreate') == 0 && plans && plans.map(plan => (
                                <tr className={catName.toLowerCase() == plan.name.toLowerCase() ? "custom_left tractive" : (catName.toLowerCase() == 'dating' && plan.name.toLowerCase() == 'chat plan' ? "custom_left tractive" : "custom_left")}>
                                    <td className="title_td">{plan.name} Plan</td>
                                    {plan.name_slug == "Legacy" && <td className="custom_cnter">{plan.name}</td>}
                                    {plan.name_slug == "Dating" && <td className="custom_cnter">Dating</td>}
                                    {plan.name_slug == "Audio-only" && <td className="custom_cnter">Audio-only<br /> (i.e. no video) <br /> for either Legacy or Dating</td>}
                                    {plan.plans.length == 2 && <td className="custom_cnter">${plan.plans[1].amount}</td>}
                                    {plan.plans.length != 2 && <td className="custom_cnter">N/A</td>}
                                    <td className="custom_cnter">${plan.plans[0].amount}</td>

                                    {(planVerify != "active" && (catName.toLowerCase() == plan.name_slug.toLowerCase() || (catName.toLowerCase() == 'dating' && plan.name.toLowerCase() == 'chat plan'))) ? <td className="custom_cnter text-white">
                                    {plan.plans.length == 2 && <a onClick={() => getPlan(plan.plans[0].id, plan.plans[0].plan, plan.plans[0].amount + plan.plans[1].amount)}>Click for Purchase</a>}
                                    {plan.plans.length == 1 && <a onClick={() => getPlan(plan.plans[0].id, plan.plans[0].plan, plan.plans[0].amount)}>Click for Purchase</a>}
                                        </td> : 
                                        <td></td>}
                                </tr>
                            ))}

                            {localStorage.getItem('typecreate') == 1 && plans && plans.map(plan => (
                                <tr className={plan.name_slug.toLowerCase() == "audio-only" ? "custom_left tractive" : "custom_left"}>
                                    <td className="title_td">{plan.name} Plan</td>
                                    {plan.name_slug == "Legacy" && <td className="custom_cnter">{plan.name}</td>}
                                    {plan.name_slug == "Dating" && <td className="custom_cnter">Dating </td>}
                                    {plan.name_slug == "Audio-only" && <td className="custom_cnter">Audio-only<br /> (i.e. no video) <br /> for either Legacy or Dating</td>}
                                    {plan.plans.length == 2 && <td className="custom_cnter">${plan.plans[1].amount}</td>}
                                    {plan.plans.length != 2 && <td className="custom_cnter">N/A</td>}
                                    <td className="custom_cnter">${plan.plans[0].amount}</td>

                                    {(planVerify != "active" && localStorage.getItem('typecreate') == 1 && plan.name_slug.toLowerCase() == "audio-only") ? <td className="custom_cnter text-white">
                                    {plan.plans.length == 2 && <a onClick={() => getPlan(plan.plans[0].id, plan.plans[0].plan, plan.plans[0].amount + plan.plans[1].amount)}>Click for Purchase</a>}
                                    {plan.plans.length == 1 && <a onClick={() => getPlan(plan.plans[0].id, plan.plans[0].plan, plan.plans[0].amount)}>Click for Purchase</a>}
                                        </td> : <td></td>}
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered compatibility">
                        <thead>
                            <tr>
                                <th colSpan="4">
                                    <h5 className="custyo">Compatibility</h5>
                                    <p className="custom67">Initial Recording Can Be Made on and Viewers Can Interact w/the Recording on Any of the Following:<br />
                                        (there may be some limitations in the case of very old hardware or software versions)
                                    </p>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered col_table_custom">
                        <tbody>
                            <tr className="first_col">
                                <td className="no_color"></td>
                                <td className="no_color">PC's - desktop or laptop computers-<br /> running Windows and Apple(iOS)</td>
                                {/* <td className="no_color">Chromebooks</td> */}
                                <td className="no_color">Tablets running Android or<br /> Apple(iOS)</td>
                                <td className="no_color">Mobile phones running Android or <br /> Apple(iOS)</td>
                                <td className="no_color">Chromebooks</td>
                            </tr>
                            {plans && plans.map(plan => (
                                <tr className="custom_left">
                                    <td className="title_td">{plan.name} Plan</td>
                                    {/* {plan.name == "Legacy" && <td className="custom_cnter">{plan.name}</td>}
                                    {plan.name == "Chat Plan" && <td className="custom_cnter">Dating</td>}
                                    {plan.name == "Audio Only Plan" && <td className="custom_cnter">Almost any Audio-<br /> only (i.e. no video) <br /> for either Legacy or Dating</td>} */}
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div className="bg_gren">
                    <p>May be compatible with some additional devices as well but we cannot confirm in advance.</p>
                </div>

                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered compatibility">
                        <thead>
                            <tr>
                                <th colSpan="4">
                                    <h5 className="custyo">Includes</h5>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered col_table_custom">
                        <tbody>
                            <tr className="first_col">
                                <td className="no_color"></td>
                                <td className="no_color">Recording through your <br />webcam or mobile camera and/or microphone</td>
                                <td className="no_color">Recording in Full HD (upto 1080p, <br />depending on resolution of recording device)</td>
                                <td className="no_color">Recording is processed for optimal playback on all compatible devices and screens</td>
                                <td className="no_color">Adaptive bit-rate streaming to<br />  optimize performance at<br />  different internet speeds</td>
                                <td className="no_color">Storage on Amazon Web<br /> Services</td>
                                <td className="no_color">Responds to questions spoken by the viewer of your Eternaview</td>
                                <td className="no_color">Responds to questions typed in by the viewer of your Eternaview</td>
                                <td className="no_color">Eternaview indexed by each recorded answer, w/ easy clicking to retrieve any answer</td>
                                <td className="no_color">The ability to set private or<br /> public viewing options and to<br /> send invites</td>
                                <td className="no_color">No charge to the viewers that<br /> you want to view/interact<br /> with your eternaview</td>
                            </tr>
                            {plans && plans.map(plan => (
                                <tr className="custom_left">
                                    <td className="title_td">{plan.name} Plan</td>
                                    {/* {plan.name == "Legacy" && <td className="custom_cnter">{plan.name}</td>}
                                    {plan.name == "Chat Plan" && <td className="custom_cnter">Dating</td>}
                                    {plan.name == "Audio Only Plan" && <td className="custom_cnter">Almost any Audio-<br /> only (i.e. no video) <br /> for either Legacy or Dating</td>} */}

                                    {plan.name_slug == "Legacy" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Dating" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Audio-only" && <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>}

                                    {plan.name_slug == "Legacy" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Dating" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Audio-only" && <td className="custom_cnter">N/A</td>}

                                    {plan.name_slug == "Legacy" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Dating" && <td className="custom_cnter">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </td>}
                                    {plan.name_slug == "Audio-only" && <td className="custom_cnter">N/A</td>}
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                    <td className="custom_cnter"><i className="fa fa-check" aria-hidden="true"></i></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered compatibility">
                        <thead>
                            <tr>
                                <th colSpan="4">
                                    <h5 className="custyo">Usage Allowances*</h5>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="table-responsive prceing_plan">
                    <table className="table table-bordered col_table_custom">
                        <tbody>
                            <tr className="first_col">
                                <td className="no_color"></td>
                                <td className="no_color"># of Eternaviews (i.e. full interviews) you can create per subscription**</td>
                                <td className="no_color">Total length of Eternaview recording (you likely will not need anywhere near this much time)</td>
                                <td className="no_color"># of answers that can be reviewed and re-recorded before submitting that particular answer</td>
                                <td className="no_color"># of answers that can be edited after initial submissions</td>
                                <td className="no_color"># of questions that viewers can ask your Eternaview***</td>
                            </tr>
                            {plans && plans.map(plan => (
                                <tr className="custom_left">
                                    <td className="title_td">{plan.name} Plan</td>
                                    {/* {plan.name == "Legacy" && <td className="custom_cnter">"{plan.name}"</td>}
                                    {plan.name == "Chat Plan" && <td>"Dating" and Most<br /> "Create Your Own"<br /> Eternaviews</td>}
                                    {plan.name == "Audio Only Plan" && <td>Almost any Audio-<br /> only (i.e. no video) <br /> Eternaview</td>}  */}
                                    <td className="custom_cnter">1</td>
                                    <td className="custom_cnter">{plan.total_time} hours</td>
                                    <td className="custom_cnter">Unlimited</td>
                                    <td className="custom_cnter">Up to {plan.no_of_edit} per year</td>
                                    <td className="custom_cnter">Up to {plan.no_of_query} per year</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div className="box455">
                    <p>
                        *If customers need to increase their usage allowances they will have the ability to renew early in order to reset their usage allowances.</p>
                    <p>Certain exceptions may apply to Usage Allowances, as detailed in our Terms of Use.</p>
                    <p>
                        **Note that a single Eternaview (i.e. interview) consists of your answers to anywhere from 75 to 175+ questions that you will be shown during the recording process.</p>
                    <p>***This includes whether questions are asked in spoken or text form, whether viewer just clicks directly on the answers they want to hear, or any other way individual
                        answers may be played back in the future. It also includes any questions the creator asks of their own Eternaview. </p>
                    <p>Keep in mind that if you make your Eternaview public that there may be more questions asked of it.</p>
                </div>
                {success == 'false' && <div className="promo_div_1 order_cancel">
                    Payment not successfully made.
                </div>}
                <div className="row contact_outer">
                    <div className="col-lg-8 col-md-12 offset-lg-2">
                        <div className="row bg_color67">
                            <div className="col-lg-8">
                                <div className="leg556">
                                    <h3>Custom Plan</h3>
                                    <p>If you are a celebrity, influencer, corporate exec
                                        or otherwise expect very high viewership, please
                                        shoot us an email with more details
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="conty6">
                                    <Link to='/home/contact-us/'><button className="btn cont-btn">Contact Us</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
};

export default Plans;
