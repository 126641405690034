import React, { Component } from 'react';
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import video_recording from "../../Images/video_recording.png";
import hand_outer from "../../Images/hand_outer.png";
import mic from "../../Images/mic.gif";
import arrow_right from "../../Images/arrow_right.png";
import ctre45 from "../../Images/ctre45.png";
import { getCreateQuestion, saveCreateQuestion, skipCreateQuestion, getQuestionById, skipCurrentModule, getCreateRestoreSession, scanQuestions, verifyUserSubscription } from '../../redux/actions';
import chat_tak from "../../Images/chat_tak.png";
import blog1 from "../../Images/blog1.png";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import RecordRTC, { invokeSaveAsDialog } from 'recordrtc';
import getBlobDuration from 'get-blob-duration';
import timer1 from "../../Images/Timer1.png";
import timer2 from "../../Images/Timer2.png";
import timer3 from "../../Images/Timer3.png";
import start from "../../Images/start.png";
import ringtone from "../../Sounds/new-ringtone.mp3";
import Loader from "react-loader";
import { browserName, browserVersion } from "react-device-detect";
import HeaderComponent from "../../components/Header";
//import videojs from "video.js";
//import "video.js/dist/video-js.css";
//import 'videojs-record/dist/css/videojs.record.css';
//import Record from 'videojs-record/dist/videojs.record.js';
import 'webrtc-adapter';
import AudioAnalyser from './AudioAnalyser';

const videoIOSType = 'video/mp4';
const videoWebType = 'video/webm';
const audioType = 'audio/*';
const iosCheck = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const details = navigator.userAgent;
const regexp = /android|iphone|kindle|ipad/i;
const isMobileDevice = regexp.test(details);

class CreateEternaview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recording: false,
            recordingAfter: false,
            videos: [],
            data: {},
            nextpage: '',
            recordedVideo: {},
            recordedAudio: {},
            category: '',
            subcategory: '',
            numberRecordings: '',
            stream: {},
            submitFlag: false,
            completedata: {},
            isSubmitted: false,
            videoUrl: '',
            audioUrl: '',
            duration: '',
            seconds: 4,
            typecreate: '',
            timer: 0,
            isActive: false,
            isPaused: false,
            loaded: true,
            type: '',
            transition: '',
            moduleQuestions: [],
            reloadFlag: '',
            total_time: false,
            grace_total_time: false,
            total_time_left: false,
            detectAudio: null,
            plan_id: '',
            viewId: '',
            moveAheadModal: false,
            hasMicrophone: false,
            hasWebcam: false,
            videoOptions: {
                video: {
                    width: { min: 320, ideal: 1920, max: 1920 },
                    height: { min: 180, ideal: 1080, max: 1080 },
                    aspectRatio: 1.7777777778,
                    facingMode: 'user'
                }, audio: true
            },
            videoFacing : 'user'
        };

        this.countRef = React.createRef(null);
        this.myInterval = React.createRef(null);
    }

    saveToServer = (event) => {

        if (this.state.numberRecordings == '') {
            toast('Please record your answer to submit successfully.', {
                position: "top-right",
                autoClose: 3000,
                type: 'warning',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            return;
        }

        if (this.state.duration != '') {
            this.setState({ loaded: false });
            this.setState({ submitFlag: true });
            let formData = new FormData();
            formData.append("video", this.state.recordedVideo);
            formData.append("question_id", this.state.data.id);
            formData.append("language", this.state.data.language.id);
            formData.append("duration", this.state.duration);
            formData.append("size", this.state.recordedVideo.size);
            formData.append("view_id", this.state.viewId);
            if (this.state.typecreate == 0) {
                formData.append("type", "video");
            } else {
                formData.append("type", "audio");
            }

            this.props.saveCreateQuestion(formData, this.props.auth.loginUserToken, (res) => {
                this.setState({ videoUrl: '' });
                if (res.is_active == false) {
                    localStorage.clear();
                    this.props.history.replace("/");
                } else {
                    this.setState({ loaded: true });
                    this.setState({ seconds: 4 });
                    this.setState({ timer: 0, duration: '' });
                    if (res.status == 200) {
                        toast('Thank you for recording answer. Your answer has been submitted successfully.', {
                            position: "top-right",
                            autoClose: 3000,
                            type: 'success',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        });

                        if (this.state.completedata.next == null) {
                            if (this.state.completedata.current_module == this.state.completedata.total_sub_module) {
                                this.setState({ transition: "yes" });
                                this.getQuestion(this.state.category, this.state.subcategory, "yes", this.state.type, this.state.viewId);
                            } else {
                                this.getNextModule();
                            }

                        } else {
                            if (this.state.transition == "yes") {
                                this.getQuestion(this.state.category, this.state.subcategory, "", this.state.type, this.state.viewId);
                            } else {
                                this.getQuestion(this.state.category, this.state.subcategory, '', this.state.type, this.state.viewId);
                            }

                        }
                        this.setState({ submitFlag: false, isSubmitted: true });
                    }
                }
            });
        } else {
            toast('Please wait until processing is complete before trying to submit.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }

        event.preventDefault();

    }

    async componentDidMount() {
        if (this.props.auth.loginUserToken == "") {
            localStorage.clear();
            this.props.history.replace("/");
        }

        let split = (this.props.match.params.id).split("+"); let viewId = '';

        let typeflag = '', typecreate = '';
        if (split[2] == 0) {
            typeflag = "video";
            typecreate = "video_audio";
        } else {
            typeflag = "audio";
            typecreate = "audio";
        }

        if (split[3]) {
            viewId = split[3];
        }


        this.setState({ type: typeflag });

        this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {

            if (res.data && res.data.length > 0) {
                let typecreate = split[2] == 0 ? 'video_audio' : 'audio';

                res.data.map((mod, ind) => {
                    // if (split[0] == mod.subscribe_plan__category__id) {
                    if (mod.views == viewId) {
                        if (mod.active_status == "active" && mod.status == "paid") {
                            if ((mod.updated_details.progress_status == "initial" || mod.updated_details.progress_status == "in-progress") && mod.subscribe_plan__plan_type == typecreate) {
                                this.setState({ plan_id: mod.id, viewId: mod.views });
                                viewId = mod.views;
                                return;
                            }
                        } else {
                            alert("Apologies! but it seems like your subscription plan's payment is either inactive or pending");
                            this.props.history.replace("/");
                        }
                    }
                });


                if (viewId != '') {
                    this.props.getCreateRestoreSession(split[0], split[1], typeflag, viewId, this.props.auth.loginUserToken, (response) => {
                        if (response.id != "" && response.id != undefined) {
                            this.getQuestion(split[0], split[1], response.id, typeflag, viewId);
                        } else if (response.module_id) {
                            this.getQuestion(split[0], response.module_id, response.id, typeflag, viewId);
                        } else if (response.transition && (response.transition).toLowerCase() == "yes") {
                            this.getQuestion(split[0], split[1], "", typeflag, viewId);
                        } else {
                            this.getQuestion(split[0], split[1], '', typeflag, viewId);
                        }
                    });
                }

                if (res.data.updated_details.total_time != 0) {
                    // let remaining_time = (res.data.subscribe_plan__total_time * 3600) - res.data.updated_details.total_time;
                    //  remaining_time = Math.ceil(remaining_time / 3600);
                    let remaining_time = res.data.updated_details.total_time;
                    if (remaining_time <= 1) {
                        this.setState({ total_time: true });
                    }
                } else if (res.data.updated_details.total_time == 0 && res.data.updated_details.grace_time > 0) {
                    //  let remaining_time = (res.data.subscribe_plan__grace_total_time * 3600) - res.data.updated_details.grace_time;
                    //   remaining_time = Math.ceil(remaining_time / 3600);
                    let remaining_time = res.data.updated_details.grace_time;
                    if (remaining_time <= 1) {
                        this.setState({ grace_total_time: true });
                    }
                } else if (res.data.updated_details.total_time == 0 && res.data.updated_details.grace_time == 0) {
                    this.setState({ total_time_left: true });
                }

            }
        });

        this.setState({ typecreate: split[2] });

        if (split[2] == 0 || split[2] == '0') {
            this.video.src = this.video.srcObject = null;
            this.video.muted = false;
            this.video.volume = 1;

        } else if (split[2] == 1 || split[2] == '1') {
            if (this.audio) {
                this.audio.src = this.audio.srcObject = null;
                this.audio.muted = true;
                this.audio.volume = 0;
            }
        }

        this.props.scanQuestions({}, `&question_scan=True`, this.props.auth.loginUserToken, (res) => {
            let con = res.data, questions = [], match = res.transition_name;

            if (res.data.length > 0) {
                con.map((data, index) => {
                    if (data.id == split[0]) {
                        data.modules.map((mod, ind) => {
                            if (mod.subcategory_name != "Interaction Facilitation Part 1 (required)" && mod.subcategory_name != "Interaction Facilitation Part 2" && mod.subcategory_name != "Pleasantries" && mod.subcategory_name != "Interaction Facilitation & Pleasantries (required)") {
                                questions.push(mod);
                            }
                        });
                    }

                });
                this.setState({ moduleQuestions: questions });
            }

        });

    }

    getQuestion(cat, subcat, restore, type, viewId) {
        this.setState({ category: cat, subcategory: subcat, type: type });

        let url = `category=${cat}&sub_category=${subcat}&type=${type}&view_id=${viewId}`;
        if (restore != "" && restore != "yes") {
            url = `category=${cat}&sub_category=${subcat}&restore_session=${restore}&type=${type}&view_id=${viewId}`;
        } else if (restore == "yes") {
            url = `category=${cat}&sub_category=${subcat}&transition=yes&type=${type}&view_id=${viewId}`;
        } else {
            url = `category=${cat}&sub_category=${subcat}&type=${type}&view_id=${viewId}`;
        }

        this.props.getCreateQuestion(url, this.props.auth.loginUserToken, (res) => {
            if (res.data && res.data.length > 0) {
                this.setState({ data: res.data[0], nextpage: res.current_page, completedata: res });
                this.setState({ recording: false, numberRecordings: '', isSubmitted: false });
            } else if (res.data && res.data.length == 0) {
                // if(this.state.reloadFlag != '') {
                //     this.props.history.push(`/create/congratulations/${cat}+${subcat}+${type}+${this.state.reloadFlag}`);
                // } else {
                this.props.history.push(`/create/congratulations/${cat}+${subcat}+${type}+${this.state.plan_id}+${this.state.viewId}`);
                //}
            }
        });
    }

    videoOptionsChange = (e) => {
       // alert(e.target.value);
        let videoOptions = {
            video: {
                width: { min: 320, ideal: 1920, max: 1920 },
                height: { min: 180, ideal: 1080, max: 1080 },
                aspectRatio: 1.7777777778,
                facingMode: e.target.value
            }, audio: true
        };

        this.setState({videoOptions : videoOptions, videoFacing : e.target.value});
    }

    async getCameraStream() {
        this.setState({ numberRecordings: '' });
        var audio = new Audio(ringtone);

        // Older browsers might not implement mediaDevices at all, so we set an empty object first
        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia != undefined) {
            await navigator.mediaDevices.getUserMedia(this.state.videoOptions).then(stream => {

                this.stream = stream;
                this.chunks = [];
                this.video.srcObject = stream;
                this.video.muted = true;
                this.video.volume = 0;

                audio.play();
                this.setState({ seconds: 3 });

                this.video.play();

                this.setState({ recording: true });

                this.myInterval = setInterval(() => {
                    if (this.state.seconds > 0) {
                        audio.play();
                        this.setState({ seconds: this.state.seconds - 1 });
                    } else if (this.state.seconds <= 0) {
                        clearInterval(this.myInterval);
                        this.setState({ seconds: this.state.seconds - 1 });
                        this.countRef.current = setInterval(() => {
                            this.setState({ timer: this.state.timer + 1 });
                        }, 1000)
                        // listen for data from media recorder
                        this.setState({ recordingAfter: true });
                        this.setState({ isActive: true, isPaused: true });

                        if (iosCheck == false) {
                            this.mediaRecorder = new MediaRecorder(stream, {
                                mimeType: (`${browserName}` == "Safari" || `${browserName}` == "safari") ? videoIOSType : videoWebType
                            });

                        } else {
                            this.mediaRecorder = new MediaRecorder(stream, {
                                mimeType: videoIOSType
                            });
                        }

                        this.mediaRecorder.start(10);

                        this.setState({ stream: this.mediaRecorder.stream });

                        this.mediaRecorder.ondataavailable = e => {
                            if (e.data && e.data.size > 0) {
                                this.chunks.push(e.data);
                            }
                        };
                        //  alert(this.chunks);
                        return stream;
                    }
                }, 1000)


            }).catch(err => {
                alert("Please enable your webcamera and microphone in order to create eternaview.")
                this.setState({ hasWebcam: false });
                this.setState({ hasMicrophone: false });
                return false;
                //throw new Error("Unable to fetch stream " + err);
            });

        } else {
            alert('getUserMedia not supported on your browser!');
        }

    }

    async getAudioStream() {

        this.setState({ numberRecordings: '' });
        var audiosound = new Audio(ringtone);

        await navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {

            this.stream = stream;
            this.chunks = [];
            this.audio.srcObject = stream;

            this.audio.muted = true;

            audiosound.play();
            this.setState({ seconds: 3 });

            this.setState({ recording: true });

            this.myInterval = setInterval(() => {
                if (this.state.seconds > 0) {
                    audiosound.play();
                    this.setState({ seconds: this.state.seconds - 1 });
                } else if (this.state.seconds <= 0) {
                    clearInterval(this.myInterval);
                    this.setState({ seconds: this.state.seconds - 1 });
                    this.countRef.current = setInterval(() => {
                        this.setState({ timer: this.state.timer + 1 });
                    }, 1000)
                    // listen for data from media recorder
                    this.setState({ recordingAfter: true });
                    this.setState({ isActive: true, isPaused: true });
                    this.setState({ detectAudio: stream });

                    this.mediaRecorder = new MediaRecorder(stream);

                    this.mediaRecorder.start(10);

                    this.setState({ stream: this.mediaRecorder.stream });

                    this.mediaRecorder.ondataavailable = e => {
                        if (e.data && e.data.size > 0) {
                            this.chunks.push(e.data);
                        }
                    };

                    return stream;
                }
            }, 1000)

        }).catch(err => {
            console.log(err);
            alert("Please enable your webcamera and microphone in order to create eternaview.")
            this.setState({ hasWebcam: false });
            this.setState({ hasMicrophone: false });
            return false;
            //throw new Error("Unable to fetch stream " + err);
        });


    }

    startRecording(e) {
        e.preventDefault();

        this.handleReset();

        //this.player.record().start();
        if (this.state.typecreate == 0 && this.state.recordingAfter == false) {
            this.getCameraStream();
        } else {
            this.getAudioStream();
        }

    }

    stopRecording(e) {
        e.preventDefault();

        this.handlePause();
        this.setState({ recording: false, numberRecordings: '1', recordingAfter: false });

        if (this.state.typecreate == 0 || this.state.typecreate == '0') {
            this.video.srcObject = this.video.src = null;
            this.video.muted = false;
            this.video.volume = 1;
            if (this.mediaRecorder) {
                this.mediaRecorder.stop();
            }

            if (iosCheck == false) {
                const blob = new Blob(this.chunks, { type: (`${browserName}` == "Safari" || `${browserName}` == "safari") ? videoIOSType : videoWebType });

                this.setState({ recordedVideo: blob });
                //  const videoURL = window.URL.createObjectURL(blob);
                const videoURL = window.webkitURL.createObjectURL(blob);

                this.setState({ videoUrl: videoURL });

                let that = this;

                getBlobDuration(blob).then(function (duration) {
                    that.setState({ duration: duration });
                    console.log((new Date(duration * 1000).toISOString()).substr(11, 8))

                }).catch((error) => {
                    console.error(error);
                });

            } else {
                const blob = new Blob(this.chunks, { type: 'video/mp4' });

                this.setState({ recordedVideo: blob });
                //  const videoURL = window.URL.createObjectURL(blob);
                const videoURL = window.webkitURL.createObjectURL(blob);

                this.setState({ videoUrl: videoURL });

                let that = this;

                getBlobDuration(blob).then(function (duration) {
                    that.setState({ duration: duration });
                }).catch((error) => {
                    console.error(error);
                });
            }


            if (iosCheck == false) {
                this.state.stream.getTracks().forEach(track => {
                    track.stop();
                });
            }

        } else if (this.state.typecreate == 1 || this.state.typecreate == '1') {

            if (this.mediaRecorder) {
                this.mediaRecorder.stop();
            }

            //const blob = new Blob(this.chunks, { type: audioType });
            const blob = new Blob(this.chunks, { type: (`${browserName}` == "Safari" || `${browserName}` == "safari") ? 'audio/mp4' : audioType });

            this.setState({ recordedVideo: blob });
            this.setState({ detectAudio: null });

            const audioUrl = window.URL.createObjectURL(blob);

            this.setState({ audioUrl: audioUrl });
            let that = this;

            getBlobDuration(blob).then(function (duration) {
                console.log(duration);
                that.setState({ duration: duration });
            }).catch((error) => {
                console.error(error);
            });

            this.audio.src = this.audio.srcObject = null;
            this.audio.muted = false;

            if (iosCheck == false) {
                this.state.stream.getTracks().forEach(track => {
                    track.stop();
                });
            }

        }
    }

    retakeVideo(e) {
        e.preventDefault();

        this.setState({ numberRecordings: '' });
        this.setState({ seconds: 4 });
        this.setState({ timer: 0, duration: '' });

        if (this.state.typecreate == 0) {
            this.getCameraStream();
        } else {
            this.getAudioStream();
        }
    }

    skipQuestion = (e) => {

        this.setState({ loaded: false });
        this.props.skipCreateQuestion({ "question_id": this.state.data.id, "type": this.state.type, "view_id": this.state.viewId }, this.props.auth.loginUserToken, (res) => {
            this.setState({ loaded: true });
            if (this.state.completedata.next != null) {
                this.setState({ seconds: 4 });
                this.setState({ timer: 0, duration: '' });
                this.getQuestion(this.state.category, this.state.subcategory, '', this.state.type, this.state.viewId);

            } else if (this.state.completedata.next == null) {
                if (this.state.completedata.current_module == this.state.completedata.total_sub_module) {
                    this.setState({ transition: "yes" });
                    this.getQuestion(this.state.category, this.state.subcategory, "", this.state.type, this.state.viewId);
                } else {
                    this.getNextModule();
                }
            }
        });

        e.preventDefault();
    }

    skipModule(e) {
        e.preventDefault();

        this.getNextModule();
    }

    navigateAway(e) {
        e.preventDefault();

        this.props.history.replace('/');
    }

    closePopUp = (e) => {
        e.preventDefault();

        this.setState({ total_time: false, grace_total_time: false });
    }

    getNextModule() {
        this.setState({ loaded: false });
        this.props.skipCurrentModule(this.state.category, this.state.subcategory, this.state.viewId, this.props.auth.loginUserToken, (res) => {
            this.setState({ loaded: true });
            if (res.data) {
                this.setState({ seconds: 4 });
                this.setState({ timer: 0, duration: '' });
                this.getQuestion(this.state.category, res.data.next_id, '', this.state.type, this.state.viewId);
            } else {
                if (this.state.completedata.next == null) {
                    if (this.state.completedata.current_module == this.state.completedata.total_sub_module) {
                        this.setState({ transition: "yes" });
                        this.getQuestion(this.state.category, this.state.subcategory, "", this.state.type, this.state.viewId);
                    } else {
                        this.props.history.replace(`/create/congratulations/${this.state.category}+${this.state.subcategory}+${this.state.type}+${this.state.plan_id}+${this.state.viewId}`);
                    }
                }
            }
        });
    }

    takeBreak = (e) => {
        e.preventDefault();

        this.props.history.replace('/home/create/');
    }

    formatTime = (timer) => {
        const getSeconds = `0${(timer % 60)}`.slice(-2)
        const minutes = `${Math.floor(timer / 60)}`
        const getMinutes = `0${minutes % 60}`.slice(-2)
        const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

        return `${getHours}:${getMinutes}:${getSeconds}`
    }

    handlePause = () => {
        clearInterval(this.countRef.current)
        this.setState({ isPaused: false });
    }

    handleReset = () => {
        clearInterval(this.countRef.current)
        this.setState({ isActive: false, isPaused: false });
        this.setState({ timer: 0, duration: '' });
    }

    goToFaq = (e) => {
        e.preventDefault();

        this.props.history.push('/home/faq/');
    }

    goToBlog = (e) => {
        e.preventDefault();

        this.props.history.push('/home/blogs/');
    }

    componentWillUnmount() {
        clearInterval(this.countRef.current);
        clearInterval(this.myInterval);
    }

    render() {
        const { recording, recordingAfter, data, completedata, videoUrl, audioUrl, numberRecordings, seconds, loaded, transition, moduleQuestions, moveAheadModal } = this.state;

        return (
            <div>
                <HeaderComponent value={this.state.numberRecordings} saveToServer={this.saveToServer} skipQuestion={this.skipQuestion} props={this.props} />
                <section className="practing_outer record2 recored3 paid5 recording1 hide_hand crey78">
                    <Loader loaded={loaded}></Loader>
                    <div className="container">
                        <div className="row">
                            <div className="col-ld-10 col-md-10 offset-lg-1 offset-md-1">
                                <div className="middle_outer56 text5627 full_intr45">
                                    {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal">
                                        Open modal
                                    </button> */}
                                    <div className="modal" id="myModal">
                                        <div className="modal-dialog custom_dioalag">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Before skipping ahead, do you wish to submit the recording you just made?</h4>
                                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                </div>

                                                <div className="modal-body">
                                                    <div className="btn_inner">
                                                        <ul>
                                                            <li><button className="btn custom_yes" onClick={e => this.saveToServer(e)} data-dismiss="modal">Yes, submit!</button></li>
                                                            <li><button className="btn custom_no" onClick={e => this.skipQuestion(e)} data-dismiss="modal">No, just move ahead</button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal" id="myBreakModal">
                                        <div className="modal-dialog custom_dioalag">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Before taking a break, do you wish to submit the recording you just made?</h4>
                                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                </div>

                                                <div className="modal-body">
                                                    <div className="btn_inner">
                                                        <ul>
                                                            <li><button className="btn custom_yes" onClick={e => this.saveToServer(e)} data-dismiss="modal">Yes, submit!</button></li>
                                                            <li><button className="btn custom_no" onClick={e => this.takeBreak(e)} data-dismiss="modal">No, just take break</button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal" id="myModalModule">
                                        <div className="modal-dialog custom_dioalag">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Before skipping ahead, do you wish to submit the recording you just made?</h4>
                                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                </div>

                                                <div className="modal-body">
                                                    <div className="btn_inner">
                                                        <ul>
                                                            <li><button className="btn custom_yes" onClick={e => this.saveToServer(e)} data-dismiss="modal">Yes, submit!</button></li>
                                                            <li><button className="btn custom_no" onClick={e => this.skipModule(e)} data-dismiss="modal">No, just move ahead</button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={moveAheadModal == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} id="myMoveModal" role="dialog">
                                        <div className="modal-dialog custom_dioalag">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Before you navigate away, do you wish to submit the recording you just made?</h4>
                                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                </div>

                                                <div className="modal-body">
                                                    <div className="btn_inner">
                                                        <ul>
                                                            <li><button className="btn custom_yes" onClick={e => this.saveToServer(e)} data-dismiss="modal">Yes, submit!</button></li>
                                                            <li><button className="btn custom_no" onClick={e => this.navigateAway(e)} data-dismiss="modal">No, just navigate away.</button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="myModal201" className={this.state.total_time == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Please note that you have consumed maximum recording time limit and left with only 1 hour.</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="myModal201" className={this.state.grace_total_time == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Please note that you have consumed maximum recording time limit along with additional time given as grace allotment and left with only 1 hour.</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {transition == '' && <h3 className="small_heding69">Module {completedata.current_module}/{completedata.total_sub_module} : {data?.sub_category?.name}</h3>}
                                    {transition == 'yes' && <h3 className="small_heding69">{data?.sub_category?.name}</h3>}

                                    {(!recording && numberRecordings == '' && data?.category?.name == 'Legacy' && data?.sub_category?.name != 'Interaction Facilitation Part 1 (required)' && completedata.current_module != completedata.total_sub_module) && <h5 className="skipe_o2"><a href="" onClick={e => this.skipModule(e)}>Skip to Next Module</a></h5>}

                                    {(!recording && numberRecordings != '' && data?.category?.name == 'Legacy' && data?.sub_category?.name != "Interaction Facilitation Part 1 (required)" && completedata.current_module != completedata.total_sub_module) && <h5 className="skipe_o2"><a href="" data-toggle="modal" data-target="#myModalModule">Skip to Next Module</a></h5>}

                                    {(!recording && numberRecordings == '' && data?.category?.name == 'Dating' && data?.sub_category?.name != "Interaction Facilitation & Pleasantries (required)" && completedata.current_module != completedata.total_sub_module) && <h5 className="skipe_o2"><a href="" onClick={e => this.skipModule(e)}>Skip to Next Module</a></h5>}

                                    {(!recording && numberRecordings != '' && data?.category?.name == 'Dating' && data?.sub_category?.name != "Interaction Facilitation & Pleasantries (required)" && completedata.current_module != completedata.total_sub_module) && <h5 className="skipe_o2"><a href="" data-toggle="modal" data-target="#myModalModule">Skip to Next Module</a></h5>}

                                    <div className="tex_fulll2">
                                        <p className="question_name"> Question {completedata.current_page} of {completedata.total_question_count}</p>
                                        <h4>{data?.question}</h4>

                                        {(!recording && numberRecordings == '' && data?.category?.name == 'Legacy' && data?.sub_category?.name != 'Interaction Facilitation Part 1 (required)') && <h5><a href="" onClick={e => this.skipQuestion(e)}>Skip Question</a></h5>}

                                        {(!recording && numberRecordings != '' && data?.category?.name == 'Legacy' && data?.sub_category?.name != "Interaction Facilitation Part 1 (required)") && <h5><a href="" data-toggle="modal" data-target="#myModal">Skip Question</a></h5>}

                                        {(!recording && numberRecordings == '' && data?.category?.name == 'Dating' && data?.sub_category?.name != "Interaction Facilitation & Pleasantries (required)") && <h5><a href="" onClick={e => this.skipQuestion(e)}>Skip Question</a></h5>}

                                        {(!recording && numberRecordings != '' && data?.category?.name == 'Dating' && data?.sub_category?.name != "Interaction Facilitation & Pleasantries (required)") && <h5><a href="" data-toggle="modal" data-target="#myModal">Skip Question</a></h5>}

                                    </div>

                                    {(numberRecordings == '' && recordingAfter == false) && <div className="same_height">
                                        {(!recording && numberRecordings == '' && this.state.total_time_left == false) && <div className="btn7 custom_t6">
                                            <button type="button" className="btn" onClick={e => this.startRecording(e)}>START Recording</button>
                                        </div>}
                                    </div>}
                                    {(!recording && numberRecordings != '') && <div className="btn7 custom_t6">
                                        <button type="button" className="btn" disabled={numberRecordings != ''}>START Recording</button>
                                    </div>}

                                    {(recording == true && recordingAfter == true) && <div className="btn7 custom_t6 red_coloe5"><button type="button" className="btn" onClick={e => this.stopRecording(e)}>STOP Recording</button>
                                    </div>}

                                    {this.state.typecreate == 0 && <div className="img6 audio_recorder custom_rec45">

                                        {recordingAfter == true && <div className="recording_gif">
                                            <img src={mic} />
                                        </div>}
                                        <div className="timer_outer">
                                            {seconds == 1 && <img src={timer1} />}
                                            {seconds == 2 && <img src={timer2} />}
                                            {seconds == 3 && <img src={timer3} />}
                                            {seconds == 0 && <img src={start} />}
                                        </div>

                                        {(numberRecordings == '' && recording == false) && <div className="videoCheck">
                                            {isMobileDevice && <select className="changeCamera_select" value={this.state.videoFacing} onChange={(e) => this.videoOptionsChange(e)}>
                                                <option value="user">Front Camera</option>
                                                <option value="environment">Rear Camera</option>
                                            </select>}
                                        </div>}

                                        {numberRecordings == '' && <video
                                            style={{ width: 900, height: 320 }}
                                            ref={v => {
                                                this.video = v;
                                            }}
                                            playsInline
                                        >
                                            Video stream not available.
                                        </video>}

                                        {numberRecordings != '' && <video id="video_player" style={{ width: 900, height: 320 }} ref={v => {
                                            this.videocreated = v;
                                        }} src={videoUrl} controls playsInline></video>}

                                        <div className="row retake_row">
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="text_nam546">

                                                    <p>Recording Time of This Question: {(new Date(this.state.duration * 1000).toISOString()).substr(11, 8)} <br />
                                                        Cumulative Recording Time: {completedata.total_time_recording ? completedata.total_time_recording : '00:00:00'}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="btn_outer99 recor67">
                                                    <ul>
                                                        {numberRecordings != '' && this.state.submitFlag == false && <li className="Save_outer4"><button className="btn" onClick={e => this.saveToServer(e)}>Submit</button></li>}

                                                        {this.state.submitFlag == true && <li className="Save_outer4"><button className="btn">Submitting...</button></li>}

                                                        {(this.state.isSubmitted == false && numberRecordings != '') && <li className="cancel"><button className="btn" onClick={e => this.retakeVideo(e)}>Retake</button></li>}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        {(!recording && numberRecordings == '' && transition == '') && <div className="take_break" onClick={e => this.takeBreak(e)}>
                                            <img src={chat_tak} />
                                        </div>}
                                        {(!recording && numberRecordings != '' && transition == '') && <div className="take_break" data-toggle="modal" data-target="#myBreakModal">
                                            <img src={chat_tak} />
                                        </div>}

                                    </div>}

                                    {localStorage.getItem('typecreate') == 1 && <div className="img6 audio_recorder custom_rec45 separate-style-audio">
                                        <div className='audio_div_box'>
                                            {recordingAfter == true && <div className="recording_audio_gif">
                                                <img src={mic} />
                                            </div>}

                                            {this.state.detectAudio ? <AudioAnalyser audio={this.state.detectAudio} /> : ''}
                                            {/* {this.state.flagFrame == false && <img src={video_recording} />} */}

                                            <div className="timer_outer">
                                                {seconds == 1 && <img src={timer1} />}
                                                {seconds == 2 && <img src={timer2} />}
                                                {seconds == 3 && <img src={timer3} />}
                                                {seconds == 0 && <img src={start} />}
                                            </div>

                                            {numberRecordings == '' && <audio
                                                ref={a => {
                                                    this.audio = a;
                                                }}

                                            >
                                                <p>Audio stream not available. </p>
                                            </audio>}

                                            {numberRecordings != '' && <audio controls src={audioUrl} controlsList="nodownload" />}
                                        </div>

                                        <div className="row retake_row">
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="text_nam546">

                                                    <p>Recording Time of This Question: {(new Date(this.state.duration * 1000).toISOString()).substr(11, 8)} <br />
                                                        Cumulative Recording Time: {completedata.total_time_recording ? completedata.total_time_recording : '00:00:00'}</p>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="btn_outer99 recor67">
                                                    <ul>
                                                        {numberRecordings != '' && this.state.submitFlag == false && <li className="Save_outer4"><button className="btn" onClick={e => this.saveToServer(e)}>Submit</button></li>}

                                                        {this.state.submitFlag == true && <li className="Save_outer4"><button className="btn">Submitting...</button></li>}

                                                        {(this.state.isSubmitted == false && numberRecordings != '') && <li className="cancel"><button className="btn" onClick={e => this.retakeVideo(e)}>Retake</button></li>}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        {(!recording && numberRecordings == '' && transition == '') && <div className="take_break" onClick={e => this.takeBreak(e)}>
                                            <img src={chat_tak} />
                                        </div>}
                                        {(!recording && numberRecordings != '' && transition == '') && <div className="take_break" data-toggle="modal" data-target="#myBreakModal">
                                            <img src={chat_tak} />
                                        </div>}
                                    </div>}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="greand_pop">
                    <div className="conatiner">
                        <div className="img56">
                            {(!recording && numberRecordings == '' && transition == '') && <a data-toggle="modal" data-target="#myModal12"><img src={hand_outer} /></a>}

                            {(!recording && numberRecordings != '' && transition == '') && <a data-toggle="modal" data-target="#myBreakModal"><img src={hand_outer} /></a>}

                            <div className="modal custom_create_popup" id="myModal12">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body cu678">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="box activer4">
                                                        <h4>Frequently Asked Questions</h4>
                                                        <div className="few3_outer">
                                                            <i className="fas fa-circle"></i>
                                                            <p>Dummy lorem ipsum dolor text?</p>
                                                        </div>
                                                        <div className="few3_outer no_bo675">
                                                            <i className="fas fa-circle"></i>
                                                            <p>Dummy lorem ipsum dolor text?</p>
                                                        </div>
                                                        <div className="btn78"><a href="" data-dismiss="modal" onClick={this.goToFaq}>View all</a></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="box">
                                                        <h4>Scan Modules & Questions in Template</h4>
                                                        <div className="custtom_according acc_cus1">
                                                            <div className="bs-example">
                                                                <div className="accordion" id="accordionExample">
                                                                    {moduleQuestions?.map((subcat, ind) => {
                                                                        return (
                                                                            <div className="card" key={subcat.id}>
                                                                                <div className="card-header" id={"headingOne" + ind}>
                                                                                    <h2 className="mb-0">
                                                                                        <button type="button" className="btn btn-link" data-toggle="collapse" data-target={"#collapseOne" + ind}>{subcat.subcategory_name}<i className="fa fa-angle-down"></i> </button>
                                                                                    </h2>
                                                                                </div>
                                                                                <div id={"collapseOne" + ind} className="collapse" aria-labelledby={"headingOne" + ind} data-parent="#accordionExample">
                                                                                    <div className="card-body cus56">
                                                                                        {
                                                                                            subcat.questions.map((ques, index) => {
                                                                                                return (

                                                                                                    <p key={ques.id}>{ques.question} </p>

                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="box">
                                                        <h4>Demo Video</h4>
                                                        <div className="img6_outer"><img src={ctre45} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="box vill98">
                                                        <h4>Blog Posts</h4>
                                                        <div className="blog_box1">
                                                            <img src={blog1} />
                                                            <div className="texcd4">
                                                                <p>Creating A Personal Connection With Eternaview</p>
                                                                <span><img src={arrow_right} /></span>
                                                            </div>
                                                        </div>
                                                        <div className="btn78"><a href="" data-dismiss="modal" onClick={this.goToBlog}>View all</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>);
    }
}

const styles = {
    'wrapper': {
        display: 'flex',
        overflow: 'auto'
    },
    'child': {
        flex: 2
    },
    'videos': {
        flex: 1
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
    getCreateQuestion: bindActionCreators(getCreateQuestion, dispatch),
    saveCreateQuestion: bindActionCreators(saveCreateQuestion, dispatch),
    skipCreateQuestion: bindActionCreators(skipCreateQuestion, dispatch),
    getQuestionById: bindActionCreators(getQuestionById, dispatch),
    getCreateRestoreSession: bindActionCreators(getCreateRestoreSession, dispatch),
    skipCurrentModule: bindActionCreators(skipCurrentModule, dispatch),
    scanQuestions: bindActionCreators(scanQuestions, dispatch),
    verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEternaview);
