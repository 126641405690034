import { createStore,applyMiddleware} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk'
import rootReducer from "../reducers/index";
import { persistStore, persistReducer } from 'redux-persist';


export default () => {

    const initialState = {};

    const middleware = [thunk];
    
    const store = createStore(rootReducer, initialState,
        composeWithDevTools(applyMiddleware(...middleware)));
        
    const persistor = persistStore(store);
  
    return { store, persistor };
};

